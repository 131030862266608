@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/*-- Default CSS --*/
body {
  font-family: "Poppins", sans-serif;
  color: #777777;
  background-color: #ffffff;
  font-size: 16px;
}

h1, h2, h3, h4, h5, h6 {
  line-height: 1.3;
  font-family: "Poppins", sans-serif;
  color: #0d1820;
}

p {
  line-height: 1.8;
  color: white;
}

a {
  transition: 0.5s all ease;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

img {
  max-width: 100%;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

button:focus {
  outline: 0;
}

.btn.focus, .btn:focus {
  box-shadow: none;
}

/*-- End Default CSS --*/
/*----- Home One CSS -----*/
/*-- Header Top --*/
.one-header-top {
  padding-top: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #f0f0f0;
  padding-right: 40px;
  padding-left: 40px;
}
.one-header-top .header-social ul {
  margin: 0;
  padding: 0;
}
.one-header-top .header-social ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.one-header-top .header-social ul li:nth-child(2) a {
  color: #1da1f2;
}
.one-header-top .header-social ul li:nth-child(3) a {
  color: #d43535;
}
.one-header-top .header-social ul li:nth-child(4) {
  margin-right: 0;
}
.one-header-top .header-social ul li:nth-child(4) a {
  color: #405de6;
}
.one-header-top .header-social ul li a {
  display: block;
  color: #0b5998;
  font-size: 20px;
}
.one-header-top .header-social ul li a:hover {
  transform: rotateY(360deg);
}
.one-header-top .header-partner {
  text-align: center;
}
.one-header-top .header-partner p {
  font-size: 14px;
  color: #606060;
  margin-bottom: 0;
}
.one-header-top .header-partner p a {
  display: inline-block;
  color: #232f77;
}
.one-header-top .header-partner p a:hover {
  color: #606060;
}
.one-header-top .header-call {
  text-align: right;
}
.one-header-top .header-call ul {
  margin: 0;
  padding: 0;
}
.one-header-top .header-call ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.one-header-top .header-call ul li:last-child {
  margin-right: 0;
}
.one-header-top .header-call ul li span {
  display: inline-block;
  color: #606060;
  font-size: 14px;
}
.one-header-top .header-call ul li a {
  display: inline-block;
  color: #232f77;
  font-size: 14px;
}
.one-header-top .header-call ul li a:hover {
  color: #606060;
}

/*-- End Header Top --*/
/*-- Navbar --*/
.navbar-light {
  padding-left: 55px;
  padding-right: 55px;
  background-color: #ffffff;
  box-shadow: 0px 20px 20px 0px rgba(221, 221, 221, 0.3215686275);
}
.navbar-light .navbar-nav .nav-link {
  color: #0d1820;
  margin-right: 15px;
  margin-left: 15px;
}
.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show, .navbar-light .navbar-nav .show > .nav-link {
  color: #12ca59;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #12ca59;
}
.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
  color: #12ca59;
}
.navbar-light .navbar-nav {
  margin-left: auto;
  margin-right: auto;
}
.navbar-light .side-nav a {
  display: inline-block;
  color: #0d1820;
}
.navbar-light .side-nav .side-nav-left {
  margin-right: 14px;
}
.navbar-light .side-nav .side-nav-left:hover {
  color: #12ca59;
}
.navbar-light .side-nav .side-nav-right {
  color: #ffffff;
  position: relative;
  background-color: #0d1820;
  z-index: 1;
  padding: 10px 16px 12px;
  border-radius: 4px;
}
.navbar-light .side-nav .side-nav-right:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.navbar-light .side-nav .side-nav-right i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 4px;
  margin-right: 4px;
}
.navbar-light .side-nav .side-nav-right:hover:before {
  width: 0;
}

/*-- End Navbar --*/
/*-- Banner --*/
.one-banner-area {
  position: relative;
  z-index: 1;
}
.one-banner-area .banner-shape img {
  z-index: -1;
  position: absolute;
}
.one-banner-area .banner-shape img:nth-child(1) {
  top: 0;
  left: 0;
  max-width: 500px;
  animation: banner-ani-one 6s linear infinite;
}
.one-banner-area .banner-shape img:nth-child(2) {
  left: 300px;
  top: 300px;
  max-width: 500px;
  animation: banner-ani-two 6s linear infinite;
}
.one-banner-area .container-fluid {
  padding-right: 0;
  padding-left: 0;
}
.one-banner-area .container-fluid .row {
  margin-left: 0;
  margin-right: 0;
}
.one-banner-area .container-fluid .row .col-lg-6 {
  padding-right: 0;
  padding-left: 0;
}
.one-banner-area .banner-text {
  max-width: 635px;
  margin-left: auto;
  margin-top: 50px;
  padding-right: 50px;
}
.one-banner-area .banner-text .banner-video {
  margin-bottom: 20px;
}
.one-banner-area .banner-text .banner-video a {
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  background-color: #0d1820;
  font-size: 50px;
}
.one-banner-area .banner-text .banner-video a:hover {
  background-color: #12ca59;
}
.one-banner-area .banner-text .banner-video a i {
  width: 80px;
  height: 80px;
  line-height: 80px;
}
.one-banner-area .banner-text .banner-video span {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #0d1820;
  position: relative;
  top: -15px;
  margin-left: 14px;
}
.one-banner-area .banner-text h1 {
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 95px;
  line-height: 115px;
}
.one-banner-area .banner-text p {
  margin-bottom: 50px;
  font-weight: 500;
  color: #1a1b1e;
  font-size: 30px;
}
.one-banner-area .banner-text .banner-form .col-lg-6 {
  padding-left: 15px;
  padding-right: 15px;
}
.one-banner-area .banner-text .banner-form .banner-form-span {
  display: inline-block;
  border-bottom: 1px solid rgba(221, 221, 221, 0.5882352941);
}
.one-banner-area .banner-text .banner-form .banner-form-span span {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #0d1820;
  padding: 15px 22px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
}
.one-banner-area .banner-text .banner-form form {
  padding: 30px 20px 30px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
  background-color: #ffffff;
}
.one-banner-area .banner-text .banner-form form .form-group .form-control {
  height: 45px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 0;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}
.one-banner-area .banner-text .banner-form form .form-group .form-control:focus {
  border: 1px solid #eeeeee;
  box-shadow: none;
}
.one-banner-area .banner-text .banner-form form .form-group .form-control span {
  font-weight: 500;
  font-size: 15px;
  color: #9d9d9d;
  margin-bottom: 0;
  line-height: 30px;
  display: block;
}
.one-banner-area .banner-text .banner-form form .form-group .form-control .list {
  display: block;
  width: 100%;
}
.one-banner-area .banner-text .banner-form form .form-group .form-control .list li {
  display: block;
}
.one-banner-area .banner-text .banner-form form .form-group ::placeholder {
  color: #9d9d9d;
}
.one-banner-area .banner-text .banner-form form .banner-form-btn {
  width: 100%;
  height: 45px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  transition: 0.5s all ease;
  position: relative;
  z-index: 1;
  background-color: #0d1820;
  border: 0;
}
.one-banner-area .banner-text .banner-form form .banner-form-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  transition: 0.5s all ease;
}
.one-banner-area .banner-text .banner-form form .banner-form-btn:hover:before {
  width: 0;
}
.one-banner-area .banner-text .banner-form form .banner-call {
  width: 100%;
  height: 45px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  background-color: #0d1820;
  display: block;
  text-align: center;
  line-height: 45px;
}
.one-banner-area .banner-text .banner-form form .banner-call:hover {
  background-color: #12ca59;
}
.one-banner-area .banner-slider .banner-inner {
  height: 900px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.one-banner-area .banner-slider .banner-inner img {
  position: absolute;
  left: 0;
  top: 0;
  max-width: 275px;
  height: 100%;
}
.one-banner-area .banner-slider .owl-prev {
  width: 95px;
  height: 90px;
  font-size: 50px !important;
  line-height: 100px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.3215686275);
  background-color: #efefef !important;
  transition: 0.5s all ease;
}
.one-banner-area .banner-slider .owl-prev:hover {
  color: #12ca59 !important;
  background-color: #ffffff !important;
}
.one-banner-area .banner-slider .owl-next {
  width: 95px;
  height: 90px;
  font-size: 50px !important;
  line-height: 100px !important;
  border-radius: 4px !important;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.3215686275);
  background-color: #efefef !important;
  transition: 0.5s all ease;
}
.one-banner-area .banner-slider .owl-next:hover {
  color: #12ca59 !important;
  background-color: #ffffff !important;
}
.one-banner-area .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  bottom: -45px;
  right: 0;
}
@keyframes banner-ani-one {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 50px);
  }
  100% {
    transform: translate(0, 0px);
  }
}
@keyframes banner-ani-two {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -50px);
  }
  100% {
    transform: translate(0, 0px);
  }
}

/*-- End Banner --*/
/*-- About --*/
.one-section-title {
  text-align: center;
  margin-bottom: 50px;
}
.one-section-title .sub-title {
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: relative;
  margin-bottom: 5px;
}
.one-section-title .sub-title:before {
  position: absolute;
  content: "";
  width: 40px;
  height: 3px;
  right: -48px;
  bottom: 7px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
}
.one-section-title h2 {
  font-weight: 700;
  font-size: 60px;
  margin-bottom: 0;
}
.one-section-title p {
  margin-top: 5px;
  max-width: 640px;
  margin-left: auto;
  margin-right: auto;
}

.one-about-area {
  padding-top: 95px;
}
.one-about-area .col-lg-6 {
  padding-left: 0;
  padding-right: 0;
}
.one-about-area .one-section-title {
  text-align: left;
  margin-bottom: 30px;
}
.one-about-area .about-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.one-about-area .about-img img {
  display: none;
}
.one-about-area .about-content {
  max-width: 545px;
  margin-left: 70px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.one-about-area .about-content .about-tablist {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  padding: 30px 30px 30px;
  margin-bottom: 20px;
}
.one-about-area .about-content .about-tablist ul {
  margin-bottom: 25px;
}
.one-about-area .about-content .about-tablist ul li {
  margin-right: 8px;
}
.one-about-area .about-content .about-tablist ul li:last-child {
  margin-right: 0;
}
.one-about-area .about-content .about-tablist ul li a {
  color: #ffffff;
  background-color: #3ae283;
  font-weight: 500;
  text-transform: uppercase;
  padding: 10px 18px 10px;
  border-radius: 4px;
}
.one-about-area .about-content .about-tablist ul li a.active {
  background: #ffffff;
  color: #121682;
}
.one-about-area .about-content .about-tablist ul li a i {
  display: inline-block;
  position: relative;
  top: 4px;
  font-size: 20px;
  margin-right: 3px;
}
.one-about-area .about-content .about-tablist p {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 15px;
}
.one-about-area .about-content .about-best ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.one-about-area .about-content .about-best ul li {
  flex: 0 0 33%;
  max-width: 33%;
  list-style-type: none;
  display: inline-block;
  background-color: #f4f4f4;
  padding: 15px 20px 15px;
  margin-right: 15px;
}
.one-about-area .about-content .about-best ul li:last-child {
  flex: 0 0 64%;
  max-width: 64%;
  margin-right: 0;
}
.one-about-area .about-content .about-best ul li h4 {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  color: #0d1820;
  margin-bottom: 0;
  line-height: 28px;
}
.one-about-area .about-content .about-best ul li img {
  padding-right: 25px;
  border-right: 1px solid #bcbcbc;
  margin-right: 25px;
  margin-top: -26px;
}
.one-about-area .about-content .about-best ul li .sig-inner {
  display: inline-block;
}
.one-about-area .about-content .about-best ul li .sig-inner span {
  display: block;
  font-weight: 500;
  color: #10c317;
  margin-bottom: 3px;
}
.one-about-area .about-content .about-best ul li .sig-inner p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  color: #909090;
}

/*-- End About --*/
/*-- Counter --*/
.one-counter-area {
  background-color: #0d1820;
}
.one-counter-area .counter-item {
  position: relative;
  margin-bottom: 30px;
}
.one-counter-area .counter-item h3 {
  font-weight: 700;
  font-size: 75px;
  color: #ffffff;
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.one-counter-area .counter-item p {
  margin-bottom: 0;
  font-size: 18px;
  color: #ffffff;
  padding-left: 110px;
  padding-top: 20px;
}

/*-- End Counter --*/
/*-- Service --*/
.one-service-area nav .nav-tabs {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border-bottom: 0;
}
.one-service-area nav .nav-tabs .nav-default {
  display: inline-block;
  margin-bottom: 30px;
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
  padding-left: 15px;
  padding-right: 15px;
}
.one-service-area nav .nav-tabs .nav-default.active .service-item:before {
  opacity: 1;
}
.one-service-area nav .nav-tabs .nav-default.active .service-item i {
  color: #ffffff;
}
.one-service-area nav .nav-tabs .nav-default.active .service-item span {
  color: #ffffff;
}
.one-service-area .service-item {
  text-align: center;
  background-color: #efefef;
  border-radius: 4px;
  padding: 20px 25px 18px;
  position: relative;
  z-index: 1;
}
.one-service-area .service-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  border-radius: 4px;
  opacity: 0;
  z-index: -1;
}
.one-service-area .service-item i {
  display: block;
  color: #0d1820;
  font-size: 50px;
  margin-bottom: 15px;
  transition: 0.5s all ease;
}
.one-service-area .service-item span {
  display: block;
  color: #0d1820;
  font-weight: 500;
  font-size: 16px;
  transition: 0.5s all ease;
}
.one-service-area .service-advisor {
  padding: 35px 35px 35px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
}
.one-service-area .service-advisor .advisor-content h2 {
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 16px;
}
.one-service-area .service-advisor .advisor-content p {
  margin-bottom: 20px;
}
.one-service-area .service-advisor .advisor-content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 22px;
}
.one-service-area .service-advisor .advisor-content ul li {
  list-style-type: none;
  display: block;
  color: #444444;
  margin-bottom: 12px;
  font-size: 16px;
}
.one-service-area .service-advisor .advisor-content ul li:last-child {
  margin-bottom: 0;
}
.one-service-area .service-advisor .advisor-content ul li i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 3px;
  margin-right: 3px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.one-service-area .service-advisor .advisor-content .advisor-link {
  display: inline-block;
}
.one-service-area .service-advisor .advisor-content .advisor-link:hover i {
  left: -3px;
}
.one-service-area .service-advisor .advisor-content .advisor-link a {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.one-service-area .service-advisor .advisor-content .advisor-link i {
  display: inline-block;
  color: #12ca59;
  font-size: 21px;
  position: relative;
  top: 4px;
  left: 0;
  transition: 0.5s all ease;
}

/*-- End Service --*/
/*-- Help --*/
.one-help-area {
  background-color: #0d1820;
  position: relative;
}
.one-help-area .help-img {
  position: absolute;
  top: 180px;
  right: 0;
  max-width: 435px;
}
.one-help-area .help-item {
  max-width: 790px;
}
.one-help-area .help-item .one-section-title {
  text-align: left;
}
.one-help-area .help-item .one-section-title h2 {
  color: #ffffff;
}
.one-help-area .help-item ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
.one-help-area .help-item ul li {
  list-style-type: none;
  display: inline-block;
  flex: 0 0 50%;
  max-width: 50%;
  position: relative;
  margin-bottom: 40px;
}
.one-help-area .help-item ul li:hover .help-icon {
  background-color: #ffffff;
}
.one-help-area .help-item ul li .help-icon {
  display: inline-block;
  background-color: #233644;
  border-radius: 4px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  transition: 0.5s all ease;
}
.one-help-area .help-item ul li .help-icon i {
  font-size: 38px;
  width: 80px;
  height: 65px;
  line-height: 65px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  box-shadow: 0px 10px 25px 0px #091117;
}
.one-help-area .help-item ul li .help-inner {
  padding-left: 110px;
}
.one-help-area .help-item ul li .help-inner h3 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
}
.one-help-area .help-item ul li .help-inner p {
  margin-bottom: 0;
  font-size: 15px;
  color: #b2bac0;
}
.one-help-area .help-item .advisor-link {
  display: inline-block;
}
.one-help-area .help-item .advisor-link:hover i {
  left: -3px;
}
.one-help-area .help-item .advisor-link a {
  display: inline-block;
  font-weight: 600;
  font-size: 16px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.one-help-area .help-item .advisor-link i {
  display: inline-block;
  color: #12ca59;
  font-size: 21px;
  position: relative;
  top: 4px;
  left: 0;
  transition: 0.5s all ease;
}

/*-- End Help --*/
/*-- Among --*/
.one-among-area .among-item {
  border-radius: 4px;
  box-shadow: 0px 0px 17px 0px rgba(221, 221, 221, 0.3490196078);
  transition: 0.5s all ease;
  margin: 15px;
}
.one-among-area .among-item:hover {
  transform: translate(0, -10px);
}
.one-among-area .among-item:hover .among-top .among-icon {
  left: 40%;
}
.one-among-area .among-item:hover .among-bottom {
  border-bottom: 3px solid;
  border-image-source: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  border-image-slice: 1;
}
.one-among-area .among-item .among-top {
  position: relative;
}
.one-among-area .among-item .among-top img {
  width: 100%;
}
.one-among-area .among-item .among-top .among-icon {
  display: inline-block;
  background-color: #ffffff;
  position: absolute;
  left: 30px;
  bottom: -15px;
  border-radius: 50%;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.831372549);
  width: 70px;
  height: 70px;
  transition: 0.5s all ease;
}
.one-among-area .among-item .among-top .among-icon i {
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  text-align: center;
  font-size: 50px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  transition: 0.5s all ease;
}
.one-among-area .among-item .among-bottom {
  padding: 38px 30px 30px;
  border-bottom: 3px solid transparent;
  transition: 0.5s all ease;
}
.one-among-area .among-item .among-bottom h3 {
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 15px;
}
.one-among-area .among-item .among-bottom p {
  margin-bottom: 15px;
  font-size: 15px;
}
.one-among-area .among-item .among-bottom a {
  display: inline-block;
  font-weight: 600;
  font-size: 14px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.one-among-area .among-item .among-bottom a:hover i {
  left: -3px;
}
.one-among-area .among-item .among-bottom i {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 4px;
  left: 0;
  transition: 0.5s all ease;
}
.one-among-area .owl-theme .owl-dots .owl-dot span {
  width: 16px;
  height: 5px;
  background: #e5e5e5;
  margin: 5px 5px;
}
.one-among-area .owl-theme .owl-dots .owl-dot.active span {
  background: #12ca59;
  width: 21px;
}
.one-among-area .center {
  transform: translate(0, -10px);
}
.one-among-area .center .among-top .among-icon {
  left: 40%;
}
.one-among-area .center .among-bottom {
  border-bottom: 3px solid;
  border-image-source: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  border-image-slice: 1;
}

/*-- End Among --*/
/*-- Quote --*/
.one-quote-area {
  padding-bottom: 110px;
}
.one-quote-area .row {
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.568627451);
  border-radius: 4px;
}
.one-quote-area .quote-content {
  padding: 28px 35px 35px;
}
.one-quote-area .quote-content h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 12px;
}
.one-quote-area .quote-content p {
  font-size: 15px;
  margin-bottom: 18px;
}
.one-quote-area .quote-content a {
  color: #ffffff;
  position: relative;
  background-color: #0d1820;
  z-index: 1;
  padding: 10px 80px 12px;
  border-radius: 4px;
  font-size: 17px;
  display: inline-block;
}
.one-quote-area .quote-content a:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.one-quote-area .quote-content a i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.one-quote-area .quote-content a:hover:before {
  width: 0;
}
.one-quote-area .quote-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
.one-quote-area .quote-img img {
  display: none;
}

/*-- End Quote --*/
/*-- Search --*/
.one-search-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.one-search-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, black 0%, rgba(0, 0, 0, 0.386992) 80%);
}
.one-search-area .search-wrap {
  position: relative;
}
.one-search-area .search-wrap .search-img {
  position: absolute;
  bottom: -100px;
  right: 0;
  max-width: 515px;
}
.one-search-area .search-content {
  position: relative;
  max-width: 640px;
}
.one-search-area .search-content .one-section-title {
  text-align: left;
  margin-bottom: 40px;
}
.one-search-area .search-content .one-section-title h2 {
  color: #ffffff;
}
.one-search-area .search-content .form-check {
  display: inline-block;
  margin-right: 15px;
}
.one-search-area .search-content .form-check label {
  font-size: 17px;
  color: #ffffff;
  margin-left: 5px;
}
.one-search-area .search-content .form-check .form-check-input {
  margin-top: 5px;
  width: 17px;
  height: 17px;
  top: 0px;
}
.one-search-area .search-content .form-check .form-check-input:focus {
  box-shadow: none;
  border: 0;
}
.one-search-area .search-content input[type=radio]:after {
  width: 11px;
  height: 11px;
  border-radius: 15px;
  top: -3px;
  left: 3px;
  position: relative;
  background-color: #fff;
  content: "";
  display: inline-block;
}
.one-search-area .search-content input[type=radio]:checked:after {
  background-color: #12ca59;
}
.one-search-area .search-content .row {
  margin-top: 23px;
}
.one-search-area .search-content .row .form-group {
  margin-bottom: 0px;
}
.one-search-area .search-content .row .form-group .form-control {
  border-radius: 4px;
  height: 50px;
  border: 0;
  padding-left: 20px;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
  color: #797979;
  display: block;
}
.one-search-area .search-content .row .form-group .form-control:focus {
  box-shadow: none;
  border: 0;
}
.one-search-area .search-content .row .form-group ::placeholder {
  color: #797979;
}
.one-search-area .search-content .row .form-group .list {
  width: 100%;
  display: block;
}
.one-search-area .search-content .search-link {
  margin-top: 25px;
}
.one-search-area .search-content .search-link button {
  color: #ffffff;
  position: relative;
  background-color: #0d1820;
  z-index: 1;
  padding: 16px 75px 16px;
  border-radius: 4px;
  font-size: 17px;
  display: inline-block;
  border: none;
  box-shadow: unset !important;
}
.one-search-area .search-content .search-link button:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.one-search-area .search-content .search-link button i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.one-search-area .search-content .search-link button:hover:before {
  width: 0;
}

/*-- End Search --*/
/*-- Team --*/
.one-team-area .one-section-title {
  text-align: left;
  position: relative;
}
.one-team-area .one-section-title a {
  display: inline-block;
  color: #ffffff;
  background-color: #0d1820;
  border-radius: 4px;
  padding: 14px 18px 14px;
  font-size: 17px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.one-team-area .one-section-title a:hover {
  background-color: #12ca59;
}
.one-team-area .one-section-title a i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 3px;
}
.one-team-area .team-item {
  text-align: center;
  margin-bottom: 30px;
}
.one-team-area .team-item:hover ul li:nth-child(1) {
  top: -20px;
  opacity: 1;
}
.one-team-area .team-item:hover ul li:nth-child(2) {
  top: -20px;
  opacity: 1;
}
.one-team-area .team-item:hover ul li:nth-child(3) {
  top: -20px;
  opacity: 1;
}
.one-team-area .team-item:hover ul li:nth-child(4) {
  top: -20px;
  opacity: 1;
}
.one-team-area .team-item .team-img {
  position: relative;
}
.one-team-area .team-item .team-img:before {
  position: absolute;
  content: "";
  width: 263px;
  height: 263px;
  border-radius: 50%;
  left: -4px;
  right: 0;
  top: -4px;
  border: 2px dashed #12ca59;
}
.one-team-area .team-item ul {
  margin: 0;
  padding: 0;
}
.one-team-area .team-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 4px;
  margin-left: 4px;
  position: relative;
}
.one-team-area .team-item ul li:nth-child(1) {
  transition: 0.3s;
  opacity: 0;
  top: 0;
}
.one-team-area .team-item ul li:nth-child(2) {
  transition: 0.5s;
  opacity: 0;
  top: 0;
}
.one-team-area .team-item ul li:nth-child(3) {
  transition: 0.7s;
  opacity: 0;
  top: 0;
}
.one-team-area .team-item ul li:nth-child(4) {
  transition: 0.9s;
  opacity: 0;
  top: 0;
}
.one-team-area .team-item ul li a {
  display: block;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 20px 0px #ddd;
  border-radius: 50%;
}
.one-team-area .team-item ul li a:hover {
  transform: rotateY(360deg);
}
.one-team-area .team-item ul li a i {
  width: 30px;
  height: 30px;
  line-height: 30px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.one-team-area .team-item h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
  text-transform: uppercase;
  color: #08104d;
}
.one-team-area .team-item span {
  display: block;
  font-size: 15px;
  color: #64677c;
}

/*-- End Team --*/
/*-- FAQ --*/
.one-faq-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.one-faq-area .faq-img {
  position: absolute;
  bottom: 0;
  right: 0;
  max-width: 560px;
}
.one-faq-area .faq-content {
  max-width: 650px;
}
.one-faq-area .faq-content .accordion {
  padding-left: 0;
  margin-bottom: 0;
}
.one-faq-area .faq-content .accordion a {
  width: 100%;
  font-weight: 600;
  display: block;
  cursor: pointer;
  font-size: 20px;
  color: #1a1b1e;
  padding: 20px 35px 20px;
}
.one-faq-area .faq-content .accordion a:after {
  position: absolute;
  content: "+";
  width: 35px;
  height: 35px;
  line-height: 36px;
  right: 35px;
  top: 17px;
  color: #ffffff;
  text-align: center;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  border-radius: 50%;
  font-size: 26px;
}
.one-faq-area .faq-content .accordion a.active {
  background-color: #ffffff;
}
.one-faq-area .faq-content .accordion a.active:after {
  content: "-";
}
.one-faq-area .faq-content .accordion p {
  display: none;
  margin-bottom: 0;
  color: #848484;
  font-size: 14px;
  padding: 0px 185px 25px 35px;
  background-color: #ffffff;
}
.one-faq-area .faq-content .accordion li {
  position: relative;
  list-style-type: none;
  margin-bottom: 15px;
  background-color: #f5f5f5;
  border: 1px solid #d9d9d9;
}
.one-faq-area .faq-content .accordion li:last-child {
  margin-bottom: 0;
}
.one-faq-area .faq-content .accordion li.active {
  background-color: #ffffff;
}

/*-- End FAQ --*/
/*-- Testimonial --*/
.one-testimonial-area .testimonial-item {
  text-align: center;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5215686275);
  padding: 30px 35px 30px;
  border-radius: 4px;
  margin-bottom: 30px;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item:hover, .one-testimonial-area .testimonial-item.active {
  background-color: #0d1820;
  transform: translate(0, -10px);
}
.one-testimonial-area .testimonial-item:hover .testimonial-top ul:before, .one-testimonial-area .testimonial-item.active .testimonial-top ul:before {
  background-color: #172631;
}
.one-testimonial-area .testimonial-item:hover .testimonial-top ul:after, .one-testimonial-area .testimonial-item.active .testimonial-top ul:after {
  background-color: #172631;
}
.one-testimonial-area .testimonial-item:hover .testimonial-top .test-img img, .one-testimonial-area .testimonial-item.active .testimonial-top .test-img img {
  border: 8px solid #314c60;
  box-shadow: none;
}
.one-testimonial-area .testimonial-item:hover .testimonial-top h3, .one-testimonial-area .testimonial-item.active .testimonial-top h3 {
  color: #ffffff;
}
.one-testimonial-area .testimonial-item:hover .testimonial-top span, .one-testimonial-area .testimonial-item.active .testimonial-top span {
  border-bottom: 1px solid #172631;
}
.one-testimonial-area .testimonial-item:hover .testimonial-bottom span, .one-testimonial-area .testimonial-item.active .testimonial-bottom span {
  color: #ffffff;
}
.one-testimonial-area .testimonial-item:hover .testimonial-bottom p, .one-testimonial-area .testimonial-item.active .testimonial-bottom p {
  color: #b2bac0;
}
.one-testimonial-area .testimonial-item .testimonial-top {
  margin-bottom: 15px;
}
.one-testimonial-area .testimonial-item .testimonial-top .test-img {
  position: relative;
  display: inline-block;
}
.one-testimonial-area .testimonial-item .testimonial-top .test-img img {
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5215686275);
  border: 8px solid #ffffff;
  border-radius: 50%;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item .testimonial-top .test-img i {
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  color: #ffffff;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  font-size: 20px;
  position: absolute;
  right: 0;
  bottom: 0;
}
.one-testimonial-area .testimonial-item .testimonial-top ul {
  margin: 0;
  padding: 0;
  margin-top: 30px;
  position: relative;
  margin-bottom: 20px;
}
.one-testimonial-area .testimonial-item .testimonial-top ul:before {
  position: absolute;
  content: "";
  left: -35px;
  top: 10px;
  width: 34%;
  height: 2px;
  background-color: #ecf3f7;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item .testimonial-top ul:after {
  position: absolute;
  content: "";
  right: -35px;
  top: 10px;
  width: 34%;
  height: 2px;
  background-color: #ecf3f7;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item .testimonial-top ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 3px;
  margin-left: 3px;
}
.one-testimonial-area .testimonial-item .testimonial-top ul li i {
  color: #ddd;
  font-size: 20px;
}
.one-testimonial-area .testimonial-item .testimonial-top ul li .checked {
  color: #ff9c00;
}
.one-testimonial-area .testimonial-item .testimonial-top h3 {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 8px;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item .testimonial-top span {
  display: block;
  font-size: 15px;
  color: #11c744;
  padding-bottom: 18px;
  border-bottom: 1px solid #dce8f0;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item .testimonial-bottom span {
  color: #3b474f;
  font-weight: 500;
  font-size: 17px;
  margin-bottom: 6px;
  display: block;
  transition: 0.5s all ease;
}
.one-testimonial-area .testimonial-item .testimonial-bottom p {
  color: #555555;
  font-size: 15px;
  margin-bottom: 0;
  transition: 0.5s all ease;
}

/*-- End Testimonial --*/
/*-- Contact --*/
.one-contact-area {
  background-color: #0d1820;
}
.one-contact-area .contact-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.one-contact-area .contact-img img {
  display: none;
}
.one-contact-area .contact-content {
  padding: 100px 0 100px;
  max-width: 635px;
  margin-left: 50px;
}
.one-contact-area .contact-content .one-section-title {
  text-align: left;
  margin-bottom: 30px;
  margin-left: 15px;
}
.one-contact-area .contact-content .one-section-title h2 {
  color: #ffffff;
}
.one-contact-area .contact-content .form-group {
  margin-bottom: 20px;
  position: relative;
}
.one-contact-area .contact-content .form-group label {
  margin: 0;
  color: #7f7f7f;
  display: inline-block;
  font-size: 18px;
  position: absolute;
  top: 12px;
  left: 25px;
}
.one-contact-area .contact-content .form-group .form-control {
  height: 50px;
  border-radius: 0;
  background-color: #ffffff;
  padding-left: 50px;
  border: 0;
  font-weight: 500;
  font-size: 15px;
}
.one-contact-area .contact-content .form-group .form-control:focus {
  box-shadow: none;
  border: 0;
}
.one-contact-area .contact-content .form-group textarea {
  height: auto !important;
  padding-top: 12px;
}
.one-contact-area .contact-content .form-group ::placeholder {
  color: #7f7f7f;
}
.one-contact-area .contact-content .contact-btn {
  color: #ffffff;
  position: relative;
  background-color: #ffffff;
  z-index: 1;
  padding: 11px 18px 12px 14px;
  border-radius: 4px;
  font-size: 16px;
  display: inline-block;
  opacity: 1;
  text-transform: uppercase;
  transition: 0.5s all ease;
  border: 0;
  margin-top: 20px;
}
.one-contact-area .contact-content .contact-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.one-contact-area .contact-content .contact-btn i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.one-contact-area .contact-content .contact-btn:hover {
  color: #0d1820;
}
.one-contact-area .contact-content .contact-btn:hover:before {
  width: 0;
}
.one-contact-area .contact-content .contact-emergency {
  position: relative;
  padding-left: 75px;
  margin-top: 15px;
}
.one-contact-area .contact-content .contact-emergency i {
  display: inline-block;
  color: #ffffff;
  position: absolute;
  top: 0;
  left: 0;
  font-size: 60px;
}
.one-contact-area .contact-content .contact-emergency span {
  display: block;
  font-size: 16px;
  color: #ffffff;
  margin-bottom: 2px;
}
.one-contact-area .contact-content .contact-emergency a {
  display: block;
  font-weight: 500;
  font-size: 22px;
  color: #ffffff;
}
.one-contact-area .contact-content .contact-emergency a:hover {
  color: #12ca59;
}
.one-contact-area .contact-content .list-unstyled {
  color: #dc3545;
  font-size: 14px;
  margin-top: 10px;
}
.one-contact-area .contact-content .text-danger {
  color: #dc3545;
  margin-top: 10px;
  font-size: 20px;
}
.one-contact-area .contact-content .text-success {
  color: #28a745;
  margin-top: 10px;
  font-size: 20px;
}

/*-- End Contact --*/
/*-- Blog --*/
.one-blog-area .one-section-title h2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.one-blog-area .blog-item {
  margin-bottom: 30px;
  transition: 0.5s all ease;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6705882353);
  background-color: #ffffff;
}
.one-blog-area .blog-item:hover {
  transform: translate(0, -10px);
}
.one-blog-area .blog-item .blog-top {
  position: relative;
}
.one-blog-area .blog-item .blog-top a {
  display: block;
}
.one-blog-area .blog-item .blog-top a img {
  width: 100%;
}
.one-blog-area .blog-item .blog-top span {
  display: inline-block;
  color: #ffffff;
  font-size: 13px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  padding: 7px 12px;
  position: absolute;
  bottom: 0;
  right: 35px;
}
.one-blog-area .blog-item .blog-bottom {
  padding: 20px 25px 20px;
}
.one-blog-area .blog-item .blog-bottom ul {
  margin: 0;
  padding: 0;
  margin-bottom: 18px;
}
.one-blog-area .blog-item .blog-bottom ul li {
  list-style-type: none;
  display: inline-block;
  color: #767676;
  font-size: 13px;
  margin-right: 10px;
}
.one-blog-area .blog-item .blog-bottom ul li:last-child {
  margin-right: 0;
}
.one-blog-area .blog-item .blog-bottom ul li i {
  display: inline-block;
  color: #395dc7;
  font-size: 14px;
  position: relative;
  top: 1px;
  margin-right: 1px;
}
.one-blog-area .blog-item .blog-bottom h3 {
  font-weight: 600;
  font-size: 20px;
  padding-bottom: 15px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 15px;
}
.one-blog-area .blog-item .blog-bottom h3 a {
  color: #0d1820;
  display: inline-block;
}
.one-blog-area .blog-item .blog-bottom h3 a:hover {
  color: #12ca59;
}
.one-blog-area .blog-item .blog-bottom img {
  display: inline-block;
  margin-right: 3px;
}
.one-blog-area .blog-item .blog-bottom p {
  margin-bottom: 0;
  display: inline-block;
  font-size: 13px;
  color: #525252;
}
.one-blog-area .blog-item .blog-bottom p a {
  display: inline-block;
  color: #525252;
}
.one-blog-area .blog-item .blog-bottom p a:hover {
  color: #12ca59;
}

/*-- End Blog --*/
/*-- Subscribe --*/
.one-subscribe-area .subscribe-wrap {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
  padding: 100px 100px 100px;
}
.one-subscribe-area .subscribe-wrap:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgba(13, 24, 32, 0.8900025614) 43%, rgba(13, 24, 32, 0.2888789659) 95%);
}
.one-subscribe-area .subscribe-wrap h2 {
  color: #ffffff;
  font-size: 35px;
  font-weight: 600;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
  position: relative;
}
.one-subscribe-area .subscribe-wrap .newsletter-form {
  max-width: 550px;
  margin: 0 auto;
  position: relative;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .form-control {
  height: 55px;
  border: 0;
  border-radius: 0;
  padding-left: 25px;
  font-size: 14px;
  border-radius: 4px;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .form-control:focus {
  box-shadow: none;
  border: 0;
}
.one-subscribe-area .subscribe-wrap .newsletter-form ::placeholder {
  color: #888888;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn {
  color: #ffffff;
  position: relative;
  background-color: #0d1820;
  z-index: 1;
  padding: 18px 20px 16px;
  border-radius: 4px;
  font-size: 15px;
  display: inline-block;
  font-weight: 500;
  opacity: 1;
  text-transform: uppercase;
  transition: 0.5s all ease;
  border-radius: 4px;
  position: absolute;
  top: 0;
  right: 0;
  border: 0;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn:hover:before {
  width: 0;
}
.one-subscribe-area .subscribe-wrap .newsletter-form .validation-danger {
  color: #ffffff;
  font-size: 14px;
  margin-top: 10px;
}

/*-- End Subscribe --*/
/*-- Footer --*/
.one-footer-area {
  background-color: #0d1820;
}
.one-footer-area .footer-item {
  margin-bottom: 30px;
}
.one-footer-area .footer-item .footer-logo a {
  display: block;
  margin-bottom: 35px;
}
.one-footer-area .footer-item .footer-logo p {
  font-size: 16px;
  color: #bdcbd5;
  margin-bottom: 14px;
}
.one-footer-area .footer-item .footer-logo ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
}
.one-footer-area .footer-item .footer-logo ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
}
.one-footer-area .footer-item .footer-logo ul li:last-child {
  margin-right: 0;
}
.one-footer-area .footer-item .footer-logo ul li a {
  display: block;
  border: 1px solid #8f8f8f;
  text-align: center;
  border-radius: 50%;
  color: #8f8f8f;
  font-size: 18px;
  margin-bottom: 0;
}
.one-footer-area .footer-item .footer-logo ul li a:hover {
  color: #ffffff;
  background-color: #8f8f8f;
}
.one-footer-area .footer-item .footer-logo ul li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.one-footer-area .footer-item .footer-quick h3 {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px;
  color: #ffffff;
}
.one-footer-area .footer-item .footer-quick ul {
  margin: 0;
  padding: 0;
}
.one-footer-area .footer-item .footer-quick ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 11px;
}
.one-footer-area .footer-item .footer-quick ul li:last-child {
  margin-bottom: 0;
}
.one-footer-area .footer-item .footer-quick ul li a {
  display: block;
  font-size: 16px;
  color: #bdcbd5;
}
.one-footer-area .footer-item .footer-quick ul li a:hover {
  letter-spacing: 1px;
}
.one-footer-area .footer-item .footer-address h3 {
  margin-bottom: 25px;
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 25px;
  color: #ffffff;
}
.one-footer-area .footer-item .footer-address ul {
  margin: 0;
  padding: 0;
}
.one-footer-area .footer-item .footer-address ul li {
  list-style-type: none;
  display: block;
  font-size: 16px;
  color: #bdcbd5;
  margin-bottom: 15px;
  position: relative;
  padding-left: 33px;
}
.one-footer-area .footer-item .footer-address ul li:last-child {
  margin-bottom: 0;
}
.one-footer-area .footer-item .footer-address ul li i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 20px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.one-footer-area .footer-item .footer-address ul li a {
  color: #bdcbd5;
}
.one-footer-area .footer-item .footer-address ul li a:hover {
  letter-spacing: 1px;
}
.one-footer-area .copyright-area {
  margin-top: 70px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #212e37;
}
.one-footer-area .copyright-area .copyright-item ul {
  margin: 0;
  padding: 0;
}
.one-footer-area .copyright-area .copyright-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 8px;
}
.one-footer-area .copyright-area .copyright-item ul li:last-child {
  margin-right: 0;
}
.one-footer-area .copyright-area .copyright-item ul li a {
  display: block;
}
.one-footer-area .copyright-area .copyright-item ul li a:hover {
  transform: translate(0, -5px);
}
.one-footer-area .copyright-area .copyright-item p {
  margin-bottom: 0;
  font-size: 16px;
  color: #767676;
  text-align: right;
}
.one-footer-area .copyright-area .copyright-item p a {
  display: inline-block;
  color: #ffffff;
  border-bottom: 1px solid #ffffff;
}
.one-footer-area .copyright-area .copyright-item p a:hover {
  letter-spacing: 1px;
}

/*-- End Footer --*/
/*----- End Home One CSS -----*/
/*----- Home Two CSS -----*/
/*-- Banner --*/
.two-banner-area {
  height: 850px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  text-align: center;
}
.two-banner-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
.two-banner-area .banner-content {
  position: relative;
  margin-top: -135px;
  max-width: 955px;
  margin-left: auto;
  margin-right: auto;
}
.two-banner-area .banner-content span {
  display: block;
  font-weight: 500;
  font-size: 25px;
  color: #ffffff;
  margin-bottom: 25px;
}
.two-banner-area .banner-content span img {
  max-width: 50px;
  display: inline-block;
  margin-right: 5px;
}
.two-banner-area .banner-content h1 {
  font-weight: 700;
  font-size: 100px;
  color: #ffffff;
  line-height: 110px;
  margin-bottom: 45px;
}
.two-banner-area .banner-content .banner-btn a {
  display: inline-block;
  color: #ffffff;
}
.two-banner-area .banner-content .banner-btn a i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 4px;
}
.two-banner-area .banner-content .banner-btn .cmn-banner-btn {
  margin-right: 10px;
}
.two-banner-area .banner-content .banner-btn .banner-right-btn {
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 11px 25px 13px;
  margin-left: 10px;
}
.two-banner-area .banner-content .banner-btn .banner-right-btn i {
  top: 4px;
}
.two-banner-area .banner-content .banner-btn .banner-right-btn:hover {
  color: #ffffff;
  background-color: #0d1820;
  border: 1px solid #0d1820;
}

.cmn-banner-btn {
  color: #ffffff;
  position: relative;
  background-color: #0d1820;
  z-index: 1;
  padding: 12px 25px 14px;
  border-radius: 4px;
  font-size: 16px;
  display: inline-block;
}
.cmn-banner-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.cmn-banner-btn i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 4px;
}
.cmn-banner-btn:hover {
  color: #ffffff;
}
.cmn-banner-btn:hover:before {
  width: 0;
}

.two-banner-wrap {
  position: relative;
}
.two-banner-wrap .container-fluid {
  padding-left: 70px;
  padding-right: 70px;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 1;
}
.two-banner-wrap .container-fluid .banner-time-left {
  padding: 50px 45px 50px;
  position: relative;
}
.two-banner-wrap .container-fluid .banner-time-left:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.5;
}
.two-banner-wrap .container-fluid .banner-time-left ul {
  margin: 0;
  padding: 0;
  position: relative;
}
.two-banner-wrap .container-fluid .banner-time-left ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  padding-left: 70px;
  margin-right: 22px;
}
.two-banner-wrap .container-fluid .banner-time-left ul li:last-child {
  margin-right: 0;
}
.two-banner-wrap .container-fluid .banner-time-left ul li i {
  position: absolute;
  top: 0px;
  left: 0;
  color: #888888;
  font-size: 55px;
}
.two-banner-wrap .container-fluid .banner-time-left ul li span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #d9d9d9;
  margin-bottom: 5px;
}
.two-banner-wrap .container-fluid .banner-time-left ul li p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.two-banner-wrap .container-fluid .banner-time-left ul li a {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  color: #ffffff;
}
.two-banner-wrap .container-fluid .banner-time-left ul li a:hover {
  color: #12ca59;
}
.two-banner-wrap .container-fluid .banner-time-right {
  background-color: #ffffff;
  padding: 50px 35px 50px 45px;
  box-shadow: 0px 10px 20px -10px rgba(221, 221, 221, 0.6117647059);
}
.two-banner-wrap .container-fluid .banner-time-right ul {
  margin: 0;
  padding: 0;
}
.two-banner-wrap .container-fluid .banner-time-right ul li {
  list-style-type: none;
  display: inline-block;
  position: relative;
  padding-left: 70px;
  margin-right: 22px;
}
.two-banner-wrap .container-fluid .banner-time-right ul li:last-child {
  margin-right: 0;
}
.two-banner-wrap .container-fluid .banner-time-right ul li i {
  position: absolute;
  top: 3px;
  left: 0;
  background: linear-gradient(90deg, #10c317 23%, #15d09a 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 55px;
}
.two-banner-wrap .container-fluid .banner-time-right ul li span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #5d5d5d;
  margin-bottom: 5px;
}
.two-banner-wrap .container-fluid .banner-time-right ul li p {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 18px;
  color: #3c3b3b;
}
.two-banner-wrap .container-fluid .banner-time-right ul li a {
  display: inline-block;
  font-weight: 500;
  font-size: 18px;
  color: #414141;
}
.two-banner-wrap .container-fluid .banner-time-right ul li a:hover {
  color: #12ca59;
}
.two-banner-wrap .two-banner-slider:hover .owl-prev {
  opacity: 1;
  left: 40px;
}
.two-banner-wrap .two-banner-slider:hover .owl-next {
  opacity: 1;
  right: 40px;
}
.two-banner-wrap .two-banner-slider .owl-prev {
  position: absolute;
  top: 45%;
  left: 30px;
  font-size: 50px !important;
  color: #fff !important;
  opacity: 0;
  transition: 0.5s all ease;
}
.two-banner-wrap .two-banner-slider .owl-prev:hover {
  color: #12ca59 !important;
  background-color: transparent !important;
}
.two-banner-wrap .two-banner-slider .owl-next {
  position: absolute;
  top: 45%;
  right: 30px;
  font-size: 50px !important;
  color: #fff !important;
  opacity: 0;
  transition: 0.5s all ease;
}
.two-banner-wrap .two-banner-slider .owl-next:hover {
  color: #12ca59 !important;
  background-color: transparent !important;
}
.two-banner-wrap .owl-theme .owl-nav {
  margin-top: 0;
}

/*-- End Banner --*/
/*-- Mission --*/
.two-mission-area .one-section-title h2 {
  max-width: 825px;
  margin-left: auto;
  margin-right: auto;
}
.two-mission-area .mission-content {
  position: relative;
  margin-bottom: -35px;
}
.two-mission-area .mission-content img {
  border-radius: 5px;
}
.two-mission-area .mission-content .mission-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding-right: 50px;
  padding-left: 50px;
}
.two-mission-area .mission-content .mission-bottom ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.two-mission-area .mission-content .mission-bottom ul li {
  list-style-type: none;
  display: inline-block;
  border-right: 1px dashed #000000;
  background-color: #ffffff;
  border-radius: 4px;
}
.two-mission-area .mission-content .mission-bottom ul li:nth-child(1) {
  flex: 0 0 40%;
  max-width: 40%;
  background-color: unset;
  border-right: 0;
}
.two-mission-area .mission-content .mission-bottom ul li:nth-child(2) {
  flex: 0 0 15%;
  max-width: 15%;
}
.two-mission-area .mission-content .mission-bottom ul li:nth-child(3) {
  flex: 0 0 15%;
  max-width: 15%;
}
.two-mission-area .mission-content .mission-bottom ul li:nth-child(4) {
  flex: 0 0 15%;
  max-width: 15%;
}
.two-mission-area .mission-content .mission-bottom ul li:nth-child(5) {
  flex: 0 0 15%;
  max-width: 15%;
  border-right: 0;
}
.two-mission-area .mission-content .mission-bottom ul li a {
  display: inline-block;
  border-radius: 50%;
  color: #ffffff;
  background-color: #0d1820;
  text-align: center;
  font-size: 45px;
  margin-right: 15px;
}
.two-mission-area .mission-content .mission-bottom ul li a i {
  width: 75px;
  height: 75px;
  line-height: 75px;
}
.two-mission-area .mission-content .mission-bottom ul li a:hover {
  background-color: #12ca59;
}
.two-mission-area .mission-content .mission-bottom ul li p {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 0;
  position: relative;
  top: -13px;
}
.two-mission-area .mission-content .mission-bottom ul li .mission-item {
  background-color: #ffffff;
  text-align: center;
  padding: 20px 20px 18px;
}
.two-mission-area .mission-content .mission-bottom ul li .mission-item i {
  display: block;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  margin-bottom: 14px;
}
.two-mission-area .mission-content .mission-bottom ul li .mission-item span {
  display: block;
  font-weight: 500;
  font-size: 16px;
  color: #0d1820;
}

/*-- End Mission --*/
/*-- Counter --*/
.two-counter-area {
  padding-top: 135px;
}

/*-- End Counter --*/
/*-- Among --*/
.two-among-area .among-item {
  margin: 0;
  margin-bottom: 30px;
  background-color: #edfffb;
}
.two-among-area .among-item:hover {
  background-color: #ffffff;
}
.two-among-area .among-item:hover .among-top .among-icon {
  transform: rotateY(360deg);
}
.two-among-area .among-item:hover .among-bottom {
  border-bottom: none;
}
.two-among-area .among-item .among-top .among-icon {
  left: 40%;
}
.two-among-area .among-item .among-bottom {
  border-bottom: none;
  padding: 38px 20px 30px;
}
.two-among-area .among-item .among-bottom h3 {
  font-size: 19px;
}

/*-- End Among --*/
/*-- House --*/
.two-house-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.two-house-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #0d1820;
  opacity: 0.8;
}
.two-house-area .house-item {
  margin-bottom: 30px;
  text-align: center;
  position: relative;
  transition: 0.5s all ease;
}
.two-house-area .house-item:hover {
  transform: translate(0, -10px);
}
.two-house-area .house-item:hover .house-icon:before {
  transform: rotate(200deg);
}
.two-house-area .house-item:hover .house-icon:after {
  transform: rotate(-200deg);
}
.two-house-area .house-item:hover a {
  color: #12ca59;
}
.two-house-area .house-item:hover a i {
  left: -1px;
  color: #12ca59;
}
.two-house-area .house-item .house-icon {
  display: inline-block;
  position: relative;
  text-align: center;
  width: 80px;
  height: 80px;
  margin-top: 15px;
  margin-bottom: 35px;
}
.two-house-area .house-item .house-icon:before {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(35deg);
  transition: 0.5s all ease;
}
.two-house-area .house-item .house-icon:after {
  position: absolute;
  content: "";
  width: 80px;
  height: 80px;
  left: 0;
  right: 0;
  top: 0;
  background-color: #ffffff;
  margin-left: auto;
  margin-right: auto;
  transform: rotate(55deg);
  opacity: 0.5;
  transition: 0.5s all ease;
}
.two-house-area .house-item .house-icon i {
  display: inline-block;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 45px;
  z-index: 1;
  position: relative;
  text-align: center;
  margin-top: 15px;
}
.two-house-area .house-item h3 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 12px;
}
.two-house-area .house-item p {
  color: #dfdfdf;
  font-size: 15px;
  margin-bottom: 15px;
}
.two-house-area .house-item a {
  display: inline-block;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
}
.two-house-area .house-item a i {
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 2px;
  left: 0;
  transition: 0.5s all ease;
}

/*-- End House --*/
/*-- Address --*/
.two-address-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #edfffb;
  text-align: center;
  position: relative;
  padding-bottom: 190px;
}
.two-address-area .one-quote-area {
  position: absolute;
  bottom: -100px;
  right: 0;
  left: 0;
  padding-bottom: 0;
}
.two-address-area .one-quote-area .row {
  background-color: #ffffff;
}
.two-address-area .address-item {
  margin-bottom: 30px;
}
.two-address-area .address-item ul {
  margin: 0;
  padding: 0;
  margin-top: -35px;
  margin-bottom: 15px;
}
.two-address-area .address-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;
  margin-left: 5px;
}
.two-address-area .address-item ul li a {
  display: block;
  font-size: 40px;
  text-align: center;
  border-radius: 50%;
  color: #0d1820;
  background-color: #ffffff;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.two-address-area .address-item ul li a:before {
  position: absolute;
  content: "";
  width: 0;
  height: 70px;
  left: 0;
  top: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  border-radius: 50%;
  z-index: -1;
  opacity: 0;
  transition: 0.5s all ease;
}
.two-address-area .address-item ul li a i {
  width: 70px;
  height: 70px;
  line-height: 70px;
}
.two-address-area .address-item ul li a:hover {
  color: #ffffff;
}
.two-address-area .address-item ul li a:hover:before {
  opacity: 1;
  width: 70px;
}
.two-address-area .address-item h3 {
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 12px;
}
.two-address-area .address-item p {
  margin-bottom: 0;
}

/*-- End Address --*/
/*-- Team --*/
.two-team-area {
  padding-top: 195px;
}

/*-- End Team --*/
/*-- Help --*/
.two-help-area .help-img-two {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.two-help-area .help-img-two img {
  display: none;
}
.two-help-area .help-item {
  max-width: 680px;
  margin-left: auto;
  padding-right: 55px;
  padding-top: 100px;
  padding-bottom: 100px;
}
.two-help-area .help-item ul li {
  flex: 0 0 100%;
  max-width: 100%;
}
.two-help-area .help-item ul li .help-inner p {
  max-width: 285px;
}

/*-- End Help --*/
/*-- Lover --*/
.two-lover-area .lover-item {
  margin-bottom: 30px;
}
.two-lover-area .lover-item ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6392156863);
  padding: 40px 30px 40px;
}
.two-lover-area .lover-item ul li {
  list-style-type: none;
  display: inline-block;
  flex: 0 0 35%;
  max-width: 35%;
}
.two-lover-area .lover-item ul li:last-child {
  flex: 0 0 65%;
  max-width: 65%;
}
.two-lover-area .lover-item ul li .lover-img {
  position: relative;
  display: inline-block;
}
.two-lover-area .lover-item ul li .lover-img:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 130px;
  right: -23px;
  top: 0;
  background-color: #dddddd;
}
.two-lover-area .lover-item ul li .lover-img img {
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6392156863);
}
.two-lover-area .lover-item ul li .lover-img i {
  display: inline-block;
  color: #ffffff;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  text-align: center;
  font-size: 20px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  position: absolute;
  bottom: 0;
  right: 0;
}
.two-lover-area .lover-item ul li .lover-content h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 7px;
}
.two-lover-area .lover-item ul li .lover-content span {
  display: block;
  font-size: 15px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 5px;
}
.two-lover-area .lover-item ul li .lover-content p {
  margin-bottom: 10px;
}
.two-lover-area .lover-item ul li .lover-content ul {
  display: block;
  margin: 0;
  padding: 0;
  box-shadow: none;
}
.two-lover-area .lover-item ul li .lover-content ul li {
  flex: 0 0 100%;
  max-width: 100%;
}
.two-lover-area .lover-item ul li .lover-content ul li:last-child {
  flex: 0 0 100%;
  max-width: 100%;
}
.two-lover-area .lover-item ul li .lover-content ul li i {
  display: inline-block;
}
.two-lover-area .lover-item ul li .lover-content ul li .checked {
  color: #ff9c00;
}

/*-- End Lover --*/
/*-- FAQ --*/
.two-faq-area {
  background-color: transparent;
  background-image: unset;
}
.two-faq-area .faq-img-two {
  margin-bottom: 30px;
}
.two-faq-area .faq-img-two img {
  width: 100%;
}
.two-faq-area .faq-content {
  margin-bottom: 30px;
}

/*-- End FAQ --*/
/*-- Contact --*/
.two-contact-area {
  background-color: transparent;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.two-contact-area:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(13, 24, 32, 0.1765194154) 15%, rgba(13, 24, 32, 0.8900025614) 67%);
}
.two-contact-area .contact-content {
  padding-top: 0;
  padding-bottom: 0;
  margin-left: auto;
  position: relative;
}
.two-contact-area .contact-content .one-section-title {
  margin-left: 0;
}

/*-- End Contact --*/
/*-- Blog --*/
.two-blog-area .blog-item {
  background-color: #edfffb;
}
.two-blog-area .blog-item:hover {
  background-color: #ffffff;
}

/*-- End Blog --*/
/*-- Subscribe --*/
.two-subscribe-area {
  background-color: #edfffb;
}
.two-subscribe-area .subscribe-wrap {
  background-image: unset;
  padding: 0;
}
.two-subscribe-area .subscribe-wrap:before {
  display: none;
}
.two-subscribe-area .subscribe-wrap h2 {
  color: #0d1820;
  margin-bottom: 0;
}
.two-subscribe-area .subscribe-wrap .newsletter-form {
  max-width: 100%;
}

/*-- End Subscribe --*/
/*----- End Home Two CSS -----*/
/*----- Home Three CSS -----*/
/*-- Header Top --*/
.two-header-top {
  background-color: #0d1820;
}
.two-header-top .header-partner p {
  color: #b8bcbf;
}
.two-header-top .header-partner p a {
  color: #ffffff;
}
.two-header-top .header-partner p a:hover {
  color: #12ca59;
}
.two-header-top .header-call ul li span {
  color: #b8bcbf;
}
.two-header-top .header-call ul li a {
  color: #ffffff;
}
.two-header-top .header-call ul li a:hover {
  color: #12ca59;
}

/*-- End Header Top --*/
/*-- Navbar --*/
.navbar-light-two {
  box-shadow: 0px 18px 52px 0px rgba(221, 221, 221, 0.6196078431);
}
.navbar-light-two .side-nav .side-nav-right {
  border-radius: 50px;
}
.navbar-light-two .side-nav .side-nav-right:before {
  border-radius: 50px;
}

/*-- End Navbar --*/
/*-- Banner --*/
.three-banner-area {
  height: 730px;
  padding-left: 40px;
  padding-right: 40px;
  overflow: hidden;
  position: relative;
}
.three-banner-area .banner-shape img {
  position: absolute;
}
.three-banner-area .banner-shape img:nth-child(1) {
  left: 0;
  top: 60px;
}
.three-banner-area .banner-shape img:nth-child(2) {
  left: 0;
  top: 0;
  z-index: -1;
  max-width: 500px;
}
.three-banner-area .banner-content span {
  display: block;
  font-weight: 500;
  font-size: 25px;
  color: #0d1820;
  margin-bottom: 20px;
}
.three-banner-area .banner-content span img {
  max-width: 50px;
  display: inline-block;
  margin-right: 5px;
}
.three-banner-area .banner-content h1 {
  font-weight: 700;
  font-size: 92px;
  margin-bottom: 20px;
}
.three-banner-area .banner-content p {
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 17px;
  max-width: 695px;
}
.three-banner-area .banner-content ul {
  margin: 0;
  padding: 0;
}
.three-banner-area .banner-content ul li {
  list-style-type: none;
  display: inline-block;
}
.three-banner-area .banner-content ul li .cmn-banner-btn {
  margin-right: 25px;
  border-radius: 50px;
  overflow: hidden;
  top: 10px;
}
.three-banner-area .banner-content ul li .cmn-banner-btn:before {
  border-radius: 50px;
}
.three-banner-area .banner-content ul li .cmn-banner-btn:hover {
  color: #ffffff;
}
.three-banner-area .banner-content ul li .banner-play {
  text-align: center;
  background-color: #ffffff;
  color: #12ca59;
  font-size: 35px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6196078431);
  display: inline-block;
  border-radius: 50%;
}
.three-banner-area .banner-content ul li .banner-play:hover {
  color: #ffffff;
  background-color: #12ca59;
}
.three-banner-area .banner-content ul li .banner-play i {
  width: 65px;
  height: 65px;
  line-height: 65px;
}
.three-banner-area .banner-content ul li span {
  display: inline-block;
  font-weight: 500;
  font-size: 20px;
  color: #333333;
  margin-bottom: 0;
  margin-left: 10px;
  position: relative;
  top: -7px;
}
.three-banner-area .banner-content ul li:last-child {
  margin-top: -10px;
}
.three-banner-area .banner-img img:nth-child(2) {
  position: absolute;
  left: -18px;
  top: -80px;
  max-width: 115px;
  z-index: -1;
}
.three-banner-area .banner-img img:nth-child(3) {
  position: absolute;
  left: -52px;
  top: -142px;
  max-width: 115px;
  z-index: -1;
  transform: rotate(2deg);
}

/*-- End Banner --*/
/*-- Time --*/
.three-banner-wrap .container-fluid {
  position: relative;
}
.three-banner-wrap .container-fluid .banner-time-left {
  background-color: #0d1820;
}
.three-banner-wrap .container-fluid .banner-time-left ul li a {
  border-bottom: 1px solid #ffffff;
}
.three-banner-wrap .container-fluid .banner-time-left ul li a:hover {
  border-bottom: 1px solid #12ca59;
}
.three-banner-wrap .container-fluid .banner-time-right {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 50px 35px 50px 35px;
}

/*-- End Time --*/
/*-- About --*/
.three-about-area {
  padding-top: 150px;
  position: relative;
}
.three-about-area .about-shape {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.three-about-area .about-img {
  position: relative;
  margin-bottom: 30px;
}
.three-about-area .about-img img:nth-child(2) {
  position: absolute;
  left: -85px;
  top: -50px;
  z-index: -1;
}
.three-about-area .about-img img:nth-child(3) {
  position: absolute;
  left: -45px;
  top: 0;
  z-index: -1;
}
.three-about-area .about-img .about-inner {
  background-color: #10c31a;
  display: inline-block;
  position: absolute;
  bottom: 15px;
  right: 0;
  border-radius: 4px;
  max-width: 380px;
  margin-left: auto;
  padding: 20px 30px 30px;
}
.three-about-area .about-img .about-inner h3 {
  color: #ffffff;
  font-weight: 600;
  font-size: 40px;
  margin-bottom: 5px;
}
.three-about-area .about-img .about-inner p {
  margin-bottom: 0;
  font-size: 16px;
  color: #daffdc;
}
.three-about-area .about-content {
  margin-bottom: 30px;
}
.three-about-area .about-content .two-section-title {
  text-align: left;
  margin-bottom: 35px;
}
.three-about-area .about-content .about-mission .nav-pills {
  margin-bottom: 25px;
}
.three-about-area .about-content .about-mission .nav-pills li {
  margin-right: 12px;
}
.three-about-area .about-content .about-mission .nav-pills li:last-child {
  margin-right: 0;
}
.three-about-area .about-content .about-mission .nav-pills li a {
  font-weight: 500;
  font-size: 15px;
  color: #626262;
  background-color: #dfdfdf;
  border-radius: 30px;
  display: inline-block;
  padding: 10px 22px 10px 20px;
}
.three-about-area .about-content .about-mission .nav-pills li a i {
  display: inline-block;
  font-size: 18px;
  position: relative;
  top: 2px;
  margin-right: 2px;
}
.three-about-area .about-content .about-mission .nav-pills li .nav-default {
  position: relative;
  z-index: 1;
}
.three-about-area .about-content .about-mission .nav-pills li .nav-default:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  opacity: 0;
  z-index: -1;
}
.three-about-area .about-content .about-mission .nav-pills li .nav-default.active {
  color: #ffffff;
}
.three-about-area .about-content .about-mission .nav-pills li .nav-default.active:before {
  opacity: 1;
}
.three-about-area .about-content .about-mission .tab-content p {
  margin-bottom: 12px;
}
.three-about-area .about-content .about-mission .tab-content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.three-about-area .about-content .about-mission .tab-content ul li {
  list-style-type: none;
  display: block;
  font-size: 16px;
  color: #444444;
  margin-bottom: 10px;
}
.three-about-area .about-content .about-mission .tab-content ul li:last-child {
  margin-bottom: 0;
}
.three-about-area .about-content .about-mission .tab-content ul li i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 4px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.three-about-area .about-content .about-mission .tab-content .cmn-banner-btn {
  border-radius: 30px;
  color: #ffffff;
  overflow: hidden;
}
.three-about-area .about-content .about-mission .tab-content .cmn-banner-btn:before {
  border-radius: 30px;
}
.three-about-area .about-content .about-mission .tab-content .cmn-banner-btn i {
  top: 4px;
}

.two-section-title .sub-title {
  margin-bottom: 7px;
  padding-bottom: 7px;
}
.two-section-title .sub-title:before {
  left: 0;
  right: unset;
  bottom: 0;
  width: 22px;
}
.two-section-title h2 {
  font-size: 40px;
}

/*-- End About --*/
/*-- Among --*/
.three-among-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
  padding-bottom: 200px;
}
.three-among-area:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.95;
  background-color: #ffffff;
}
.three-among-area .two-section-title {
  position: relative;
}
.three-among-area .among-item {
  background-color: #ffffff;
  text-align: center;
}
.three-among-area .one-quote-area {
  position: absolute;
  bottom: -100px;
  right: 0;
  left: 0;
  padding-bottom: 0;
}
.three-among-area .one-quote-area .row {
  background-color: #ffffff;
}

/*-- End Among --*/
/*-- Process --*/
.three-process-area {
  padding-top: 195px;
  position: relative;
}
.three-process-area .counter-shape img {
  position: absolute;
}
.three-process-area .counter-shape img:nth-child(1) {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
}
.three-process-area .counter-shape img:nth-child(2) {
  position: absolute;
  top: 0;
  right: 0;
  z-index: -1;
}
.three-process-area .container-fluid {
  padding-right: 55px;
  padding-left: 55px;
}
.three-process-area .process-item {
  box-shadow: 0px 0px 65px 0px rgba(221, 221, 221, 0.431372549);
  border-radius: 4px;
  position: relative;
  padding: 55px 20px 30px 40px;
  transition: 0.5s all ease;
  margin-bottom: 85px;
  max-width: 320px;
  background-color: #ffffff;
}
.three-process-area .process-item:nth-child(2) {
  margin-left: 25px;
}
.three-process-area .process-item:nth-child(3) {
  margin-bottom: 30px;
}
.three-process-area .process-item:hover {
  background-color: #12ca59;
}
.three-process-area .process-item:hover h3 {
  color: #ffffff;
}
.three-process-area .process-item:hover p {
  color: #ffffff;
}
.three-process-area .process-item:hover:before {
  border-left: 35px solid #12ca59;
}
.three-process-area .process-item:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  right: -60px;
  top: 50%;
  border: 25px solid transparent;
  border-left: 35px solid #ffffff;
  transform: translateY(-50%);
  transition: 0.5s all ease;
}
.three-process-area .process-item .process-span {
  display: inline-block;
  background-color: #ffffff;
  position: absolute;
  top: -36px;
  left: 40px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  transition: 0.5s all ease;
}
.three-process-area .process-item .process-span span {
  display: inline-block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  box-shadow: 0px 0px 25px 0px rgba(221, 221, 221, 0.431372549);
  text-align: center;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 26px;
  transition: 0.5s all ease;
}
.three-process-area .process-item h3 {
  font-weight: 500;
  font-size: 20px;
  color: #1a1b1e;
  margin-bottom: 6px;
  transition: 0.5s all ease;
}
.three-process-area .process-item p {
  margin-bottom: 0;
  color: #848484;
  font-size: 16px;
  transition: 0.5s all ease;
}
.three-process-area .process-two {
  margin-left: auto;
}
.three-process-area .process-two:nth-child(2) {
  margin-right: -25px;
  margin-left: auto;
}
.three-process-area .process-two:before {
  transform: rotate(180deg);
  right: unset;
  left: -59px;
  top: 35%;
}
.three-process-area .process-img {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.three-process-area .process-img img:nth-child(1) {
  margin-left: 40px;
  position: relative;
  z-index: 1;
}
.three-process-area .process-img img:nth-child(2) {
  position: absolute;
  left: -30px;
  bottom: 30px;
  max-width: 105px;
  z-index: -1;
  margin-left: 40px;
}
.three-process-area .process-img img:nth-child(3) {
  position: absolute;
  left: -20px;
  bottom: 0;
  max-width: 100px;
  z-index: -1;
  margin-left: 40px;
}
.three-process-area .process-img img:nth-child(4) {
  position: absolute;
  left: -75px;
  bottom: 8px;
  z-index: -1;
}
.three-process-area .process-img img:nth-child(5) {
  position: absolute;
  left: -85px;
  bottom: 0px;
  opacity: 0.2;
}
.three-process-area .process-img img:nth-child(6) {
  position: absolute;
  right: -110px;
  bottom: 8px;
  z-index: -1;
}
.three-process-area .process-img img:nth-child(7) {
  position: absolute;
  right: -103px;
  bottom: 0px;
  opacity: 0.2;
}

/*-- End Process --*/
/*-- Counter --*/
.three-counter-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.three-counter-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.7;
}

/*-- End Counter --*/
/*-- Team --*/
.three-team-area .team-item {
  background-color: #dbf6df;
  border-radius: 4px;
  text-align: center;
  margin-bottom: 30px;
  transition: 0.5s all ease;
}
.three-team-area .team-item:hover {
  background-color: #ffffff;
  transform: translate(0, -10px);
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
}
.three-team-area .team-item .team-top {
  position: relative;
}
.three-team-area .team-item .team-top img {
  width: 100%;
}
.three-team-area .team-item .team-top ul {
  margin: 0;
  padding: 0;
  border-radius: 4px;
  position: absolute;
  left: 7px;
  right: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  max-width: 175px;
}
.three-team-area .team-item .team-top ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: -4px;
}
.three-team-area .team-item .team-top ul li:nth-child(2) a {
  background-color: #3f70ff;
}
.three-team-area .team-item .team-top ul li:nth-child(3) a {
  background-color: #e52f29;
}
.three-team-area .team-item .team-top ul li:nth-child(4) a {
  background-color: #b92722;
}
.three-team-area .team-item .team-top ul li:nth-child(5) a {
  background-color: #11c635;
}
.three-team-area .team-item .team-top ul li a {
  display: block;
  background-color: #0331b8;
  color: #ffffff;
  text-align: center;
  font-size: 17px;
}
.three-team-area .team-item .team-top ul li a:hover {
  transform: translate(0, -8px);
}
.three-team-area .team-item .team-top ul li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.three-team-area .team-item .team-bottom {
  position: relative;
  padding: 20px 15px 24px;
}
.three-team-area .team-item .team-bottom i {
  display: inline-block;
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 50px;
  color: #b6eecd;
}
.three-team-area .team-item .team-bottom h3 {
  margin-bottom: 5px;
  color: #08104d;
  font-weight: 500;
  font-size: 20px;
}
.three-team-area .team-item .team-bottom span {
  display: block;
  font-size: 15px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.three-team-area .team-call {
  text-align: center;
}
.three-team-area .team-call p {
  display: inline-block;
  font-weight: 600;
  font-size: 18px;
  color: #6f6f78;
  margin-bottom: 0;
  position: relative;
  padding-left: 15px;
}
.three-team-area .team-call p a {
  display: inline-block;
  color: #0d1820;
  font-size: 25px;
}
.three-team-area .team-call p a:hover {
  color: #12ca59;
}
.three-team-area .team-call p i {
  display: inline-block;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  position: absolute;
  left: -45px;
  bottom: -1px;
}

/*-- End Team --*/
/*-- Loan --*/
.three-loan-area {
  background-color: #0d1820;
}
.three-loan-area .two-section-title {
  margin-bottom: 30px;
}
.three-loan-area .two-section-title h2 {
  color: #ffffff;
}
.three-loan-area .sorting-menu {
  text-align: center;
  margin-bottom: 50px;
}
.three-loan-area .sorting-menu ul {
  margin: 0;
  padding: 0;
}
.three-loan-area .sorting-menu ul li {
  display: inline-block;
  font-weight: 500;
  color: #a4bdcf;
  font-size: 30px;
  cursor: pointer;
  margin-left: 25px;
  margin-right: 60px;
  border-bottom: 2px solid transparent;
  position: relative;
}
.three-loan-area .sorting-menu ul li:last-child {
  margin-right: 0;
}
.three-loan-area .sorting-menu ul li.mixitup-control-active {
  color: #ffffff;
  border-bottom: 2px solid #ffffff;
}
.three-loan-area .sorting-menu ul li.mixitup-control-active i {
  color: #ffffff;
}
.three-loan-area .sorting-menu ul li i {
  display: inline-block;
  color: #888888;
  font-size: 45px;
  position: absolute;
  top: 0px;
  left: -50px;
}
.three-loan-area .loan-item {
  background-color: #1f313e;
  border-radius: 4px;
  padding: 30px 25px 30px;
  transition: 0.5s all ease;
  margin-bottom: 30px;
}
.three-loan-area .loan-item:hover {
  background-color: #ffffff;
}
.three-loan-area .loan-item:hover span {
  color: #12ca59;
  background-color: #cff3d2;
}
.three-loan-area .loan-item:hover h3 {
  color: #0d1820;
}
.three-loan-area .loan-item:hover p {
  color: #777777;
}
.three-loan-area .loan-item:hover a {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.three-loan-area .loan-item:hover a i {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.three-loan-area .loan-item span {
  display: inline-block;
  font-size: 14px;
  color: #b2c4cf;
  border-radius: 4px;
  padding: 10px 12px;
  background-color: #33444f;
  margin-bottom: 15px;
  transition: 0.5s all ease;
}
.three-loan-area .loan-item h3 {
  font-weight: 500;
  font-size: 20px;
  color: #ffffff;
  margin-bottom: 10px;
  transition: 0.5s all ease;
}
.three-loan-area .loan-item p {
  margin-bottom: 12px;
  font-size: 15px;
  color: #cad7ff;
  transition: 0.5s all ease;
}
.three-loan-area .loan-item a {
  display: inline-block;
  font-size: 14px;
  color: #eef8ff;
}
.three-loan-area .loan-item a i {
  display: inline-block;
  position: relative;
  top: 2px;
  font-size: 17px;
  transition: 0.5s all ease;
}

/*-- End Loan --*/
/*-- FAQ --*/
.three-faq-area {
  position: relative;
}
.three-faq-area .faq-shape img {
  position: absolute;
}
.three-faq-area .faq-shape img:nth-child(1) {
  top: 0;
  left: 0;
  z-index: -1;
}
.three-faq-area .faq-shape img:nth-child(2) {
  bottom: 0;
  right: 0;
}
.three-faq-area .faq-shape img:nth-child(3) {
  top: 80px;
  left: 0;
}
.three-faq-area .faq-img-two {
  position: relative;
}
.three-faq-area .faq-img-two img:nth-child(1) {
  position: relative;
}
.three-faq-area .faq-img-two img:nth-child(2) {
  position: absolute;
  left: -15px;
  top: -13px;
  z-index: -1;
  max-width: 90px;
}
.three-faq-area .faq-img-two img:nth-child(3) {
  position: absolute;
  left: -25px;
  top: -35px;
  z-index: -1;
  max-width: 90px;
}

/*-- End FAQ --*/
/*-- Contact --*/
.three-contact-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.three-contact-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.3;
}
.three-contact-area .contact-content {
  padding: 50px 35px 50px;
  max-width: 635px;
  margin-left: auto;
  background-color: #fafafa;
  border-radius: 4px;
  position: relative;
}
.three-contact-area .contact-content .two-section-title {
  margin-left: 0;
}
.three-contact-area .contact-content .two-section-title h2 {
  color: #0d1820;
  font-size: 35px;
}
.three-contact-area .contact-content .contact-btn {
  border-radius: 30px;
  background-color: #0d1820;
  overflow: hidden;
  padding: 11px 14px 12px 22px;
}
.three-contact-area .contact-content .contact-btn:before {
  border-radius: 30px;
}
.three-contact-area .contact-content .contact-btn:hover {
  color: #ffffff;
}

/*-- End Contact --*/
/*-- Review --*/
.three-review-area .three-review-slider .review-item {
  text-align: center;
  margin-top: -13px;
}
.three-review-area .three-review-slider .review-item i {
  display: inline-block;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 85px;
  margin-bottom: 15px;
}
.three-review-area .three-review-slider .review-item h2 {
  font-weight: 500;
  font-size: 18px;
  color: white;
  font-style: italic;
  margin-bottom: 35px;
  font-family: "Poppins", sans-serif;
  max-width: 1025px;
  margin-left: auto;
  margin-right: auto;
  line-height: 39px;
}
.three-review-area .three-review-slider .review-item .review-bottom {
  position: relative;
  display: inline-block;
  text-align: left;
}
.three-review-area .three-review-slider .review-item .review-bottom img {
  position: absolute;
  top: -6px;
  left: -75px;
  max-width: 55px;
}
.three-review-area .three-review-slider .review-item .review-bottom h3 {
  font-weight: 600;
  color: white;
  font-size: 16px;
  margin-bottom: 5px;
}
.three-review-area .three-review-slider .review-item .review-bottom span {
  display: block;
  font-style: 15px;
  color: white;
}
.three-review-area .owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 35px;
}
.three-review-area .owl-theme .owl-dots .owl-dot span {
  width: 11px;
  height: 11px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%) !important;
}
.three-review-area .owl-theme .owl-dots .owl-dot.active span {
  width: 15px;
  height: 15px;
  position: relative;
}
.three-review-area .owl-theme .owl-dots .owl-dot.active span::before {
  position: absolute;
  content: "";
  width: 7px;
  height: 6px;
  top: 4px;
  right: 3px;
  border-radius: 50%;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*-- End Review --*/
/*-- Join --*/
.three-join-area .row {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  padding: 50px 60px 55px 115px;
}
.three-join-area .row:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #fff;
  opacity: 0.8;
  border-radius: 4px;
}
.three-join-area .join-item {
  position: relative;
}
.three-join-area .join-item:before {
  position: absolute;
  content: "";
  width: 2px;
  height: 100px;
  top: 10px;
  right: 60px;
  background-color: #e1e1e1;
}
.three-join-area .join-item h3 {
  font-weight: 600;
  font-size: 30px;
  color: #1a1b1e;
  margin-bottom: 15px;
}
.three-join-area .join-item .cmn-banner-btn {
  font-size: 18px;
  padding: 15px 25px 17px;
  border-radius: 50px;
  overflow: hidden;
  color: #ffffff;
}
.three-join-area .join-item .cmn-banner-btn:before {
  border-radius: 50px;
}
.three-join-area .join-item .cmn-banner-btn i {
  top: 4px;
}
.three-join-area .join-two:before {
  display: none;
}

/*-- End Join --*/
/*-- Subscribe --*/
.three-subscribe-area {
  padding-top: 50px;
  padding-bottom: 50px;
}

/*-- End Subscribe --*/
/*----- End Home Three CSS -----*/
/*----- Home Four CSS -----*/
/*-- Header Top --*/
.four-header-top .header-partner p a {
  color: transparent;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.four-header-top .header-call ul li span {
  color: transparent;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*-- End Header Top --*/
/*-- Navbar --*/
.navbar-light-four {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  position: relative;
  z-index: 1;
}
.navbar-light-four.sticky-top {
  position: sticky;
  z-index: 1020;
}
.navbar-light-four:before {
  position: absolute;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  content: "";
  z-index: -1;
  opacity: 0.2;
}
.navbar-light-four .navbar-nav .nav-link {
  color: #ffffff;
  position: relative;
}
.navbar-light-four .navbar-nav .nav-link:before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  top: 17px;
  left: -5px;
  background-color: #ffffff;
  opacity: 0;
  transition: 0.5s all ease;
}
.navbar-light-four .navbar-nav .active > .nav-link, .navbar-light-four .navbar-nav .nav-link.active, .navbar-light-four .navbar-nav .nav-link.show, .navbar-light-four .navbar-nav .show > .nav-link {
  color: #ffffff;
}
.navbar-light-four .navbar-nav .active > .nav-link:before, .navbar-light-four .navbar-nav .nav-link.active:before, .navbar-light-four .navbar-nav .nav-link.show:before, .navbar-light-four .navbar-nav .show > .nav-link:before {
  opacity: 1;
}
.navbar-light-four .navbar-nav .nav-link:focus, .navbar-light-four .navbar-nav .nav-link:hover {
  color: #ffffff;
}
.navbar-light-four .navbar-nav .nav-link:focus:before, .navbar-light-four .navbar-nav .nav-link:hover:before {
  opacity: 1;
}
.navbar-light-four .side-nav .side-nav-left {
  color: #ffffff;
}
.navbar-light-four .side-nav .side-nav-left:hover {
  color: #0d1820;
}
.navbar-light-four .side-nav .side-nav-right {
  color: #12ca59;
  background-color: #ffffff;
  border-radius: 50px;
}
.navbar-light-four .side-nav .side-nav-right:hover {
  color: #ffffff;
  background-color: #12ca59;
}
.navbar-light-four .side-nav .side-nav-right:before {
  display: none;
}

/*-- End Navbar --*/
/*-- Banner --*/
.three-section-title .sub-title:before {
  display: none;
}
.three-section-title h2 {
  font-size: 40px;
}

.four-banner-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.four-banner-area:before {
  position: absolute;
  content: "";
  z-index: -1;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
.four-banner-area .banner-content span {
  color: #ffffff;
}
.four-banner-area .banner-content h1 {
  color: #ffffff;
}
.four-banner-area .banner-content p {
  color: #ffffff;
}
.four-banner-area .banner-content ul li span {
  color: #ffffff;
}
.four-banner-area .banner-form {
  background-color: #fafafa;
  padding: 35px 20px 40px;
  border-radius: 4px;
}
.four-banner-area .banner-form .three-section-title {
  text-align: left;
  margin-bottom: 25px;
}
.four-banner-area .banner-form .three-section-title h2 {
  font-size: 35px;
}
.four-banner-area .banner-form ul {
  border-bottom: 0;
  margin-bottom: 15px;
}
.four-banner-area .banner-form ul li a {
  display: block;
  color: #12ca59;
  font-size: 16px;
  background-color: #e1f4e3;
  border-radius: 4px;
  padding: 10px 15px;
}
.four-banner-area .banner-form ul li .nav-default.active {
  position: relative;
  z-index: 1;
  color: #ffffff;
}
.four-banner-area .banner-form ul li .nav-default.active:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  border-radius: 4px;
  z-index: -1;
}
.four-banner-area .banner-form form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.four-banner-area .banner-form form .form-group label {
  margin-bottom: 0;
  font-size: 20px;
  position: absolute;
  top: 11px;
  left: 12px;
  color: #7f7f7f;
}
.four-banner-area .banner-form form .form-group .form-control {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #f1f1f1;
  font-weight: 500;
  font-size: 15px;
  padding-left: 40px;
  width: 100%;
  color: #7f7f7f;
  background-color: #fefefe;
}
.four-banner-area .banner-form form .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.four-banner-area .banner-form form .form-group ::placeholder {
  color: #7f7f7f;
}
.four-banner-area .banner-form form .form-group .dropdown {
  position: relative;
}
.four-banner-area .banner-form form .form-group .dropdown a {
  display: block;
  height: 50px;
  border-radius: 4px;
  color: #7f7f7f;
  background-color: #fefefe;
  font-weight: 500;
  font-size: 15px;
  border: 1px solid #f1f1f1;
  text-align: left;
  padding-left: 40px;
  line-height: 35px;
}
.four-banner-area .banner-form form .form-group .dropdown a::after {
  border-top: 7px solid;
  position: absolute;
  top: 22px;
  right: 25px;
}
.four-banner-area .banner-form form .form-group .dropdown a:focus {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.four-banner-area .banner-form form .form-group .dropdown .dropdown-menu.show {
  display: block;
  width: 100%;
}
.four-banner-area .banner-form form .cmn-banner-btn {
  border: 0;
  font-size: 18px;
  border-radius: 50px;
  padding: 14px 26px 14px 30px;
  overflow: hidden;
  color: #ffffff;
}
.four-banner-area .banner-form form .cmn-banner-btn i {
  top: 3px;
}
.four-banner-area .banner-form form .cmn-banner-btn:before {
  border-radius: 50px;
}

/*-- End Banner --*/
/*-- Time --*/
.four-banner-wrap .container-fluid .banner-time-left {
  padding: 50px 55px 50px;
}
.four-banner-wrap .container-fluid .banner-time-right {
  padding: 50px 35px 50px 55px;
}

/*-- End Time --*/
/*-- About --*/
.four-about-area {
  padding-top: 100px;
}
.four-about-area .three-section-title {
  text-align: left;
  margin-bottom: 30px;
}
.four-about-area .about-content {
  margin-bottom: 30px;
  max-width: 620px;
  margin-left: auto;
  padding-right: 30px;
}
.four-about-area .about-content .about-mission .nav-pills li {
  margin-right: 0;
}
.four-about-area .about-content .about-mission .nav-pills li a {
  color: #12ca59;
  background-color: #e7faf4;
  border-radius: 4px;
}
.four-about-area .about-content .about-mission .nav-pills li .nav-default:before {
  border-radius: 4px;
}
.four-about-area .about-content .about-mission .tab-content p {
  margin-bottom: 25px;
}
.four-about-area .about-content .about-mission .tab-content ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  margin-bottom: 20px;
}
.four-about-area .about-content .about-mission .tab-content ul li {
  display: inline-block;
  flex: 0 0 50%;
  max-width: 50%;
  margin-bottom: 0;
}
.four-about-area .about-content .about-mission .tab-content ul li .about-support i {
  display: inline-block;
  color: #12ca59;
  font-size: 40px;
  margin-bottom: 10px;
  top: 0;
}
.four-about-area .about-content .about-mission .tab-content ul li .about-support h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 8px;
}
.four-about-area .about-content .about-mission .tab-content ul li .about-support p {
  margin-bottom: 0;
  font-size: 15px;
}
.four-about-area .about-img .about-inner {
  background-color: #ffffff;
  right: unset;
  left: -45px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
}
.four-about-area .about-img .about-inner h3 {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.four-about-area .about-img .about-inner p {
  color: #959595;
}
.four-about-area .about-img img:nth-child(2) {
  position: absolute;
  left: -35px;
  top: unset;
  bottom: -30px;
  z-index: -1;
}

/*-- End About --*/
/*-- Expect --*/
.four-expect-area {
  background-color: #0d1820;
}
.four-expect-area .three-section-title h2 {
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: #ffffff;
}
.four-expect-area .expect-item {
  background-color: #ffffff;
  position: relative;
  border-radius: 4px;
  padding: 45px 35px 45px 115px;
  transition: 0.5s all ease;
  margin-bottom: 30px;
}
.four-expect-area .expect-item:hover, .four-expect-area .expect-item.active {
  background-color: #233644;
}
.four-expect-area .expect-item:hover h3, .four-expect-area .expect-item.active h3 {
  color: #ffffff;
}
.four-expect-area .expect-item:hover p, .four-expect-area .expect-item.active p {
  color: #bedaef;
}
.four-expect-area .expect-item:hover a, .four-expect-area .expect-item.active a {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.four-expect-area .expect-item:hover a i, .four-expect-area .expect-item.active a i {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.four-expect-area .expect-item .expect-icon i {
  display: inline-block;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 60px;
  margin-bottom: 30px;
  position: absolute;
  top: 45px;
  left: 35px;
}
.four-expect-area .expect-item h3 {
  font-weight: 700;
  font-size: 20px;
  color: #262424;
  margin-bottom: 10px;
  transition: 0.5s all ease;
}
.four-expect-area .expect-item p {
  margin-bottom: 10px;
  font-size: 15px;
  color: #6d807c;
  transition: 0.5s all ease;
}
.four-expect-area .expect-item a {
  display: inline-block;
  font-size: 16px;
  color: #6d807c;
}
.four-expect-area .expect-item a i {
  display: inline-block;
  position: relative;
  top: 4px;
  font-size: 18px;
  transition: 0.5s all ease;
}

/*-- End Expect --*/
/*-- Quote --*/
.four-quote-area {
  padding-top: 100px;
  padding-bottom: 100px;
}
.four-quote-area .row {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.four-quote-area .row:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
  left: 0;
  background: linear-gradient(90deg, rgb(16, 195, 23) 45%, rgba(16, 195, 23, 0) 75%);
}
.four-quote-area .quote-content {
  padding: 28px 35px 35px;
  max-width: 515px;
}
.four-quote-area .quote-content h3 {
  color: #ffffff;
}
.four-quote-area .quote-content p {
  color: #ffffff;
}
.four-quote-area .quote-content a {
  background-color: #ffffff;
  color: #0d1820;
}
.four-quote-area .quote-content a:hover {
  color: #ffffff;
  background-color: #0d1820;
}
.four-quote-area .quote-content a:before {
  display: none;
}

/*-- End Quote --*/
/*-- Record --*/
.four-record-area {
  position: relative;
}
.four-record-area .record-shape img {
  position: absolute;
}
.four-record-area .record-shape img:nth-child(1) {
  bottom: 0;
  right: 0;
}
.four-record-area .record-shape img:nth-child(2) {
  bottom: 100px;
  right: 0;
}
.four-record-area .three-section-title {
  text-align: left;
  margin-bottom: 35px;
}
.four-record-area .record-img {
  margin-bottom: 30px;
}
.four-record-area .record-content {
  margin-bottom: 30px;
  max-width: 535px;
  margin-left: 40px;
}
.four-record-area .record-content .record-item {
  position: relative;
  margin-bottom: 50px;
}
.four-record-area .record-content .record-item h3 {
  font-weight: 700;
  font-size: 75px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 65px;
}
.four-record-area .record-content .record-item span {
  display: block;
  font-size: 18px;
  color: #0d1820;
  padding-left: 110px;
}
.four-record-area .record-content .cmn-banner-btn {
  border-radius: 50px;
  overflow: hidden;
  color: #ffffff;
}
.four-record-area .record-content .cmn-banner-btn:before {
  border-radius: 50px;
}

/*-- End Record --*/
/*-- Step --*/
.four-step-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.four-step-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.5;
}
.four-step-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.four-step-area .three-section-title {
  position: relative;
}
.four-step-area .three-section-title h2 {
  color: #ffffff;
}
.four-step-area .three-section-title p {
  color: #ffffff;
}
.four-step-area .step-item {
  background-color: #ffffff;
  position: relative;
  padding: 55px 15px 25px 25px;
  border-radius: 4px;
  margin-top: 40px;
}
.four-step-area .step-item:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: -57px;
  top: 50%;
  border: 25px solid transparent;
  border-right: 35px solid #ffffff;
  transform: translateY(-50%);
  transition: 0.5s all ease;
}
.four-step-area .step-item .step-span {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  box-shadow: 0px 6px 20px 0px rgba(221, 221, 221, 0.5882352941);
  text-align: center;
  margin-top: -90px;
  background-color: #ffffff;
  margin-bottom: 20px;
}
.four-step-area .step-item .step-span span {
  display: inline-block;
  line-height: 70px;
  width: 70px;
  height: 70px;
  display: inline-block;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 600;
  font-size: 26px;
}
.four-step-area .step-item h3 {
  color: #1a1b1e;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 8px;
}
.four-step-area .step-item p {
  color: #848484;
  margin-bottom: 0;
}
.four-step-area .step-one {
  margin-top: 80px;
}

/*-- End Step --*/
/*-- Team --*/
.four-team-area .team-item {
  margin-bottom: 30px;
  text-align: center;
  background-color: #f4f4f4;
  padding: 25px 25px 25px;
  border-radius: 4px;
  transition: 0.5s all ease;
}
.four-team-area .team-item:hover {
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
  transform: translate(-10px, 0);
}
.four-team-area .team-item img {
  margin-bottom: 22px;
}
.four-team-area .team-item h3 {
  font-weight: 600;
  font-size: 20px;
  color: #0d1820;
  margin-bottom: 5px;
  position: relative;
}
.four-team-area .team-item h3:before {
  position: absolute;
  content: "";
  width: 3px;
  height: 40px;
  left: -25px;
  top: 8px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
}
.four-team-area .team-item p {
  margin-bottom: 20px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 15px;
}
.four-team-area .team-item ul {
  margin: 0;
  padding: 0;
}
.four-team-area .team-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}
.four-team-area .team-item ul li:nth-child(2) a {
  background-color: #3f70ff;
}
.four-team-area .team-item ul li:nth-child(3) a {
  background-color: #e52f29;
}
.four-team-area .team-item ul li:nth-child(4) a {
  background-color: #b92722;
}
.four-team-area .team-item ul li:nth-child(5) a {
  background-color: #11c535;
}
.four-team-area .team-item ul li a {
  color: #ffffff;
  background-color: #0331b8;
  display: block;
  border-radius: 50%;
  font-size: 17px;
}
.four-team-area .team-item ul li a:hover {
  transform: rotateY(360deg);
}
.four-team-area .team-item ul li a i {
  width: 35px;
  height: 35px;
  line-height: 35px;
}
.four-team-area .team-join {
  text-align: center;
}
.four-team-area .team-join p {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 18px;
  color: #6f6f78;
}
.four-team-area .team-join p a {
  display: inline-block;
  color: #0d1820;
  font-size: 25px;
  border-bottom: 1px solid #0d1820;
}
.four-team-area .team-join p a:hover {
  color: #12ca59;
  border-bottom: 1px solid #12ca59;
}

/*-- End Team --*/
/*-- Loan --*/
.four-loan-area {
  padding-bottom: 200px;
}
.four-loan-area .three-section-title h2 {
  color: #ffffff;
}

/*-- End Loan --*/
/*-- Join --*/
.four-join-area {
  text-align: center;
  margin-top: -130px;
  padding-bottom: 100px;
}
.four-join-area .join-item h3 {
  margin-bottom: 30px;
  max-width: 500px;
  margin-left: auto;
  margin-right: auto;
}
.four-join-area .join-item a {
  margin-left: 8px;
  margin-right: 8px;
}

/*-- End Join --*/
/*-- FAQ --*/
.four-faq-area {
  background-image: unset;
}
.four-faq-area .faq-img-two {
  margin-bottom: 30px;
}
.four-faq-area .faq-content {
  margin-bottom: 30px;
  max-width: 540px;
  margin-left: auto;
}
.four-faq-area .faq-content .accordion li {
  background-color: #d7f2d9;
  border: 0;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.3803921569);
}
.four-faq-area .faq-content .accordion li a {
  padding: 20px 20px 20px;
  font-size: 19px;
  color: #12ca59;
}
.four-faq-area .faq-content .accordion li a:after {
  right: 10px;
}
.four-faq-area .faq-content .accordion li a.active {
  color: #0d1820;
}
.four-faq-area .faq-content .accordion li p {
  padding: 0px 85px 25px 20px;
}

/*-- End FAQ --*/
/*-- Review --*/
.four-review-area .three-review-slider .review-item i {
  margin-bottom: 0;
  position: absolute;
  left: 0;
  right: 0;
  font-size: 135px;
  top: -22px;
  opacity: 0.2;
}
.four-review-area .three-review-slider .review-item h2 {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  max-width: 935px;
  margin-top: 5px;
  line-height: 35px;
}

/*-- End Review --*/
/*-- Blog --*/
.four-blog-area {
  background-color: #dbf6df;
}

/*-- End Blog --*/
/*-- Subscribe --*/
.four-subscribe-area {
  padding-top: 50px;
  padding-bottom: 50px;
}
.four-subscribe-area .subscribe-wrap .newsletter-form .form-control {
  border-radius: 50px;
}
.four-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn {
  border-radius: 50px;
  overflow: hidden;
}
.four-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn:before {
  border-radius: 50px;
}

/*-- End Subscribe --*/
/*-- Map --*/
.four-map-area #map {
  display: block;
  border: 0;
  width: 100%;
  height: 400px;
}

/*-- End Map --*/
/*-- Footer --*/
.four-footer-area .footer-item .footer-address .form-group {
  margin-bottom: 15px;
}
.four-footer-area .footer-item .footer-address .form-group .form-control {
  border-radius: 4px;
  height: 40px;
  background-color: #142028;
  border: 1px solid #273946;
  font-size: 14px;
  padding-left: 20px;
  color: #ffffff;
}
.four-footer-area .footer-item .footer-address .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #273946;
}
.four-footer-area .footer-item .footer-address .form-group ::placeholder {
  color: #688193;
}
.four-footer-area .footer-item .footer-address .form-group textarea {
  height: auto !important;
}
.four-footer-area .footer-item .footer-address .footer-form-btn {
  background-color: #ffffff;
  color: #0d1820;
  font-weight: 500;
  font-size: 15px;
  border-radius: 50px;
  padding: 14px 28px;
  border: 0;
  transition: 0.5s all ease;
}
.four-footer-area .footer-item .footer-address .footer-form-btn:hover {
  color: #ffffff;
  background-color: #12ca59;
}

/*-- End Footer --*/
/*----- End Home Four CSS -----*/
/*----- Home Five CSS -----*/
/*-- Banner --*/
.five-banner-area {
  height: 1000px;
  position: relative;
}
.five-banner-area .banner-shape img {
  position: absolute;
  z-index: -1;
}
.five-banner-area .banner-shape img:nth-child(1) {
  top: 0;
  left: 0;
}
.five-banner-area .banner-shape img:nth-child(2) {
  top: 225px;
  right: 55px;
  max-width: 745px;
  animation: five-banner 5s infinite linear;
}
.five-banner-area .container-fluid {
  padding-left: 55px;
  padding-right: 55px;
}
.five-banner-area .banner-content {
  max-width: 750px;
}
.five-banner-area .banner-content span {
  display: block;
  font-weight: 500;
  font-size: 25px;
  color: #0d1820;
  margin-bottom: 15px;
}
.five-banner-area .banner-content span img {
  max-width: 50px;
  display: inline-block;
  margin-right: 5px;
}
.five-banner-area .banner-content h1 {
  font-weight: 600;
  font-size: 100px;
  color: #0d1820;
  margin-bottom: 10px;
}
.five-banner-area .banner-content h1 span {
  font-weight: 300;
  font-size: 100px;
  display: inline-block;
  margin-bottom: 0;
}
.five-banner-area .banner-content p {
  font-weight: 500;
  font-size: 30px;
  color: #1a1b1e;
  margin-bottom: 40px;
}
.five-banner-area .banner-content .banner-form ul {
  border-radius: 4px 4px 0 0;
  display: inline-block;
  box-shadow: 0px -7px 20px 0px rgba(221, 221, 221, 0.5882352941);
}
.five-banner-area .banner-content .banner-form ul li {
  display: inline-block;
}
.five-banner-area .banner-content .banner-form ul li .nav-default {
  display: block;
  font-size: 16px;
  background-color: #ffffff;
  padding: 14px 16px;
  color: #12ca59;
  position: relative;
  z-index: 1;
}
.five-banner-area .banner-content .banner-form ul li .nav-default:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  opacity: 0;
  z-index: -1;
}
.five-banner-area .banner-content .banner-form ul li .nav-default.active {
  color: #ffffff;
}
.five-banner-area .banner-content .banner-form ul li .nav-default.active:before {
  opacity: 1;
}
.five-banner-area .banner-content .banner-form .tab-content form {
  padding: 30px 30px 30px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
  background-color: #ffffff;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group {
  margin-bottom: 15px;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group .form-control {
  height: 45px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 0;
  font-weight: 500;
  font-size: 15px;
  width: 100%;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group .form-control:focus {
  border: 1px solid #eeeeee;
  box-shadow: none;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group .form-control span {
  font-weight: 500;
  font-size: 15px;
  color: #9d9d9d;
  margin-bottom: 0;
  line-height: 30px;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group .form-control .list {
  display: block;
  width: 100%;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group .form-control .list li {
  display: block;
}
.five-banner-area .banner-content .banner-form .tab-content form .form-group ::placeholder {
  color: #9d9d9d;
}
.five-banner-area .banner-content .banner-form .tab-content form .banner-form-btn {
  width: 100%;
  height: 45px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  transition: 0.5s all ease;
  position: relative;
  z-index: 1;
  background-color: #0d1820;
  border: 0;
}
.five-banner-area .banner-content .banner-form .tab-content form .banner-form-btn:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  z-index: -1;
  transition: 0.5s all ease;
}
.five-banner-area .banner-content .banner-form .tab-content form .banner-form-btn:hover:before {
  width: 0;
}
.five-banner-area .banner-content .banner-form .tab-content form .banner-call {
  width: 100%;
  height: 45px;
  color: #ffffff;
  font-size: 15px;
  font-weight: 500;
  background-color: #0d1820;
  display: block;
  text-align: center;
  line-height: 45px;
}
.five-banner-area .banner-content .banner-form .tab-content form .banner-call:hover {
  background-color: #12ca59;
}
@keyframes five-banner {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, -25px);
  }
  100% {
    transform: translate(0, 0);
  }
}

/*-- End Banner --*/
/*-- Loan --*/
.five-loan-area .three-section-title h2 {
  color: #ffffff;
}
.five-loan-area .three-section-title p {
  color: #c2d1db;
}
.five-loan-area .loan-item i {
  font-size: 50px !important;
  margin-bottom: 15px;
  display: inline-block;
  font-size: 35px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.five-loan-area .loan-item a i {
  font-size: 17px !important;
  -webkit-text-fill-color: unset;
}
.five-loan-area .owl-theme .owl-dots .owl-dot span {
  width: 11px;
  height: 11px;
  margin: 5px 7px;
  background: transparent;
  border: 1px solid #9fb0d9;
}
.five-loan-area .owl-theme .owl-dots .owl-dot.active span {
  background: transparent;
  width: 14px;
  height: 14px;
  border: 1px solid #ffffff;
  position: relative;
  margin: 3px;
}
.five-loan-area .owl-theme .owl-dots .owl-dot.active span:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 2px;
  top: 3px;
  right: 0;
  background-color: #ffffff;
}

/*-- End Loan --*/
/*-- About --*/
.five-about-area {
  padding-top: 100px;
}
.five-about-area .three-section-title {
  text-align: left;
  margin-bottom: 30px;
}
.five-about-area .about-img img:nth-child(1) {
  animation: five-about-ani 10s infinite linear;
}
.five-about-area .about-img img:nth-child(2) {
  position: absolute;
  left: -40px;
  top: unset;
  z-index: -1;
  bottom: -5px;
}
@keyframes five-about-ani {
  0% {
    transform: rotateY(0deg);
  }
  50% {
    transform: rotateY(20deg);
  }
  100% {
    transform: rotateY(0deg);
  }
}

/*-- End About --*/
/*-- Quote --*/
.five-quote-area {
  padding-left: 70px;
  padding-right: 70px;
}
.five-quote-area .three-section-title {
  text-align: left;
  margin-bottom: 0;
}
.five-quote-area .three-section-title .sub-title {
  font-size: 15px;
}
.five-quote-area .three-section-title h2 {
  font-size: 27px;
  color: #ffffff;
}
.five-quote-area .quote-wrap {
  background-color: #0d1820;
  padding-top: 55px;
  padding-bottom: 55px;
  padding-left: 10px;
  padding-right: 10px;
  border-radius: 4px 0 0 4px;
}
.five-quote-area .quote-wrap .quote-btn {
  text-align: right;
}
.five-quote-area .quote-wrap .quote-btn a {
  display: inline-block;
  font-size: 16px;
  padding: 12px 25px 14px 25px;
  border-radius: 50px;
  overflow: hidden;
  text-align: center;
}
.five-quote-area .quote-wrap .quote-btn a i {
  display: inline-block;
  font-size: 21px;
  position: relative;
  top: 4px;
  margin-right: 4px;
}
.five-quote-area .quote-wrap .quote-btn .quote-btn-left {
  position: relative;
  z-index: 1;
  color: #ffffff;
  background-color: #ffffff;
  margin-right: 10px;
}
.five-quote-area .quote-wrap .quote-btn .quote-btn-left:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  border-radius: 50px;
  z-index: -1;
  transition: 0.5s all ease;
}
.five-quote-area .quote-wrap .quote-btn .quote-btn-left:hover {
  color: #0d1820;
}
.five-quote-area .quote-wrap .quote-btn .quote-btn-left:hover:before {
  width: 0;
}
.five-quote-area .quote-wrap .quote-btn .quote-btn-right {
  color: #0d1820;
  background-color: #ffffff;
}
.five-quote-area .quote-wrap .quote-btn .quote-btn-right:hover {
  color: #ffffff;
  background-color: #12ca59;
}
.five-quote-area .quote-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
  background-color: #ffffff;
  position: relative;
}
.five-quote-area .quote-img img {
  position: absolute;
  bottom: 0;
  right: 18px;
}

/*-- End Quote --*/
/*-- Service --*/
.five-service-area {
  background-color: #f3fcf4;
}
.five-service-area .three-section-title h2 {
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
}
.five-service-area .service-item {
  margin-bottom: 30px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
  padding: 50px 50px 50px;
  transition: 0.5s all ease;
}
.five-service-area .service-item:hover {
  transform: translate(0, -10px);
}
.five-service-area .service-item .service-img {
  text-align: center;
}
.five-service-area .service-item .service-img img {
  width: 250px;
  height: 260px;
  margin-bottom: 30px;
}
.five-service-area .service-item h3 {
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 20px;
}
.five-service-area .service-item p {
  margin-bottom: 17px;
}
.five-service-area .service-item ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.five-service-area .service-item ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 14px;
  font-size: 16px;
  color: #1a1b1e;
}
.five-service-area .service-item ul li:last-child {
  margin-bottom: 0;
}
.five-service-area .service-item ul li i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  color: #12ca59;
  background-color: #dcf7e4;
  text-align: center;
  border-radius: 50%;
  font-size: 18px;
  margin-right: 10px;
}
.five-service-area .service-item .cmn-banner-btn {
  border-radius: 50px;
  padding: 12px 23px 14px 29px;
  margin-right: 10px;
}
.five-service-area .service-item .cmn-banner-btn:before {
  border-radius: 50px;
}
.five-service-area .service-item .cmn-banner-btn i {
  top: 4px;
  margin-right: 0;
}
.five-service-area .service-item .service-btn {
  border-radius: 50px;
  padding: 12px 23px 14px 29px;
  display: inline-block;
  background-color: #e7faf1;
  color: #12ca59;
}
.five-service-area .service-item .service-btn:hover {
  color: #ffffff;
  background-color: #0d1820;
}
.five-service-area .service-item .service-btn i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 4px;
}

/*-- End Service --*/
/*-- Step --*/
.five-step-area {
  background-image: unset;
}
.five-step-area:before {
  display: none;
}
.five-step-area .three-section-title h2 {
  color: #0d1820;
}
.five-step-area .three-section-title p {
  color: #777777;
}
.five-step-area .step-item {
  box-shadow: 0px 0px 45px 0px rgba(221, 221, 221, 0.5882352941);
  margin-bottom: 30px;
}

/*-- End Step --*/
/*-- Team --*/
.five-team-area {
  background-color: #f2fcf3;
  position: relative;
}
.five-team-area .team-shape {
  position: absolute;
  top: 0;
  right: 0;
}

/*-- End Team --*/
/*-- Counter --*/
.five-counter-area .row {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}
.five-counter-area .counter-item {
  margin-bottom: 30px;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
  text-align: center;
  position: relative;
  padding: 35px 25px 35px;
  max-width: 180px;
  margin-left: auto;
  margin-right: auto;
  transition: 0.5s all ease;
}
.five-counter-area .counter-item:hover {
  margin-top: 15px;
}
.five-counter-area .counter-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 3px;
  top: 0;
  left: 0;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
}
.five-counter-area .counter-item h3 {
  font-weight: 700;
  font-size: 75px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  line-height: 70px;
  margin-bottom: 10px;
}
.five-counter-area .counter-item span {
  display: block;
  font-size: 17px;
  color: #1b2b37;
}
.five-counter-area .counter-two {
  margin-top: 15px;
}
.five-counter-area .counter-two:hover {
  margin-top: 0;
}

/*-- End Counter --*/
/*-- FAQ --*/
.five-faq-area .faq-content .accordion li {
  background-color: #f5f5f5;
}
.five-faq-area .faq-content .accordion li a {
  color: #1a1b1e;
}

/*-- End FAQ --*/
/*-- Loan --*/
.five-loan-area {
  padding-bottom: 70px;
}

/*-- End Loan --*/
/*-- Lover --*/
.five-lover-area .three-section-title h2 {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.five-lover-area .lover-item {
  transition: 0.5s all ease;
  margin-bottom: 70px;
}
.five-lover-area .lover-item:hover {
  background-color: #f7f7f7;
}
.five-lover-area .lover-item ul li:last-child {
  flex: 0 0 100%;
  max-width: 100%;
}
.five-lover-area .lover-item ul li .lover-content {
  position: relative;
}
.five-lover-area .lover-item ul li .lover-content .lover-inner-img {
  position: absolute;
  bottom: -80px;
  right: 0;
}
.five-lover-area .lover-item ul li .lover-content .lover-inner-img img {
  border-radius: 50%;
  border: 5px solid #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6392156863);
}
.five-lover-area .lover-item ul li .lover-content .lover-icon {
  position: absolute;
  top: -10px;
  right: 0;
}
.five-lover-area .lover-item ul li .lover-content .lover-icon i {
  display: inline-block;
  color: #b2ebd4;
  font-size: 45px;
}

/*-- End Lover --*/
/*-- Blog --*/
.five-blog-area {
  background-color: #dcf8ef;
}
.five-blog-area .blog-item .blog-bottom ul li i {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/*-- End Blog --*/
/*-- Subscribe --*/
.five-subscribe-area {
  background-color: #15d1a5;
}
.five-subscribe-area .subscribe-wrap {
  padding-top: 50px;
  padding-bottom: 60px;
  padding-left: 0;
  padding-right: 0;
  background-image: unset;
  text-align: left;
}
.five-subscribe-area .subscribe-wrap:before {
  display: none;
}
.five-subscribe-area .subscribe-wrap h2 {
  margin-left: 0;
}
.five-subscribe-area .subscribe-wrap .newsletter-form {
  margin-left: 0;
}
.five-subscribe-area .subscribe-wrap .newsletter-form .form-control {
  border-radius: 50px;
}
.five-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn {
  border-radius: 50px;
  overflow: hidden;
}
.five-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn:before {
  border-radius: 50px;
  width: 0;
}
.five-subscribe-area .subscribe-wrap .newsletter-form .subscribe-btn:hover:before {
  width: 100%;
}
.five-subscribe-area .subscribe-wrap .sub-img {
  position: absolute;
  bottom: 0;
  right: 0;
}

/*-- End Subscribe --*/
/*-- Footer --*/
.five-footer-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
}
.five-footer-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0.96;
}
.five-footer-area .footer-item {
  position: relative;
}
.five-footer-area .footer-item .footer-logo p {
  color: #777777;
}
.five-footer-area .footer-item .footer-logo ul li a {
  color: #12ca59;
  border: 1px solid #12ca59;
}
.five-footer-area .footer-item .footer-logo ul li a:hover {
  color: #ffffff;
  background-color: #12ca59;
}
.five-footer-area .footer-item .footer-quick h3 {
  color: #0d1820;
}
.five-footer-area .footer-item .footer-quick ul li a {
  color: #777777;
}
.five-footer-area .footer-item .footer-address h3 {
  color: #0d1820;
}
.five-footer-area .footer-item .footer-address .form-group .form-control {
  background-color: #ffffff;
  border: 1px solid #ababab;
  color: #0d1820;
}
.five-footer-area .footer-item .footer-address .footer-form-btn {
  color: #ffffff;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
}
.five-footer-area .footer-item .footer-address .footer-form-btn:hover {
  background: #0d1820;
}
.five-footer-area .copyright-area {
  border-top: 1px solid #e6e6e6;
  position: relative;
}
.five-footer-area .copyright-area .copyright-item p a {
  color: #12ca59;
  border-bottom: 1px solid #12ca59;
}

/*-- End Footer --*/
/*----- End Home Five CSS -----*/
/*----- Home Six CSS -----*/
/*-- Banner --*/
.six-banner-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  height: 850px;
}
.six-banner-area .banner-img {
  position: absolute;
  top: 45px;
  left: -50px;
  max-width: 960px;
}
.six-banner-area .banner-content {
  position: relative;
  z-index: 1;
  max-width: 500px;
}
.six-banner-area .banner-content h1 {
  font-size: 100px;
  font-weight: 700;
}
.six-banner-area .banner-content h1 span {
  font-weight: 500;
  margin-bottom: 0;
  font-size: 100px;
}

/*-- End Banner --*/
/*-- Quote --*/
.six-quote-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: -1;
  padding-top: 50px;
  padding-bottom: 55px;
}
.six-quote-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
  background-color: #0d1820;
  opacity: 0.9;
}
.six-quote-area .quote-wrap {
  background-color: unset;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  border-radius: 0;
}

/*-- End Quote --*/
/*-- Loan --*/
.six-loan-area {
  background-color: #ffffff;
}
.six-loan-area .loan-item {
  background-color: #dcf7ea;
}
.six-loan-area .loan-item i {
  transition: 0.5s all ease;
}
.six-loan-area .loan-item h3 {
  color: #08104d;
}
.six-loan-area .loan-item p {
  color: #667c71;
}
.six-loan-area .loan-item a {
  color: #13cc70;
}
.six-loan-area .loan-item:hover {
  background-color: #13cc70;
}
.six-loan-area .loan-item:hover i {
  color: #ffffff;
  -webkit-text-fill-color: unset;
}
.six-loan-area .loan-item:hover h3 {
  color: #ffffff;
}
.six-loan-area .loan-item:hover p {
  color: #d5ffea;
}
.six-loan-area .loan-item:hover a {
  color: #eef8ff;
  -webkit-text-fill-color: unset;
}
.six-loan-area .loan-item:hover a i {
  color: #eef8ff;
  -webkit-text-fill-color: unset;
}
.six-loan-area .owl-theme .owl-dots .owl-dot span {
  border: 1px solid #13cc70;
}
.six-loan-area .owl-theme .owl-dots .owl-dot.active span {
  border: 1px solid #13cc70;
}
.six-loan-area .owl-theme .owl-dots .owl-dot.active span:before {
  background-color: #13cc70;
}

/*-- End Loan --*/
/*-- About --*/
.six-about-area {
  padding-top: 20px;
}
.six-about-area .about-shape {
  position: absolute;
  top: 0;
  left: 0;
}
.six-about-area .about-img img:nth-child(1) {
  position: relative;
}
.six-about-area .about-img img:nth-child(2) {
  left: unset;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*-- End About --*/
/*-- Contact --*/
.six-contact-area .contact-content {
  position: relative;
  top: 100px;
  background-color: #fefefe;
}
.six-contact-area .contact-content .three-section-title {
  margin-left: 0;
}
.six-contact-area .contact-content .three-section-title h2 {
  color: #0d1820;
  font-size: 35px;
}
.six-contact-area .contact-content .form-group .form-control {
  border: 1px solid #d8d8d8;
}

/*-- End Contact --*/
/*-- Counter --*/
.six-counter-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #f9f9f9;
  padding-top: 200px;
}
.six-counter-area .counter-item {
  position: relative;
  margin-bottom: 30px;
}
.six-counter-area .counter-item ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
}
.six-counter-area .counter-item ul li {
  list-style-type: none;
  display: inline-block;
  flex: 0 0 40%;
  max-width: 40%;
}
.six-counter-area .counter-item ul li:last-child {
  flex: 0 0 55%;
  max-width: 55%;
  margin-left: 10px;
}
.six-counter-area .counter-item .progress {
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: none;
  box-shadow: none;
  position: relative;
  display: inline-block;
}
.six-counter-area .counter-item .progress:after {
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 7px solid #dccec0;
  position: absolute;
  top: 0;
  left: 0;
}
.six-counter-area .counter-item .progress > span {
  width: 50%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  top: 0;
  z-index: 1;
}
.six-counter-area .counter-item .progress .progress-left {
  left: 0;
}
.six-counter-area .counter-item .progress .progress-bar {
  width: 100%;
  height: 100%;
  background: none;
  border-width: 7px;
  border-style: solid;
  position: absolute;
  top: 0;
}
.six-counter-area .counter-item .progress .progress-left .progress-bar {
  left: 100%;
  border-top-right-radius: 80px;
  border-bottom-right-radius: 80px;
  border-left: 0;
  transform-origin: center left;
}
.six-counter-area .counter-item .progress .progress-right {
  right: 0;
}
.six-counter-area .counter-item .progress .progress-right .progress-bar {
  left: -100%;
  border-top-left-radius: 80px;
  border-bottom-left-radius: 80px;
  border-right: 0;
  transform-origin: center right;
  animation: loading-1 1.8s linear forwards;
}
.six-counter-area .counter-item .progress .progress-value {
  width: 90%;
  height: 90%;
  border-radius: 50%;
  background: transparent;
  font-size: 24px;
  color: #fff;
  line-height: 135px;
  text-align: center;
  position: absolute;
  top: 5%;
  left: 5%;
}
.six-counter-area .counter-item .progress .progress-value i {
  display: inline-block;
  color: #262424;
  font-size: 48px;
  position: relative;
  top: -10px;
}
.six-counter-area .counter-item .progress.blue .progress-bar {
  border-color: #12ca59;
}
.six-counter-area .counter-item .progress.blue .progress-left .progress-bar {
  animation: loading-2 1.5s linear forwards 1.8s;
}
.six-counter-area .counter-item .progress.yellow .progress-bar {
  border-color: #12ca59;
}
.six-counter-area .counter-item .progress.yellow .progress-left .progress-bar {
  animation: loading-3 1s linear forwards 1.8s;
}
.six-counter-area .counter-item .progress.pink .progress-bar {
  border-color: #12ca59;
}
.six-counter-area .counter-item .progress.pink .progress-left .progress-bar {
  animation: loading-4 0.4s linear forwards 1.8s;
}
.six-counter-area .counter-item .progress.green .progress-bar {
  border-color: #12ca59;
}
.six-counter-area .counter-item .progress.green .progress-left .progress-bar {
  animation: loading-5 1.2s linear forwards 1.8s;
}
@keyframes loading-1 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(180deg);
  }
}
@keyframes loading-2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(144deg);
  }
}
@keyframes loading-3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(90deg);
  }
}
@keyframes loading-4 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(36deg);
  }
}
@keyframes loading-5 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(126deg);
  }
}
@media only screen and (max-width: 990px) {
  .six-counter-area .counter-item .progress {
    margin-bottom: 20px;
  }
}
.six-counter-area .counter-item .progress-content {
  display: inline-block;
}
.six-counter-area .counter-item .progress-content h3 {
  font-weight: 600;
  font-size: 35px;
  color: #262424;
  margin-bottom: 0px;
}
.six-counter-area .counter-item .progress-content p {
  margin-bottom: 0;
  color: #262424;
  font-size: 17px;
  text-transform: uppercase;
}

/*-- End Counter --*/
/*-- Step --*/
.six-step-area .three-section-title h2 {
  max-width: 470px;
  margin-left: auto;
  margin-right: auto;
}
.six-step-area .owl-theme .owl-dots .owl-dot span {
  width: 11px;
  height: 11px;
  margin: 5px 7px;
  background: transparent;
  border: 1px solid #13cc70;
}
.six-step-area .owl-theme .owl-dots .owl-dot.active span {
  background: transparent;
  width: 14px;
  height: 14px;
  border: 1px solid #13cc70;
  position: relative;
  margin: 3px;
}
.six-step-area .owl-theme .owl-dots .owl-dot.active span:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 2px;
  top: 3px;
  right: 0;
  background-color: #13cc70;
}

/*-- End Step --*/
/*-- Nearly --*/
.six-nearly-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
}
.six-nearly-area:before {
  position: absolute;
  content: "";
  width: 100%;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #ffffff;
  opacity: 0.9;
}
.six-nearly-area .three-section-title h2 {
  color: #0d1820;
  max-width: 610px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}
.six-nearly-area .nearly-content {
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5882352941);
}
.six-nearly-area .nearly-content .nav-tabs {
  border-bottom: 1px solid #f3f3f3;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 55px;
}
.six-nearly-area .nearly-content .nav-tabs .nav-default {
  font-weight: 500;
  font-size: 18px;
  color: #a6a6a6;
  font-family: "Poppins", sans-serif;
  padding-right: 15px;
  margin-right: 15px;
  border-right: 1px solid #a6a6a6;
  display: inline-block;
}
.six-nearly-area .nearly-content .nav-tabs .nav-default:last-child {
  border-right: 0;
}
.six-nearly-area .nearly-content .nav-tabs .nav-default.active {
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.six-nearly-area .nearly-content .tab-content {
  padding-left: 55px;
  padding-top: 25px;
  padding-right: 100px;
  padding-bottom: 40px;
}
.six-nearly-area .nearly-content .tab-content h3 {
  font-weight: 600;
  font-size: 20px;
  color: #1b2b37;
  margin-bottom: 10px;
}
.six-nearly-area .nearly-content .tab-content p {
  margin-bottom: 15px;
}
.six-nearly-area .nearly-content .tab-content ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.six-nearly-area .nearly-content .tab-content ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 15px;
  color: #1a1b1e;
}
.six-nearly-area .nearly-content .tab-content ul li:last-child {
  margin-bottom: 0;
}
.six-nearly-area .nearly-content .tab-content ul li i {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  text-align: center;
  color: #12ca59;
  background-color: #dcf7e2;
  margin-right: 5px;
  font-size: 18px;
}
.six-nearly-area .nearly-content .tab-content .nearly-btn .cmn-banner-btn {
  border-radius: 50px;
  padding: 12px 20px 14px 28px;
  overflow: hidden;
  margin-right: 10px;
}
.six-nearly-area .nearly-content .tab-content .nearly-btn .cmn-banner-btn:before {
  border-radius: 50px;
}
.six-nearly-area .nearly-content .tab-content .nearly-btn .nearly-btn {
  display: inline-block;
  color: #12ca59;
  background-color: #e7f9ec;
  border-radius: 50px;
  padding: 12px 20px 14px 28px;
  overflow: hidden;
}
.six-nearly-area .nearly-content .tab-content .nearly-btn .nearly-btn:hover {
  color: #ffffff;
  background-color: #12ca59;
}
.six-nearly-area .nearly-content .tab-content .nearly-btn .nearly-btn i {
  display: inline-block;
  font-size: 20px;
  position: relative;
  top: 5px;
  margin-right: 4px;
}
.six-nearly-area .nearly-img {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.six-nearly-area .nearly-img img {
  display: none;
}

/*-- End Nearly --*/
/*-- Team --*/
.six-team-area .team-item {
  padding: 0;
}
.six-team-area .team-item:hover .team-bottom h3:before {
  opacity: 1;
}
.six-team-area .team-item img {
  margin-bottom: 0;
  width: 100%;
}
.six-team-area .team-item .team-bottom {
  padding: 25px 25px 25px;
}
.six-team-area .team-item .team-bottom h3:before {
  width: 50px;
  height: 3px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: -25px;
  opacity: 0;
  transition: 0.5s all ease;
}

/*-- End Team --*/
/*-- Loan --*/
.seven-loan-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.seven-loan-area:before {
  position: absolute;
  content: "";
  width: 100%;
  z-index: -1;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000;
  opacity: 0.8;
}
.seven-loan-area .three-section-title h2 {
  color: #ffffff;
  position: relative;
}

/*-- End Loan --*/
/*-- FAQ --*/
.six-faq-area .faq-shape {
  position: absolute;
  top: 100px;
  right: 0;
}
.six-faq-area .faq-img {
  position: relative;
  margin-bottom: 30px;
}
.six-faq-area .faq-img img:nth-child(1) {
  position: relative;
}
.six-faq-area .faq-img img:nth-child(2) {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 1;
}
.six-faq-area .faq-img img:nth-child(3) {
  position: absolute;
  right: 0;
  bottom: 0;
}
.six-faq-area .faq-content {
  margin-left: 0;
}

/*-- End FAQ --*/
/*-- Lover --*/
.six-lover-area .three-section-title {
  margin-bottom: 35px;
}
.six-lover-area .lover-item {
  margin-bottom: 30px;
  margin-top: 15px;
}
.six-lover-area .owl-theme .owl-dots .owl-dot span {
  width: 11px;
  height: 11px;
  margin: 5px 7px;
  background: transparent;
  border: 1px solid #13cc70;
}
.six-lover-area .owl-theme .owl-dots .owl-dot.active span {
  background: transparent;
  width: 14px;
  height: 14px;
  border: 1px solid #13cc70;
  position: relative;
  margin: 3px;
}
.six-lover-area .owl-theme .owl-dots .owl-dot.active span:before {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  border-radius: 50%;
  left: 2px;
  top: 3px;
  right: 0;
  background-color: #13cc70;
}

/*-- End Lover --*/
/*-- Subscribe --*/
.six-subscribe-area {
  background-color: #ffffff;
  margin-top: 25px;
}
.six-subscribe-area .subscribe-wrap {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding-left: 50px;
  position: relative;
}
.six-subscribe-area .subscribe-wrap:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: #12bc67;
  opacity: 0.6;
  display: block;
}
.six-subscribe-area .subscribe-wrap h2 {
  max-width: 465px;
}
.six-subscribe-area .subscribe-wrap .newsletter-form {
  max-width: 490px;
}
.six-subscribe-area .subscribe-wrap .sub-img {
  right: 50px;
}

/*-- End Subscribe --*/
/*-- Hour --*/
.six-hour-area {
  padding-top: 40px;
  padding-bottom: 10px;
  padding-left: 115px;
  padding-right: 50px;
}
.six-hour-area .hour-item {
  margin-bottom: 30px;
  position: relative;
  position: relative;
}
.six-hour-area .hour-item:before {
  position: absolute;
  content: "";
  width: 1px;
  height: 62px;
  right: 0;
  top: 0;
  background-color: #cdcdcd;
}
.six-hour-area .hour-item i {
  display: inline-block;
  color: #262424;
  font-size: 35px;
  position: absolute;
  top: 0;
  left: 0;
}
.six-hour-area .hour-item .hour-inner {
  padding-left: 50px;
}
.six-hour-area .hour-item .hour-inner h3 {
  font-weight: 600;
  font-size: 20px;
  margin-bottom: 5px;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.six-hour-area .hour-item .hour-inner span {
  display: block;
  font-weight: 500;
  color: #2e2e2e;
  font-size: 16px;
}
.six-hour-area .hour-item .hour-inner a {
  display: inline-block;
  font-weight: 500;
  color: #2e2e2e;
  font-size: 16px;
  border-bottom: 1px solid #2e2e2e;
}
.six-hour-area .hour-item .hour-inner a:hover {
  color: #12ca59;
  border-bottom: 1px solid #12ca59;
}
.six-hour-area .hour-right:before {
  display: none;
}

/*-- End Hour --*/
/*----- End Home Six CSS -----*/
/*----- Preloader CSS -----*/
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background: linear-gradient(90deg, rgb(135, 124, 70) 23%, rgb(161, 132, 95) 64%);
}

.spinner {
  width: 83px;
  height: 100px;
  background-image: url(./assets/logo/pramaan-logo.png);
  margin: 100px auto;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
/*----- End Preloader CSS -----*/
/*----- Back to Top CSS ------*/
#toTop {
  position: fixed;
  bottom: 80px;
  right: 0;
  cursor: pointer;
  display: none;
  z-index: 10;
}

.back-to-top-btn i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  font-size: 25px;
  border-radius: 50%;
  transition: 0.5s all ease;
  margin-right: 28px;
  box-shadow: 0px 0px 14px 0px linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.back-to-top-btn i:hover {
  background: #0d1820;
  color: #ffffff;
  box-shadow: 0px 0px 14px 0px rgba(51, 51, 51, 0.6509803922);
}

/*----- End Back to Top CSS -----*/
/*----- main-section-title CSS -----*/
.main-section-title {
  margin-bottom: 45px;
  text-align: center;
}
.main-section-title .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 12px;
}
.main-section-title h2 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 0;
}

/*----- main-default-btn CSS -----*/
.main-default-btn {
  display: inline-block;
  padding: 15px 30px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.main-default-btn:hover {
  background-color: #0d1820;
}

/*----- Main Top Header Area CSS -----*/
.main-top-header {
  background-color: #0d1820;
  padding-top: 15px;
  padding-bottom: 15px;
}
.main-top-header .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.top-header-information {
  padding: 0;
  margin-bottom: 0;
}
.top-header-information li {
  list-style-type: none;
  display: inline-block;
  margin-right: 20px;
  font-size: 14px;
}
.top-header-information li:last-child {
  margin-right: 0;
}
.top-header-information li span {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}
.top-header-information li a {
  color: #DDDDDD;
}
.top-header-information li a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

.top-header-social {
  padding: 0;
  margin-bottom: 0;
  text-align: end;
}
.top-header-social li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.top-header-social li:last-child {
  margin-right: 0;
}
.top-header-social li a i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: #ffffff;
  color: #0d1820;
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  transition: 0.5s all ease;
}
.top-header-social li a i:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

/*----- Main Navbar Area CSS -----*/
.main-navbar .navbar.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.main-navbar .navbar.navbar-light {
  padding-left: 0;
  padding-right: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #ffffff;
  box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
}
.main-navbar .navbar.navbar-light .navbar-nav {
  margin: auto;
}
.main-navbar .navbar.navbar-light .navbar-nav .nav-item {
  position: relative;
  padding: 0;
}
.main-navbar .navbar.navbar-light .navbar-nav .nav-item a {
  color: #0d1820;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  padding-bottom: 0;
  font-weight: 400;
  margin-left: 20px;
  margin-right: 20px;
}
.main-navbar .navbar.navbar-light .navbar-nav .nav-item a:hover, .main-navbar .navbar.navbar-light .navbar-nav .nav-item a:focus, .main-navbar .navbar.navbar-light .navbar-nav .nav-item a.active {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  position: relative;
  transition: 0.5s all ease;
}
.main-navbar .navbar.navbar-light .navbar-nav .nav-item:last-child a {
  margin-right: 0;
}
.main-navbar .navbar.navbar-light .navbar-nav .nav-item:first-child a {
  margin-left: 0;
}
.main-navbar .navbar.navbar-light .navbar-nav .nav-item:hover a, .main-navbar .navbar.navbar-light .navbar-nav .nav-item.active a {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar.navbar-light .others-option .option-item {
  margin-right: 25px;
}
.main-navbar .navbar.navbar-light .others-option .option-item:last-child {
  margin-right: 0;
}
.main-navbar .navbar.navbar-light .others-option .option-item .log-in {
  color: #0d1820;
  font-weight: 400;
  transition: 0.5s all ease;
}
.main-navbar .navbar.navbar-light .others-option .option-item .log-in:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar.navbar-light .others-option .option-item .sign-up {
  display: inline-flex;
  padding: 15px 25px;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  color: #ffffff;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 5px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.main-navbar .navbar.navbar-light .others-option .option-item .sign-up:before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  width: 550px;
  height: 550px;
  margin: auto;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 50%;
  z-index: -1;
  transform-origin: top center;
  transform: translateX(-50%) translateY(-5%) scale(0.4);
  transition: transform 0.9s;
}
.main-navbar .navbar.navbar-light .others-option .option-item .sign-up:hover {
  color: #ffffff;
}
.main-navbar .navbar.navbar-light .others-option .option-item .sign-up:hover:before {
  transition: transform 1s;
  transform: translateX(-45%) translateY(0) scale(1);
  transform-origin: bottom center;
}
.main-navbar .navbar.navbar-light .others-option .option-item .call-btn {
  color: #0d1820;
  font-weight: 400;
  position: relative;
  top: 1px;
}
.main-navbar .navbar.navbar-light .others-option .option-item .call-btn:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.main-navbar .navbar.navbar-style-two {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;
  background-color: transparent !important;
  box-shadow: unset;
}
.main-navbar .navbar.navbar-style-two.is-sticky {
  box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff !important;
}
.main-navbar .navbar.navbar-style-two.is-sticky.navbar-light .others-option .option-item .log-in {
  color: #0d1820;
}
.main-navbar .navbar.navbar-style-two.is-sticky.navbar-light .others-option .option-item .log-in:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar.navbar-style-two.is-sticky.navbar-light .others-option .option-item .sign-up {
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  color: #ffffff;
}
.main-navbar .navbar.navbar-style-two.is-sticky.navbar-light .others-option .option-item .sign-up::before {
  background-color: #0d1820;
}
.main-navbar .navbar.navbar-style-two.is-sticky.navbar-light .others-option .option-item .call-btn {
  color: #0d1820;
}
.main-navbar .navbar.navbar-style-two.is-sticky.navbar-light .others-option .option-item .call-btn:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar.navbar-style-two.navbar-light .logo {
  padding: 0 55px 0 0;
}
.main-navbar .navbar.navbar-style-two.navbar-light .navbar-nav {
  margin-right: auto;
  margin-left: 0;
}
.main-navbar .navbar.navbar-style-two.navbar-light .others-option .option-item .log-in {
  color: #ffffff;
  font-weight: 500;
}
.main-navbar .navbar.navbar-style-two.navbar-light .others-option .option-item .log-in:hover {
  color: #0d1820;
}
.main-navbar .navbar.navbar-style-two.navbar-light .others-option .option-item .sign-up {
  background: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar.navbar-style-two.navbar-light .others-option .option-item .sign-up::before {
  background-color: #0d1820;
}
.main-navbar .navbar.navbar-style-two.navbar-light .others-option .option-item .call-btn {
  color: #ffffff;
  font-weight: 500;
}
.main-navbar .navbar.navbar-style-two.navbar-light .others-option .option-item .call-btn:hover {
  color: #0d1820;
}
.main-navbar .navbar.navbar-style-black-color.navbar-light {
  background-color: #0d1820 !important;
}
.main-navbar .navbar.navbar-style-black-color.navbar-light .navbar-nav .nav-item a {
  color: #ffffff;
}
.main-navbar .navbar.navbar-style-black-color.navbar-light .navbar-nav .nav-item a:hover, .main-navbar .navbar.navbar-style-black-color.navbar-light .navbar-nav .nav-item a:focus, .main-navbar .navbar.navbar-style-black-color.navbar-light .navbar-nav .nav-item a.active {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-navbar .navbar.navbar-style-black-color.navbar-light .others-option .option-item .log-in {
  color: #ffffff;
}
.main-navbar .navbar.navbar-style-black-color.navbar-light .others-option .option-item .log-in:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*----- Main Header Area CSS -----*/
.main-header-area {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 9;
}
.main-header-area .main-top-header {
  background-color: transparent;
}
.main-header-area .main-navbar .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.main-header-area .main-navbar .navbar.is-sticky.navbar-light {
  border-radius: 0;
  padding-left: 30px;
  padding-right: 30px;
}
.main-header-area .main-navbar .navbar.navbar-light {
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 15px;
  padding-bottom: 15px;
  border-radius: 5px;
}

/*----- Main Banner Area CSS -----*/
.main-banner-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
}
.main-banner-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.main-banner-content .sub-title {
  display: inline-block;
  margin-bottom: 18px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.main-banner-content h1 {
  font-size: 85px;
  margin-bottom: 15px;
  font-weight: bold;
}
.main-banner-content .banner-popup {
  margin-top: 20px;
  position: relative;
  left: 5px;
}
.main-banner-content .banner-popup .video-btn i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  color: #ffffff;
  font-size: 30px;
  margin-right: 10px;
  transition: 0.5s all ease;
}
.main-banner-content .banner-popup .video-btn:hover i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.main-banner-content .banner-popup .video-btn span {
  color: #0d1820;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -5px;
}

.main-banner-form-wrap {
  background: rgba(0, 0, 0, 0.5);
  border: 2px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
  border-radius: 10px;
  padding: 50px 65px;
  max-width: 550px;
  margin-left: auto;
}
.main-banner-form-wrap h3 {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 25px;
}
.main-banner-form-wrap form .form-group {
  margin-bottom: 20px;
}
.main-banner-form-wrap form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #ffffff;
  font-size: 15px;
}
.main-banner-form-wrap form .form-group .nice-select {
  float: unset;
  height: 55px !important;
  line-height: 55px;
  color: #767676;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 35px;
}
.main-banner-form-wrap form .form-group .nice-select .list {
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  border-radius: 10px;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.main-banner-form-wrap form .form-group .nice-select .list .option {
  transition: 0.5s all ease;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  font-weight: 500;
}
.main-banner-form-wrap form .form-group .nice-select .list .option:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%) !important;
  color: #ffffff;
}
.main-banner-form-wrap form .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 400;
}
.main-banner-form-wrap form .form-group .nice-select:after {
  right: 20px;
  width: 8px;
  height: 8px;
  margin-top: -5.5px;
}
.main-banner-form-wrap form .calculat-button {
  text-align: center;
  margin-top: 30px;
}
.main-banner-form-wrap form .calculat-button .default-btn {
  display: inline-block;
  padding: 15px 30px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  width: 100%;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.main-banner-form-wrap form .calculat-button .default-btn:hover {
  background-color: #0d1820;
}

/*----- About Style Area CSS -----*/
.about-style-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.about-style-area.with-black-color {
  background-color: #0d1820;
}
.about-style-area.with-black-color .about-style-wrap-content h3 {
  color: #ffffff;
}
.about-style-area.with-black-color .about-style-wrap-content .bold {
  color: #ffffff;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .nav {
  border-bottom: 1px solid #F8FAFB;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .nav .nav-item .nav-link {
  color: #ffffff;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .tab-content .tab-pane .content p {
  color: #ffffff;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .list li {
  color: #ffffff;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .tab-btn {
  margin-top: 25px;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .tab-btn .main-default-btn {
  color: #ffffff;
}
.about-style-area.with-black-color .about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .tab-btn .main-default-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.about-style-area.with-black-color .information-card .icon i {
  background: rgba(0, 193, 149, 0.2);
}
.about-style-area.with-black-color .information-card p span {
  color: #ffffff;
}
.about-style-area.with-black-color .information-card p span a {
  color: #ffffff;
}
.about-style-area.with-black-color .information-card p span a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.about-style-area.with-black-color .information-card:hover .icon i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

.about-style-image {
  position: relative;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
  border-radius: 10px;
}
.about-style-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 312px;
  background-color: transparent;
  left: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 5px;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.about-style-image img {
  border-radius: 10px;
}
.about-style-image .quote-content {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  padding: 25px;
  position: absolute;
  left: 0;
  bottom: 30px;
  max-width: 418px;
  border-radius: 0px 20px 20px 0px;
  z-index: 1;
}
.about-style-image .quote-content p {
  color: #ffffff;
  font-size: 15px;
}
.about-style-image .quote-content span {
  font-weight: 600;
  color: #ffffff;
}
.about-style-image .quote-content::before {
  content: "\ee32";
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  color: #ffffff;
  z-index: -1;
  position: absolute;
  right: 25px;
  bottom: 25px;
  line-height: 1;
}

.about-style-content {
  padding-left: 30px;
}
.about-style-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.about-style-content h3 {
  font-size: 40px;
  margin-bottom: 18px;
  font-weight: bold;
}
.about-style-content .bold {
  color: #0d1820;
  font-weight: 500;
}
.about-style-content .counter-box {
  background: #e5f9f4;
  border-radius: 10px;
  text-align: center;
  padding: 30px 25px;
  margin-top: 15px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.about-style-content .counter-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 0%;
  transition: all 0.5s ease-in-out;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  z-index: -1;
  opacity: 0;
  border-radius: 50%;
  transform: scale(1.5);
}
.about-style-content .counter-box h4 {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-size: 65px;
  line-height: 1;
  font-weight: bold;
  transition: 0.5s all ease;
  margin-bottom: 15px;
}
.about-style-content .counter-box span {
  color: #0d1820;
  font-weight: 600;
  transition: 0.5s all ease;
}
.about-style-content .counter-box:hover h4 {
  color: #ffffff;
}
.about-style-content .counter-box:hover span {
  color: #ffffff;
}
.about-style-content .counter-box:hover::before {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
}

.about-style-image-wrap {
  position: relative;
  z-index: 1;
  text-align: center;
}
.about-style-image-wrap .certified-image {
  position: absolute;
  right: 75px;
  bottom: 0;
}

.about-style-wrap-content {
  padding-left: 30px;
}
.about-style-wrap-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.about-style-wrap-content h3 {
  font-size: 40px;
  margin-bottom: 18px;
  font-weight: bold;
}
.about-style-wrap-content .bold {
  color: #0d1820;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
}
.about-style-wrap-content .about-list-tab {
  margin-top: 25px;
}
.about-style-wrap-content .about-list-tab .nav {
  padding: 0;
  margin-bottom: 0;
  border: none;
  border-bottom: 1px solid #DDDDDD;
}
.about-style-wrap-content .about-list-tab .nav .nav-item {
  margin-right: 30px;
}
.about-style-wrap-content .about-list-tab .nav .nav-item:last-child {
  margin-right: 0;
}
.about-style-wrap-content .about-list-tab .nav .nav-item .nav-link {
  transition: 0.5s all ease;
  color: #0d1820;
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  padding-bottom: 20px;
}
.about-style-wrap-content .about-list-tab .nav .nav-item .nav-link::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 0%;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease;
}
.about-style-wrap-content .about-list-tab .nav .nav-item .nav-link:hover, .about-style-wrap-content .about-list-tab .nav .nav-item .nav-link.active {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.about-style-wrap-content .about-list-tab .nav .nav-item .nav-link:hover::before, .about-style-wrap-content .about-list-tab .nav .nav-item .nav-link.active::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.about-style-wrap-content .about-list-tab .tab-content .tab-pane .content {
  margin-top: 25px;
}
.about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .list {
  padding: 0;
  margin-bottom: 0;
}
.about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .list li {
  list-style-type: none;
  color: #0d1820;
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 18px;
  position: relative;
  padding-left: 22px;
}
.about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .list li:last-child {
  margin-bottom: 0;
}
.about-style-wrap-content .about-list-tab .tab-content .tab-pane .content .list li i {
  position: absolute;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-size: 18px;
  left: 0;
  top: -2px;
}

.about-style-shape-1 {
  position: absolute;
  right: 0;
  top: -50px;
  z-index: -1;
}

.about-style-area-with-full-width .container-fluid {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}

.about-style-full-content {
  max-width: 640px;
  margin-left: auto;
  padding-right: 25px;
}
.about-style-full-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.about-style-full-content h3 {
  font-size: 40px;
  margin-bottom: 18px;
  font-weight: bold;
}
.about-style-full-content .bold {
  color: #0d1820;
  font-weight: 500;
  font-size: 15px;
}
.about-style-full-content .inner-content {
  margin-top: 25px;
}
.about-style-full-content .inner-content h4 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 14px;
}
.about-style-full-content .inner-content p {
  margin-bottom: 0;
  font-size: 15px;
}
.about-style-full-content .about-btn {
  margin-top: 25px;
}
.about-style-full-content .about-btn .main-default-btn {
  color: #ffffff;
}

.about-full-image-wrap {
  position: relative;
}
.about-full-image-wrap .counter-box {
  background: rgba(13, 24, 32, 0.9);
  border-radius: 0;
  text-align: center;
  padding: 30px 25px;
  position: absolute;
  left: 20px;
  bottom: 20px;
  z-index: 1;
  overflow: hidden;
}
.about-full-image-wrap .counter-box::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 0%;
  height: 0%;
  transition: all 0.5s ease-in-out;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  z-index: -1;
  opacity: 0;
  border-radius: 50%;
  transform: scale(1.5);
}
.about-full-image-wrap .counter-box h4 {
  color: #ffffff;
  font-size: 55px;
  line-height: 1;
  font-weight: bold;
  transition: 0.5s all ease;
  margin-bottom: 15px;
}
.about-full-image-wrap .counter-box span {
  color: #ffffff;
  font-weight: 600;
  transition: 0.5s all ease;
}
.about-full-image-wrap .counter-box:hover h4 {
  color: #ffffff;
}
.about-full-image-wrap .counter-box:hover span {
  color: #ffffff;
}
.about-full-image-wrap .counter-box:hover::before {
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
}

.about-mortgage-calculator-form {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
  border-radius: 10px;
  padding: 55px 65px;
  border-radius: 10px;
}
.about-mortgage-calculator-form h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
  color: #ffffff;
}
.about-mortgage-calculator-form form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.about-mortgage-calculator-form form .form-group label {
  margin-bottom: 0;
  font-size: 18px;
  position: absolute;
  top: 11px;
  left: 15px;
  color: #555555;
}
.about-mortgage-calculator-form form .form-group .form-control {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #E3E9ED;
  font-weight: 400;
  font-size: 14px;
  padding-left: 40px;
  width: 100%;
  color: #555555;
  background-color: #F8FAFB;
}
.about-mortgage-calculator-form form .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.about-mortgage-calculator-form form .form-group::placeholder {
  color: #7f7f7f;
}
.about-mortgage-calculator-form form .form-group .dropdown {
  position: relative;
}
.about-mortgage-calculator-form form .form-group .dropdown a {
  display: block;
  height: 50px;
  border-radius: 4px;
  color: #555555;
  background-color: #F8FAFB;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #E3E9ED;
  text-align: left;
  padding-left: 40px;
  line-height: 35px;
}
.about-mortgage-calculator-form form .form-group .dropdown a::after {
  border-top: 7px solid;
  position: absolute;
  top: 22px;
  right: 18px;
}
.about-mortgage-calculator-form form .form-group .dropdown a:focus {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.about-mortgage-calculator-form form .form-group .dropdown .dropdown-menu.show {
  display: block;
  width: 100%;
}
.about-mortgage-calculator-form form .main-default-btn {
  border: 0;
  width: 100%;
  background-color: #0d1820;
}
.about-mortgage-calculator-form form .main-default-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*----- Core Service Area CSS -----*/
.core-service-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.core-service-area.with-black-bg .main-section-title h2 {
  color: #ffffff;
}
.core-service-area.with-black-bg .core-service-list-tab .nav .nav-item:first-child .nav-link {
  border-radius: 0;
}
.core-service-area.with-black-bg .core-service-list-tab .nav .nav-item:last-child .nav-link {
  border-radius: 0;
}
.core-service-area.with-black-bg .core-service-list-tab .tab-content .tab-pane {
  border-radius: 0;
}

.core-service-list-tab .nav {
  padding: 0;
  list-style-type: none;
  display: block;
  border: none;
  text-align: center;
  margin-bottom: 0;
}
.core-service-list-tab .nav .nav-item {
  display: inline-block;
  margin-right: -5px;
}
.core-service-list-tab .nav .nav-item:first-child .nav-link {
  border-radius: 8px 0 0 0;
}
.core-service-list-tab .nav .nav-item:last-child .nav-link {
  border-radius: 0 8px 0 0;
}
.core-service-list-tab .nav .nav-item .nav-link {
  color: #ffffff;
  border: none;
  padding: 20px 30px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-weight: 400;
  border-radius: 0;
  transition: 0.5s all ease;
}
.core-service-list-tab .nav .nav-item .nav-link:hover, .core-service-list-tab .nav .nav-item .nav-link.active {
  color: black;
  background: white !important;
}
.core-service-list-tab .tab-content .tab-pane {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 50px;
}
.core-service-list-tab .core-service-content {
  padding-right: 30px;
}
.core-service-list-tab .core-service-content .icon {
  margin-bottom: 20px;
}
.core-service-list-tab .core-service-content .icon i {
  display: inline-block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  background-color: #e5f9f4;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  text-align: center;
  font-size: 35px;
  border-radius: 50px;
  transition: 0.5s all ease;
}
.core-service-list-tab .core-service-content .icon i:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.core-service-list-tab .core-service-content h3 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 15px;
}
.core-service-list-tab .core-service-content p {
  margin-bottom: 0;
}
.core-service-list-tab .core-service-content .list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 0;
}
.core-service-list-tab .core-service-content .list li {
  list-style-type: none;
  margin-bottom: 15px;
  position: relative;
  color: #0d1820;
  padding-left: 22px;
  font-size: 17.5px;
}
.core-service-list-tab .core-service-content .list li:last-child {
  margin-bottom: 0;
}
.core-service-list-tab .core-service-content .list li i {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.core-service-list-tab .core-service-image {
  overflow: hidden;
  border-radius: 10px;
}
.core-service-list-tab .core-service-image img {
  border-radius: 10px;
  transition: 0.5s all ease;
}
.core-service-list-tab .core-service-image:hover img {
  transform: scale(1.1);
}

/*----- Buying Home Area CSS -----*/
.buying-home-area {
  overflow: hidden;
}
.buying-home-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.buying-home-image {
  position: relative;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.buying-home-image img {
  border-radius: 0px 10px 10px 0px;
}
.buying-home-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 312px;
  background-color: transparent;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 10px;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.buying-home-image .counter-box {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 10px 0px 0px 10px;
  text-align: center;
  padding: 30px;
  position: absolute;
  right: 0;
  bottom: 45px;
}
.buying-home-image .counter-box h4 {
  color: #ffffff;
  font-size: 65px;
  line-height: 1;
  font-weight: bold;
  transition: 0.5s all ease;
  margin-bottom: 15px;
}
.buying-home-image .counter-box span {
  color: #ffffff;
  font-weight: 600;
  transition: 0.5s all ease;
}
.buying-home-image .counter-box:hover h4 {
  color: #ffffff;
}
.buying-home-image .counter-box:hover span {
  color: #ffffff;
}

.buying-home-content {
  padding-left: 15px;
  max-width: 750px;
}
.buying-home-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.buying-home-content h3 {
  font-size: 40px;
  margin-bottom: 18px;
  font-weight: bold;
}
.buying-home-content p {
  margin-bottom: 0;
}
.buying-home-content .buying-inner-box {
  margin-top: 30px;
  position: relative;
  padding-left: 100px;
}
.buying-home-content .buying-inner-box .icon {
  position: absolute;
  left: 0;
  top: 1.5px;
}
.buying-home-content .buying-inner-box .icon i {
  display: inline-block;
  width: 80px;
  height: 75px;
  line-height: 75px;
  border-radius: 10px;
  background: #e5f9f4;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-size: 45px;
  text-align: center;
  transition: 0.5s all ease;
}
.buying-home-content .buying-inner-box h4 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}
.buying-home-content .buying-inner-box p {
  margin-bottom: 0;
  font-size: 15px;
}
.buying-home-content .buying-inner-box:hover .icon i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

/*----- Mortgage Quote Area CSS -----*/
.mortgage-quote-area .row {
  background-color: #0d1820;
  border-radius: 10px;
}

.mortgage-quote-content {
  padding: 70px 50px 80px;
}
.mortgage-quote-content h3 {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 15px;
  color: #ffffff;
}
.mortgage-quote-content p {
  font-size: 15px;
  margin-bottom: 25px;
  color: #ffffff;
}
.mortgage-quote-content .quote-btn {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  transition: 0.5s all ease;
}
.mortgage-quote-content .quote-btn:hover {
  background: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

.mortgage-quote-image {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  border-radius: 0px 10px 10px 0px;
}

.mortgage-quote-area-with-full-width {
  overflow: hidden;
  background-color: #0d1820;
  position: relative;
  z-index: 1;
}
.mortgage-quote-area-with-full-width.with-main-color {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.mortgage-quote-area-with-full-width.with-main-color .mortgage-quote-content .quote-btn {
  background: #0d1820;
  color: #ffffff;
}
.mortgage-quote-area-with-full-width.with-main-color .mortgage-quote-content .quote-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.mortgage-quote-area-with-full-width .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.mortgage-quote-area-with-full-width .mortgage-quote-content {
  padding: 70px 50px 80px 0;
  max-width: 642px;
  margin-left: auto;
}
.mortgage-quote-area-with-full-width .mortgage-quote-content h3 {
  margin-bottom: 18px;
}
.mortgage-quote-area-with-full-width .mortgage-quote-image {
  border-radius: 0;
}

.mortgage-quote-shape {
  position: absolute;
  bottom: 15px;
  left: 45%;
  transform: translateX(-45%);
  max-width: 150px;
  z-index: -1;
}

/*----- Why Choose Us Area CSS -----*/
.why-choose-us-area {
  position: relative;
  z-index: 1;
  border-top: 1px solid #f9f9f9;
}
.why-choose-us-area::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 508px;
  left: 0;
  top: 0;
  right: 0;
  z-index: -1;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.why-choose-us-card .choose-image {
  overflow: hidden;
  border-radius: 10px 10px 0px 0px;
}
.why-choose-us-card .choose-image img {
  border-radius: 10px 10px 0px 0px;
  transition: 0.5s all ease;
}
.why-choose-us-card .choose-content {
  background: #F5F7FB;
  border-radius: 0px 0px 10px 10px;
  padding: 30px 25px;
  transition: 0.5s all ease;
}
.why-choose-us-card .choose-content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
  line-height: 1.5;
  transition: 0.5s all ease;
}
.why-choose-us-card .choose-content p {
  font-size: 15px;
  margin-bottom: 20px;
  transition: 0.5s all ease;
}
.why-choose-us-card .choose-content .choose-btn {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  transition: 0.5s all ease;
}
.why-choose-us-card:hover .choose-image img {
  transform: scale(1.2);
}
.why-choose-us-card:hover .choose-content {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.why-choose-us-card:hover .choose-content h3 {
  color: #ffffff;
}
.why-choose-us-card:hover .choose-content p {
  color: #ffffff;
}
.why-choose-us-card:hover .choose-content .choose-btn {
  background: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

.why-choose-us-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #DDDDDD;
  transition: 0.5s all ease;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 5px;
}
.why-choose-us-slider.owl-theme .owl-dots .owl-dot:hover span, .why-choose-us-slider.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: scale(1.2);
}
.why-choose-us-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
}
.why-choose-us-slider.owl-theme .owl-nav {
  margin-top: 0;
}

.why-choose-us-area-without-bg .main-section-title {
  max-width: 650px;
  margin: 0 auto 45px;
}

.why-choose-us-item {
  background-color: #F5F7FB;
  padding: 35px;
  margin-bottom: 30px;
  transition: 0.5s all ease;
}
.why-choose-us-item.with-border {
  background-color: transparent;
  border: 1px solid #F5F7FB;
}
.why-choose-us-item .choose-image {
  margin-bottom: 25px;
}
.why-choose-us-item .choose-content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 0;
  line-height: 1.5;
  transition: 0.5s all ease;
}
.why-choose-us-item .choose-content .list {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 25px;
}
.why-choose-us-item .choose-content .list li {
  list-style-type: none;
  color: #767676;
  margin-bottom: 15px;
  position: relative;
  padding-left: 22px;
  transition: 0.5s all ease;
}
.why-choose-us-item .choose-content .list li:last-child {
  margin-bottom: 0;
}
.why-choose-us-item .choose-content .list li i {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  position: absolute;
  left: 0;
  top: 0;
  transition: 0.5s all ease;
}
.why-choose-us-item .choose-content .choose-btn {
  display: inline-block;
  padding: 15px 30px;
  border-radius: 5px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  transition: 0.5s all ease;
}
.why-choose-us-item:hover {
  background-color: #0d1820;
  transform: translateY(-5px);
}
.why-choose-us-item:hover .choose-content h3 {
  color: #ffffff;
}
.why-choose-us-item:hover .choose-content .list li {
  color: #ffffff;
}
.why-choose-us-item:hover .choose-content .list li i {
  color: #ffffff;
}

/*----- Find Loan Officer Area CSS -----*/
.find-loan-officer-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.find-loan-officer-area::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0d1820;
  z-index: -1;
  opacity: 0.77;
}

.find-loan-officer-form {
  background-color: #ffffff;
  box-shadow: 0px 5px 50px rgba(13, 24, 32, 0.05);
  border-radius: 15px;
  padding: 50px 65px;
}
.find-loan-officer-form h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}
.find-loan-officer-form .loan-check-box {
  margin-top: 25px;
  margin-bottom: 25px;
}
.find-loan-officer-form .loan-check-box .form-check {
  display: inline-block;
  margin-right: 30px;
}
.find-loan-officer-form .loan-check-box .form-check:last-child {
  margin-right: 0;
}
.find-loan-officer-form .loan-check-box .form-check .form-check-label {
  color: #0d1820;
}
.find-loan-officer-form .loan-check-box .form-check .form-check-input {
  font-size: 15px;
}
.find-loan-officer-form .loan-check-box .form-check .form-check-input:checked {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.find-loan-officer-form .loan-check-box .form-check .form-check-input:focus {
  box-shadow: unset;
}
.find-loan-officer-form form .form-group {
  margin-bottom: 20px;
}
.find-loan-officer-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #767676;
  font-size: 14px;
}
.find-loan-officer-form form .form-group .form-control {
  display: block;
  height: 55px;
  font-weight: 400;
  background: #F8FAFB;
  border: 1px solid #E3E9ED;
  border-radius: 10px;
  outline: 0;
  padding: 15px 20px;
  color: #767676;
}
.find-loan-officer-form form .form-group .form-control::placeholder {
  color: #767676;
  transition: 0.5s all ease;
}
.find-loan-officer-form form .form-group .form-control:focus {
  box-shadow: unset;
}
.find-loan-officer-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.find-loan-officer-form form .form-group .nice-select {
  float: unset;
  height: 55px !important;
  line-height: 55px;
  color: #767676;
  background: #F8FAFB;
  border: 1px solid #E3E9ED;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 35px;
}
.find-loan-officer-form form .form-group .nice-select .list {
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  border-radius: 10px;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.find-loan-officer-form form .form-group .nice-select .list .option {
  transition: 0.5s all ease;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  font-weight: 500;
}
.find-loan-officer-form form .form-group .nice-select .list .option:hover {
  background-color: linear-gradient(135deg, cyan 30%, #eb9d37 85%) !important;
  color: #ffffff;
}
.find-loan-officer-form form .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 400;
}
.find-loan-officer-form form .form-group .nice-select:after {
  right: 20px;
  width: 8px;
  height: 8px;
  margin-top: -5.5px;
}
.find-loan-officer-form form .loan-button {
  text-align: center;
  margin-top: 30px;
}
.find-loan-officer-form form .loan-button .default-btn {
  display: inline-block;
  padding: 15px 30px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  width: 100%;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.find-loan-officer-form form .loan-button .default-btn:hover {
  background-color: #0d1820;
}

.find-loan-officer-image {
  padding-left: 50px;
  position: relative;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 15px;
}
.find-loan-officer-image img {
  border-radius: 15px;
}
.find-loan-officer-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 312px;
  background-color: transparent;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 10px;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.find-loan-officer-image .find-loan-popup {
  position: absolute;
  right: 0;
  bottom: 35px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 10px 0px 0px 10px;
  padding: 15px 45px;
  transition: 0.5s all ease;
}
.find-loan-officer-image .find-loan-popup .video-btn i {
  font-size: 50px;
  color: #ffffff;
}
.find-loan-officer-image .find-loan-popup:hover {
  background-color: #0d1820;
}

/*----- Staff Area CSS -----*/
.staff-card {
  margin-bottom: 30px;
}
.staff-card .staff-image {
  transition: 0.5s all ease;
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-left: 0;
}
.staff-card .staff-image img {
  border-radius: 15px;
}
.staff-card .staff-image::before {
  position: absolute;
  content: "";
  height: 340px;
  width: 270px;
  background-color: transparent;
  left: 0;
  top: 0;
  z-index: -1;
  border-radius: 10px;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease;
}
.staff-card .staff-image .social-link {
  position: absolute;
  left: 0;
  bottom: 0;
  border-radius: 0px 10px 10px 0px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  padding: 25px;
  transition: 0.5s all ease;
  opacity: 0;
  visibility: hidden;
  margin-bottom: 0;
}
.staff-card .staff-image .social-link li {
  display: inline-block;
  margin-right: 5px;
}
.staff-card .staff-image .social-link li:last-child {
  margin-right: 0;
}
.staff-card .staff-image .social-link li a i {
  display: inline-block;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  text-align: center;
  border-radius: 50px;
  font-size: 15px;
  transition: 0.5s all ease;
}
.staff-card .staff-image .social-link li a i:hover {
  background-color: #0d1820;
  color: #ffffff;
  transform: translateY(-2px);
}
.staff-card .staff-content {
  text-align: center;
  margin-top: 30px;
}
.staff-card .staff-content h3 {
  color: #0d1820;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
}
.staff-card .staff-content span {
  color: #767676;
  display: inline-block;
  margin-top: 10px;
}
.staff-card:hover .staff-image {
  padding-top: 10px;
  padding-left: 10px;
}
.staff-card:hover .staff-image::before {
  opacity: 1;
  visibility: visible;
}
.staff-card:hover .staff-image .social-link {
  opacity: 1;
  visibility: visible;
  bottom: 35px;
}
.staff-card.without-border .staff-image {
  border-radius: 15px;
  overflow: hidden;
}
.staff-card.without-border .staff-image::before {
  display: none;
}
.staff-card.without-border .staff-image img {
  border-radius: 15px;
  transition: 0.5s all ease;
}
.staff-card.without-border:hover .staff-image {
  padding-top: 0;
  padding-left: 0;
}
.staff-card.without-border:hover .staff-image img {
  transform: scale(1.1);
}
.staff-card.without-border-radius .staff-image {
  border-radius: 0;
  text-align: center;
}
.staff-card.without-border-radius .staff-image img {
  border-radius: 0;
}
.staff-card.without-border-radius .staff-image .social-link {
  left: 0;
  right: 0;
  border-radius: 0;
  max-width: 220px;
  margin: auto;
}

.staff-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #DDDDDD;
  transition: 0.5s all ease;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 5px;
}
.staff-slider.owl-theme .owl-dots .owl-dot:hover span, .staff-slider.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: scale(1.2);
}
.staff-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.staff-slider.owl-theme .owl-nav {
  margin-top: 0;
}

/*----- Need Help FAQ Area CSS -----*/
.need-help-faq-area {
  overflow: hidden;
}
.need-help-faq-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.need-help-faq-content {
  max-width: 640px;
  margin-left: auto;
}
.need-help-faq-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.need-help-faq-content h3 {
  font-size: 40px;
  margin-bottom: 0;
  font-weight: bold;
}
.need-help-faq-content .need-help-faq-accordion {
  margin-top: 30px;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item {
  margin-bottom: 30px;
  transition: 0.5s all ease;
  border-radius: 5px;
  border: none;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item .accordion-button {
  color: #0d1820;
  position: relative;
  text-align: start;
  box-shadow: unset;
  margin-bottom: 0;
  display: block;
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: bold;
  padding: 0 45px 0 0;
  transition: 0.5s all ease;
  background-color: transparent;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item .accordion-button::before {
  position: absolute;
  right: 0;
  top: 3.5px;
  content: "-";
  font-size: 15px;
  color: 0.5s all ease;
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  border-radius: 50px;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item .accordion-button.collapsed::before {
  content: "+";
  color: #ffffff;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item .accordion-body {
  padding: 20px 0 0 0;
  transition: 0.5s all ease;
  border: none;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item .accordion-body p {
  color: #767676;
  font-size: 15px;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item .accordion-collapse {
  border: none;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.need-help-faq-content .need-help-faq-accordion .accordion-item:last-child .accordion-body p {
  margin-bottom: 0;
}

.need-help-faq-image {
  margin-left: 50px;
  position: relative;
  z-index: 1;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 15px;
}
.need-help-faq-image img {
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #eeeeee;
}
.need-help-faq-image::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 312px;
  background-color: transparent;
  left: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 10px;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.need-help-faq-image .help-box {
  position: absolute;
  left: 0;
  bottom: 35px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 0 10px 10px 0;
  padding: 30px;
  text-align: center;
}
.need-help-faq-image .help-box h3 {
  font-weight: bold;
  color: #ffffff;
  font-size: 45px;
  margin-bottom: 12px;
  line-height: 1;
}
.need-help-faq-image .help-box span {
  color: #ffffff;
}

/*----- Testimonials Style Area CSS -----*/
.testimonials-style-area {
  background-color: #F5F7FB;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.testimonials-style-area.bg-ffffff {
  background-color: #ffffff;
}

.testimonials-style-content {
  padding-left: 30px;
}
.testimonials-style-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.testimonials-style-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.testimonials-style-content.with-border-color {
  border: 1px solid #DDDDDD;
  padding: 35px;
  border-radius: 5px;
}

.testimonials-style-card .info {
  position: relative;
  padding-left: 90px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.testimonials-style-card .info i {
  display: inline-block;
  height: 70px;
  width: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 45px;
  text-align: center;
  border-radius: 50px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.testimonials-style-card .info h4 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: bold;
}
.testimonials-style-card .info span {
  color: #767676;
  font-size: 14px;
}
.testimonials-style-card p {
  margin-bottom: 0;
}
.testimonials-style-card .star-list {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.testimonials-style-card .star-list li {
  margin-right: 5px;
  display: inline-block;
}
.testimonials-style-card .star-list li:last-child {
  margin-right: 0;
}
.testimonials-style-card .star-list li i {
  color: #FFC700;
  font-size: 18px;
}

.testimonials-style-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #DDDDDD;
  transition: 0.5s all ease;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 5px;
}
.testimonials-style-slider.owl-theme .owl-dots .owl-dot:hover span, .testimonials-style-slider.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: scale(1.2);
}
.testimonials-style-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 30px;
  text-align: start;
}
.testimonials-style-slider.owl-theme .owl-nav {
  margin-top: 0;
}

.testimonials-style-area-with-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.testimonials-style-area-with-bg .testimonials-style-content {
  padding-left: 0;
  background-color: #ffffff;
  padding: 50px;
  max-width: 750px;
}
.testimonials-style-area-with-bg .testimonials-style-content .info {
  padding-top: 40px;
  padding-bottom: 25px;
}
.testimonials-style-area-with-bg.bg-style-two .testimonials-style-content {
  padding-left: 0;
  background-color: transparent;
  padding: 0;
  max-width: 750px;
}

.testimonials-gradient-shape {
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
}

/*----- Let’s Contact Area CSS -----*/
.let-contact-area {
  background-color: #0d1820;
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.let-contact-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.let-contact-area.with-main-color {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.let-contact-area.with-main-color .let-contact-form .sub-title {
  color: #ffffff;
}
.let-contact-area.with-main-color .let-contact-form form .let-contact-btn .main-default-btn {
  background-color: #0d1820;
  color: #ffffff;
}
.let-contact-area.with-main-color .let-contact-form form .let-contact-btn .main-default-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.let-contact-area.with-main-color .let-contact-form form .text-danger {
  color: #ffffff !important;
}

.let-contact-form {
  max-width: 640px;
  margin-left: auto;
}
.let-contact-form .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
  position: relative;
  left: 8px;
}
.let-contact-form h3 {
  font-size: 40px;
  margin-bottom: 0;
  font-weight: bold;
  color: #ffffff;
  position: relative;
  left: 8px;
}
.let-contact-form form {
  margin-top: 25px;
}
.let-contact-form form .form-group {
  margin-bottom: 20px;
}
.let-contact-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
.let-contact-form form .form-group .form-control {
  display: block;
  height: 55px;
  font-weight: 400;
  background: #F8FAFB;
  border: 1px solid #E3E9ED;
  border-radius: 10px;
  outline: 0;
  padding: 15px 20px;
  color: #555555;
}
.let-contact-form form .form-group .form-control::placeholder {
  color: #555555;
  transition: 0.5s all ease;
}
.let-contact-form form .form-group .form-control:focus {
  box-shadow: unset;
}
.let-contact-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.let-contact-form form .form-group textarea.form-control {
  min-height: 120px;
}
.let-contact-form form .form-group .list-unstyled li {
  font-size: 12px;
  margin-top: 10px;
  color: red;
}
.let-contact-form form .let-contact-btn .main-default-btn {
  display: inline-block;
  padding: 15px 30px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.let-contact-form form .let-contact-btn .main-default-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.let-contact-form form .text-danger {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%) !important;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0;
}

.let-contact-map {
  padding-left: 50px;
}
.let-contact-map iframe {
  width: 100%;
  height: 800px;
  margin-bottom: -10px;
}

.let-contact-area-with-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.let-contact-area-with-bg::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0d1820;
  z-index: -1;
  opacity: 0.77;
}
.let-contact-area-with-bg .main-section-title h2 {
  color: #ffffff;
}
.let-contact-area-with-bg .let-contact-form {
  max-width: 100%;
}
.let-contact-area-with-bg .let-contact-form form {
  margin-top: 0;
}
.let-contact-area-with-bg .let-contact-form form .form-group {
  margin-bottom: 20px;
}
.let-contact-area-with-bg .let-contact-form form .form-group .form-control {
  height: 60px;
  color: #555555;
  font-size: 15px;
  border-radius: 5px;
}
.let-contact-area-with-bg .let-contact-form form .form-group .form-control::placeholder {
  color: #767676;
  transition: 0.5s all ease;
}
.let-contact-area-with-bg .let-contact-form form .form-group .form-control:focus {
  box-shadow: unset;
}
.let-contact-area-with-bg .let-contact-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.let-contact-area-with-bg .let-contact-form form .form-group textarea.form-control {
  min-height: 172px;
}
.let-contact-area-with-bg .let-contact-form form .let-contact-btn .main-default-btn {
  width: 100%;
  border-radius: 5px;
}

.let-contact-area-with-bg-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.let-contact-area-with-bg-image .let-contact-form {
  margin-left: 0;
  margin-right: auto;
}
.let-contact-area-with-bg-image .let-contact-form h3 {
  color: #0d1820;
}
.let-contact-area-with-bg-image .let-contact-form form .form-group .form-control {
  font-size: 14px;
}
.let-contact-area-with-bg-image .let-contact-form form .form-group label {
  color: #0d1820;
}

.let-contact-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 30%;
}

.let-contact-form-with-gray-color {
  margin-top: 50px;
  position: relative;
  z-index: 1;
}
.let-contact-form-with-gray-color .main-section-title .sub-title {
  color: #ffffff;
}
.let-contact-form-with-gray-color .main-section-title h2 {
  color: #ffffff;
}
.let-contact-form-with-gray-color form {
  background: #F5F7FB;
  padding: 50px;
  max-width: 880px;
  margin: auto;
  border-radius: 5px;
}
.let-contact-form-with-gray-color form .form-group {
  margin-bottom: 20px;
}
.let-contact-form-with-gray-color form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #0d1820;
  font-size: 14px;
}
.let-contact-form-with-gray-color form .form-group .form-control {
  display: block;
  height: 55px;
  font-weight: 400;
  background: #ffffff;
  border: 1px solid #E3E9ED;
  border-radius: 10px;
  outline: 0;
  padding: 15px 20px;
  color: #555555;
}
.let-contact-form-with-gray-color form .form-group .form-control::placeholder {
  color: #555555;
  transition: 0.5s all ease;
}
.let-contact-form-with-gray-color form .form-group .form-control:focus {
  box-shadow: unset;
}
.let-contact-form-with-gray-color form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.let-contact-form-with-gray-color form .form-group textarea.form-control {
  min-height: 120px;
}
.let-contact-form-with-gray-color form .form-group .list-unstyled li {
  font-size: 12px;
  margin-top: 10px;
  color: red;
}
.let-contact-form-with-gray-color form .let-contact-btn .main-default-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #0d1820;
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.let-contact-form-with-gray-color form .let-contact-btn .main-default-btn:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.let-contact-form-with-gray-color form .text-danger {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%) !important;
  font-size: 15px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 0;
}
.let-contact-form-with-gray-color .let-contact-shape {
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}

/*----- Blog Style Area CSS -----*/
.blog-style-left-card .blog-image {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}
.blog-style-left-card .blog-image .tag {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  position: absolute;
  left: 20px;
  top: 20px;
  font-size: 14px;
}
.blog-style-left-card .blog-image img {
  border-radius: 10px;
  transition: 0.5s all ease;
}
.blog-style-left-card .blog-content {
  margin-top: 25px;
}
.blog-style-left-card .blog-content .post-meta {
  padding: 0;
  margin-bottom: 25px;
}
.blog-style-left-card .blog-content .post-meta li {
  color: #767676;
  font-size: 15px;
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 22px;
}
.blog-style-left-card .blog-content .post-meta li:first-child {
  padding-left: 0;
}
.blog-style-left-card .blog-content .post-meta li:last-child {
  margin-right: 0;
}
.blog-style-left-card .blog-content .post-meta li .post-author img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}
.blog-style-left-card .blog-content .post-meta li .post-author span {
  font-weight: 400;
  font-size: 15px;
  color: #767676;
}
.blog-style-left-card .blog-content .post-meta li i {
  position: absolute;
  left: 0;
  top: 2.8px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.blog-style-left-card .blog-content h3 {
  font-size: 24px;
  margin-bottom: 15px;
  font-weight: bold;
}
.blog-style-left-card .blog-content h3 a {
  color: #0d1820;
}
.blog-style-left-card .blog-content p {
  margin-bottom: 0;
}
.blog-style-left-card:hover .blog-image img {
  transform: scale(1.2);
}

.blog-style-right-card {
  padding-left: 45px;
}
.blog-style-right-card .card-box {
  margin-bottom: 40px;
  padding-bottom: 30px;
  border-bottom: 1px solid #E3E9ED;
}
.blog-style-right-card .card-box:last-child {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: none;
}
.blog-style-right-card .card-box .blog-content .tag {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  font-size: 14px;
  margin-bottom: 22px;
  transition: 0.5s all ease;
}
.blog-style-right-card .card-box .blog-content .tag:hover {
  background-color: #0d1820;
}
.blog-style-right-card .card-box .blog-content .post-meta {
  padding: 0;
  margin-bottom: 15px;
}
.blog-style-right-card .card-box .blog-content .post-meta li {
  color: #767676;
  font-size: 15px;
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 22px;
}
.blog-style-right-card .card-box .blog-content .post-meta li:last-child {
  margin-right: 0;
}
.blog-style-right-card .card-box .blog-content .post-meta li i {
  position: absolute;
  left: 0;
  top: 2.8px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.blog-style-right-card .card-box .blog-content h3 {
  font-size: 24px;
  margin-bottom: 12px;
  font-weight: bold;
  line-height: 1.5;
}
.blog-style-right-card .card-box .blog-content h3 a {
  color: #0d1820;
}
.blog-style-right-card .card-box .blog-content p {
  margin-bottom: 0;
}

.blog-style-card {
  margin-bottom: 30px;
  background-color: #F5F7FB;
  padding: 30px;
  transition: 0.5s all ease;
}
.blog-style-card .tag {
  display: inline-block;
  padding: 5px 15px;
  border-radius: 5px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  margin-bottom: 28px;
}
.blog-style-card .post-meta {
  padding: 0;
  margin-bottom: 20px;
}
.blog-style-card .post-meta li {
  color: #767676;
  font-size: 14px;
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  padding-left: 22px;
  transition: 0.5s all ease;
}
.blog-style-card .post-meta li:first-child {
  padding-left: 0;
}
.blog-style-card .post-meta li:last-child {
  margin-right: 0;
}
.blog-style-card .post-meta li .post-author img {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  display: inline-block;
}
.blog-style-card .post-meta li .post-author span {
  font-weight: 400;
  font-size: 14px;
  color: #767676;
  transition: 0.5s all ease;
}
.blog-style-card .post-meta li i {
  position: absolute;
  left: 0;
  top: 0;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transition: 0.5s all ease;
}
.blog-style-card h3 {
  font-size: 22px;
  font-weight: bold;
  line-height: 1.5;
  margin-bottom: 15px;
}
.blog-style-card h3 a {
  color: #0d1820;
  transition: 0.5s all ease;
}
.blog-style-card p {
  margin-bottom: 0;
  transition: 0.5s all ease;
  font-size: 15px;
}
.blog-style-card:hover {
  background-color: #0d1820;
}
.blog-style-card:hover .post-meta li {
  color: #ffffff;
}
.blog-style-card:hover .post-meta li .post-author span {
  color: #ffffff;
}
.blog-style-card:hover .post-meta li i {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.blog-style-card:hover h3 a {
  color: #ffffff;
}
.blog-style-card:hover p {
  color: #ffffff;
}

.blog-style-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #DDDDDD;
  transition: 0.5s all ease;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 5px;
}
.blog-style-slider.owl-theme .owl-dots .owl-dot:hover span, .blog-style-slider.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: scale(1.2);
}
.blog-style-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.blog-style-slider.owl-theme .owl-nav {
  margin-top: 0;
}

/*----- Subscribe Style Area CSS -----*/
.subscribe-style-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.subscribe-style-wrap-content h2 {
  color: #ffffff;
  font-weight: bold;
  margin-bottom: 0;
  font-size: 40px;
}
.subscribe-style-wrap-content .newsletter-form {
  position: relative;
}
.subscribe-style-wrap-content .newsletter-form .form-control {
  display: block;
  width: 100%;
  background-color: #ffffff;
  border: none;
  height: 70px;
  padding: 15px 25px;
  border-radius: 10px;
  outline: 0;
  color: #767676;
  font-size: 15px;
}
.subscribe-style-wrap-content .newsletter-form .form-control::placeholder {
  color: #767676;
  transition: 0.5s all ease;
}
.subscribe-style-wrap-content .newsletter-form .form-control:focus {
  box-shadow: unset;
}
.subscribe-style-wrap-content .newsletter-form .form-control:focus::placeholder {
  color: transparent;
}
.subscribe-style-wrap-content .newsletter-form .main-default-btn {
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  color: #ffffff !important;
  padding: 20px;
  position: absolute;
  top: 4px;
  right: 4px;
}
.subscribe-style-wrap-content .newsletter-form #validator-newsletter {
  color: #ffffff;
  font-size: 14px;
  margin-top: 10px;
}

/*----- Subscribe Style Area CSS -----*/
.footer-style-area {
  background: #0d1820;
}
.footer-style-area .copyright-style-area {
  margin-top: 70px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #212e37;
}
.footer-style-area .copyright-style-area .copyright-item p {
  margin-bottom: 0;
  color: #E3E9ED;
  font-size: 15px;
}
.footer-style-area .copyright-style-area .copyright-item p a {
  display: inline-block;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-weight: 600;
}
.footer-style-area .copyright-style-area .copyright-item p a:hover {
  letter-spacing: 1px;
}
.footer-style-area .copyright-style-area .copyright-item .payment-list {
  margin: 0;
  padding: 0;
  text-align: end;
}
.footer-style-area .copyright-style-area .copyright-item .payment-list li {
  list-style-type: none;
  display: inline-block;
  margin-right: 8px;
}
.footer-style-area .copyright-style-area .copyright-item .payment-list li:last-child {
  margin-right: 0;
}
.footer-style-area .copyright-style-area .copyright-item .payment-list li a {
  display: block;
}
.footer-style-area .copyright-style-area .copyright-item .payment-list li a:hover {
  transform: translateY(-5px);
}

.single-footer-widget {
  margin-bottom: 25px;
}
.single-footer-widget .footer-logo a img {
  margin-bottom: 30px;
}
.single-footer-widget .footer-logo p {
  color: #ffffff;
  margin-bottom: 20px;
  font-size: 15px;
}
.single-footer-widget .footer-logo .footer-social {
  padding: 0;
  margin-bottom: 0;
}
.single-footer-widget .footer-logo .footer-social li {
  display: inline-block;
  margin-right: 10px;
}
.single-footer-widget .footer-logo .footer-social li:last-child {
  margin-right: 0;
}
.single-footer-widget .footer-logo .footer-social li a i {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border-radius: 50px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  transition: 0.5s all ease;
  font-size: 15px;
}
.single-footer-widget .footer-logo .footer-social li a i:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: translateY(-2px);
}
.single-footer-widget h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 25px;
  color: #ffffff;
}
.single-footer-widget .quick-link {
  padding: 0 0 0 0;
  margin-bottom: 0;
}
.single-footer-widget .quick-link li {
  list-style-type: none;
  margin-bottom: 15px;
  position: relative;
  padding-left: 22px;
  font-size: 15px;
}
.single-footer-widget .quick-link li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .quick-link li a {
  color: #ffffff;
}
.single-footer-widget .quick-link li a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.single-footer-widget .quick-link li::before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  border-radius: 50px;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-footer-widget .address-info {
  padding: 0;
  margin-bottom: 0;
}
.single-footer-widget .address-info li {
  list-style-type: none;
  margin-bottom: 15px;
  font-size: 15px;
  color: #ffffff;
  position: relative;
  padding-left: 22px;
}
.single-footer-widget .address-info li:last-child {
  margin-bottom: 0;
}
.single-footer-widget .address-info li i {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.single-footer-widget .address-info li a {
  color: #ffffff;
  font-size: 15px;
}
.single-footer-widget .address-info li a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*----- Banner Buy Area CSS -----*/
.banner-buy-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-bottom: 70px;
}
.banner-buy-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.banner-buy-content {
  background: rgba(0, 0, 0, 0.5);
  padding: 70px 80px;
  max-width: 850px;
  border-radius: 0px 0px 50px 50px;
}
.banner-buy-content .sub-title {
  display: inline-block;
  margin-bottom: 18px;
  color: #ffffff;
}
.banner-buy-content h1 {
  font-size: 85px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #ffffff;
}
.banner-buy-content p {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 15px;
}
.banner-buy-content .banner-buy-btn {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.banner-buy-content .banner-buy-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 18px;
}
.banner-buy-content .banner-buy-btn li:last-child {
  margin-right: 0;
}
.banner-buy-content .banner-buy-btn li .main-default-btn {
  color: #ffffff;
}
.banner-buy-content .banner-buy-btn li .main-optional-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.banner-buy-content .banner-buy-btn li .main-optional-btn:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

.banner-buy-info-card {
  position: relative;
  margin-top: 50px;
  padding-left: 82px;
}
.banner-buy-info-card .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.banner-buy-info-card .icon i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  font-size: 35px;
  transition: 0.5s all ease;
}
.banner-buy-info-card p {
  margin-bottom: 0;
}
.banner-buy-info-card p span {
  display: block;
  color: #ffffff;
  margin-bottom: 8px;
}
.banner-buy-info-card p span:last-child {
  margin-bottom: 0;
}
.banner-buy-info-card p span a {
  color: #ffffff;
}
.banner-buy-info-card:hover .icon i {
  background-color: #0d1820;
  color: #ffffff;
}

/*----- Offer Style Area CSS -----*/
.offer-style-card {
  position: relative;
  transition: 0.5s all ease;
  margin-bottom: 30px;
}
.offer-style-card::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 90%;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.4) 61.46%, rgba(255, 255, 255, 0.9) 100%);
  bottom: 0;
}
.offer-style-card .offer-content {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 30px 35px 30px;
}
.offer-style-card .offer-content h3 {
  font-size: 20px;
  color: #0d1820;
  margin-bottom: 12px;
  font-weight: bold;
  line-height: 1.5;
}
.offer-style-card .offer-content a {
  font-weight: 500;
  color: #0d1820;
  font-size: 15px;
  transition: 0.5s all ease;
}
.offer-style-card:hover {
  transform: translateY(10px);
}
.offer-style-card:hover .offer-content a {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.offer-style-card.with-black-color::before {
  background: linear-gradient(180deg, rgba(13, 24, 32, 0) 0%, #000000 100%, rgba(0, 0, 0, 0.7) 100%);
}
.offer-style-card.with-black-color::after {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 90%;
  background: linear-gradient(180deg, rgba(0, 193, 148, 0) 0%, rgba(0, 173, 133, 0.682292) 61.98%, #00A47E 100%);
  bottom: 0;
  transition: 0.5s all ease;
  opacity: 0;
  visibility: hidden;
}
.offer-style-card.with-black-color .offer-content {
  z-index: 1;
}
.offer-style-card.with-black-color .offer-content h3 {
  color: #ffffff;
  transition: 0.5s all ease;
}
.offer-style-card.with-black-color .offer-content a {
  color: #ffffff;
  transition: 0.5s all ease;
}
.offer-style-card.with-black-color:hover::after {
  opacity: 1;
  visibility: visible;
}
.offer-style-card.with-black-color:hover .offer-content h3 {
  color: #ffffff;
}
.offer-style-card.with-black-color:hover .offer-content a {
  color: #ffffff;
}

.offer-style-slider.owl-theme .owl-dots .owl-dot span {
  width: 10px;
  height: 10px;
  background-color: #DDDDDD;
  transition: 0.5s all ease;
  border-radius: 50%;
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  margin: 0 5px;
}
.offer-style-slider.owl-theme .owl-dots .owl-dot:hover span, .offer-style-slider.owl-theme .owl-dots .owl-dot.active span {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: scale(1.2);
}
.offer-style-slider.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 0;
}
.offer-style-slider.owl-theme .owl-nav {
  margin-top: 0;
}

.offer-style-area-with-full-width {
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.offer-style-area-with-full-width .container-fluid {
  left: calc((100% - 1320px) / 2);
  position: relative;
}
.offer-style-area-with-full-width::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 950px;
  background: #F5F7FB;
  border-radius: 0px 100px 0px 0px;
  z-index: -1;
}

.offer-style-section-content .sub-title {
  display: inline-block;
  margin-bottom: 15px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.offer-style-section-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.offer-style-section-content p {
  margin-bottom: 0;
  font-size: 15px;
}

.offer-style-item {
  margin-bottom: 30px;
}
.offer-style-item .offer-image {
  position: relative;
  z-index: 1;
}
.offer-style-item .offer-image::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  width: 100%;
  height: 90%;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.4) 61.46%, rgba(0, 0, 0, 0.8) 100%);
  bottom: 0;
}
.offer-style-item .offer-content {
  padding: 30px;
  border: 1px solid #EBE9EE;
  background-color: #ffffff;
  margin-top: 10px;
  transition: 0.5s all ease;
}
.offer-style-item .offer-content h3 {
  font-size: 18px;
  color: #0d1820;
  margin-bottom: 12px;
  font-weight: bold;
  line-height: 1.6;
  transition: 0.5s all ease;
}
.offer-style-item .offer-content a {
  font-weight: 500;
  color: #0d1820;
  font-size: 14px;
  transition: 0.5s all ease;
  transition: 0.5s all ease;
}
.offer-style-item:hover .offer-content {
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.offer-style-item:hover .offer-content h3 {
  color: #ffffff;
}
.offer-style-item:hover .offer-content a {
  color: #ffffff;
}

/*----- Get Introduced Area CSS -----*/
.get-introduced-area {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.get-introduced-area .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.get-introduced-area::before {
  position: absolute;
  content: "";
  width: 250px;
  height: 100%;
  background-color: #ffffff;
  right: 0;
  top: 0;
  z-index: -1;
}
.get-introduced-area::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 90px;
  background-color: #ffffff;
  right: 0;
  bottom: 0;
  z-index: -1;
}

.get-introduced-content {
  max-width: 640px;
  margin-left: auto;
}
.get-introduced-content .sub-title {
  display: inline-block;
  margin-bottom: 18px;
  color: #ffffff;
}
.get-introduced-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
  color: #ffffff;
}
.get-introduced-content p {
  margin-bottom: 0;
  color: #ffffff;
  font-size: 15px;
}
.get-introduced-content .introduced-inner-box {
  background-color: #00ad85;
  padding: 35px 20px 35px 85px;
  border-radius: 5px;
  position: relative;
  margin-top: 30px;
}
.get-introduced-content .introduced-inner-box .icon {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.get-introduced-content .introduced-inner-box .icon i {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  border-radius: 64% 36% 35% 65%/65% 44% 56% 35%;
}
.get-introduced-content .introduced-inner-box span {
  color: #ffffff;
  font-size: 15px;
}
.get-introduced-content .introduced-counter-item {
  margin-top: 80px;
}
.get-introduced-content .introduced-counter-item .counter-card {
  position: relative;
  margin-top: 25px;
}
.get-introduced-content .introduced-counter-item .counter-card h3 {
  font-weight: 700;
  font-size: 55px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  margin-bottom: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.get-introduced-content .introduced-counter-item .counter-card p {
  margin-bottom: 0;
  font-size: 15px;
  color: #0d1820;
  padding-left: 85px;
  padding-top: 10px;
  font-weight: 500;
}

.get-introduced-image {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
  margin-left: 35px;
  border-radius: 10px 0 0 10px;
}

/*----- Video Overview Area CSS -----*/
.video-overview-image {
  position: relative;
  z-index: 1;
  text-align: center;
}
.video-overview-image::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.5s all ease;
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  z-index: 1;
  margin: 50px;
  opacity: 0.77;
}
.video-overview-image .video-btn {
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.video-overview-image .video-btn i {
  width: 100px;
  height: 100px;
  line-height: 100px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-size: 70px;
  margin-right: 10px;
  transition: 0.5s all ease;
}
.video-overview-image .video-btn:hover i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.video-overview-image .video-btn span {
  color: #0d1820;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -5px;
}

.video-overview-content {
  padding-left: 45px;
}
.video-overview-content .overview-inner-box {
  margin-bottom: 35px;
  position: relative;
  padding-left: 100px;
}
.video-overview-content .overview-inner-box:last-child {
  margin-bottom: 0;
}
.video-overview-content .overview-inner-box .icon {
  position: absolute;
  left: 0;
  top: 1.5px;
}
.video-overview-content .overview-inner-box .icon i {
  display: inline-block;
  width: 80px;
  height: 75px;
  line-height: 75px;
  border-radius: 10px;
  background: #e5f9f4;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-size: 45px;
  text-align: center;
  transition: 0.5s all ease;
}
.video-overview-content .overview-inner-box h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 12px;
}
.video-overview-content .overview-inner-box p {
  margin-bottom: 10px;
  font-size: 15px;
}
.video-overview-content .overview-inner-box a {
  font-weight: 500;
  color: #0d1820;
  font-size: 15px;
  transition: 0.5s all ease;
}
.video-overview-content .overview-inner-box:hover .icon i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

/*----- Get Address Area CSS -----*/
.get-address-content .sub-title {
  display: inline-block;
  margin-bottom: 18px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.get-address-content h3 {
  font-size: 40px;
  margin-bottom: 15px;
  font-weight: bold;
}
.get-address-content .inner-box {
  margin-top: 30px;
  position: relative;
  padding-left: 75px;
}
.get-address-content .inner-box::before {
  width: 1px;
  height: 140%;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  content: "";
  z-index: -1;
  position: absolute;
  left: 28px;
  top: 0;
}
.get-address-content .inner-box:last-child::before {
  display: none;
}
.get-address-content .inner-box .number {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  text-align: center;
  font-size: 25px;
  border-radius: 64% 36% 35% 65%/65% 44% 56% 35%;
  font-weight: bold;
  font-style: italic;
  position: absolute;
  left: 0;
  top: 0;
}
.get-address-content .inner-box h4 {
  font-size: 22px;
  margin-bottom: 12px;
  font-weight: bold;
}
.get-address-content .inner-box p {
  color: #767676;
}

.get-address-form {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 15px;
  padding: 50px 65px;
  margin-left: 35px;
}
.get-address-form h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
  color: #ffffff;
}
.get-address-form .loan-check-box {
  margin-top: 25px;
  margin-bottom: 25px;
}
.get-address-form .loan-check-box .form-check {
  display: inline-block;
  margin-right: 30px;
}
.get-address-form .loan-check-box .form-check:last-child {
  margin-right: 0;
}
.get-address-form .loan-check-box .form-check .form-check-label {
  color: #ffffff;
}
.get-address-form .loan-check-box .form-check .form-check-input {
  font-size: 15px;
}
.get-address-form .loan-check-box .form-check .form-check-input:checked {
  background-color: #0d1820;
  border-color: #0d1820;
}
.get-address-form .loan-check-box .form-check .form-check-input:focus {
  box-shadow: unset;
}
.get-address-form form .form-group {
  margin-bottom: 20px;
}
.get-address-form form .form-group label {
  display: block;
  margin-bottom: 10px;
  font-weight: 500;
  color: #ffffff;
  font-size: 14px;
}
.get-address-form form .form-group .form-control {
  display: block;
  height: 55px;
  font-weight: 400;
  background: #F8FAFB;
  border: 1px solid #E3E9ED;
  border-radius: 10px;
  outline: 0;
  padding: 15px 20px;
  color: #767676;
}
.get-address-form form .form-group .form-control::placeholder {
  color: #767676;
  transition: 0.5s all ease;
}
.get-address-form form .form-group .form-control:focus {
  box-shadow: unset;
}
.get-address-form form .form-group .form-control:focus::placeholder {
  color: transparent;
}
.get-address-form form .form-group .nice-select {
  float: unset;
  height: 55px !important;
  line-height: 55px;
  color: #767676;
  background: #F8FAFB;
  border: 1px solid #E3E9ED;
  font-size: 14px;
  font-weight: 500;
  border-radius: 10px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 20px;
  padding-right: 35px;
}
.get-address-form form .form-group .nice-select .list {
  background: llinear-gradient(90deg, rgb(226, 191, 21) 23%, rgb(235, 157, 55) 64%);
  border-radius: 10px;
  margin-top: 0;
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}
.get-address-form form .form-group .nice-select .list .option {
  transition: 0.5s all ease;
  padding-left: 20px;
  padding-right: 20px;
  color: #ffffff;
  font-weight: 500;
}
.get-address-form form .form-group .nice-select .list .option:hover {
  background-color: linear-gradient(135deg, cyan 30%, #eb9d37 85%) !important;
  color: #ffffff;
}
.get-address-form form .form-group .nice-select .list .option.selected {
  background-color: transparent;
  font-weight: 400;
}
.get-address-form form .form-group .nice-select:after {
  right: 20px;
  width: 8px;
  height: 8px;
  margin-top: -5.5px;
}
.get-address-form form .loan-button {
  text-align: center;
  margin-top: 30px;
}
.get-address-form form .loan-button .default-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #0d1820;
  color: #ffffff;
  width: 100%;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.get-address-form form .loan-button .default-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*----- FAQ Style Area CSS -----*/
.faq-style-accordion {
  max-width: 870px;
  margin: auto;
}
.faq-style-accordion .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.faq-style-accordion h3 {
  font-size: 40px;
  margin-bottom: 35px;
  font-weight: bold;
}
.faq-style-accordion .accordion-item {
  margin-bottom: 20px;
  transition: 0.5s all ease;
  border-radius: 0;
  border: none;
}
.faq-style-accordion .accordion-item .accordion-button {
  color: #0d1820;
  position: relative;
  text-align: start;
  box-shadow: unset;
  margin-bottom: 0;
  display: block;
  border: none;
  width: 100%;
  font-size: 18px;
  font-weight: 600;
  padding: 25px 65px 25px 25px;
  transition: 0.5s all ease;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  border-radius: 0;
}
.faq-style-accordion .accordion-item .accordion-button::before {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  content: "-";
  font-size: 15px;
  color: 0.5s all ease;
  display: inline-block;
  height: 20px;
  width: 20px;
  line-height: 20px;
  text-align: center;
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 50px;
}
.faq-style-accordion .accordion-item .accordion-button.collapsed {
  background-color: #E5F9F4;
  color: #0d1820;
}
.faq-style-accordion .accordion-item .accordion-button.collapsed::before {
  content: "+";
  color: #ffffff;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.faq-style-accordion .accordion-item .accordion-body {
  padding: 20px 0 25px 20px;
  transition: 0.5s all ease;
  border: none;
}
.faq-style-accordion .accordion-item .accordion-body p {
  color: #767676;
  font-size: 15px;
  margin-bottom: 0;
}
.faq-style-accordion .accordion-item .accordion-collapse {
  border: none;
}
.faq-style-accordion .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-style-accordion .accordion-item:last-child .accordion-body {
  padding-bottom: 0;
}

.faq-style-area-with-full-width {
  overflow: hidden;
}
.faq-style-area-with-full-width .container-fluid {
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
}
.faq-style-area-with-full-width .faq-style-accordion {
  max-width: 640px;
  margin: 0 auto 0 0;
}
.faq-style-area-with-full-width.with-black-color {
  background-color: #0d1820;
}
.faq-style-area-with-full-width.with-black-color .faq-style-accordion h3 {
  color: #ffffff;
}
.faq-style-area-with-full-width.with-black-color .faq-style-accordion .accordion-item {
  background-color: transparent;
}
.faq-style-area-with-full-width.with-black-color .faq-style-accordion .accordion-item .accordion-body {
  padding-right: 15px;
}
.faq-style-area-with-full-width.with-black-color .faq-style-accordion .accordion-item .accordion-body p {
  color: #ffffff;
  font-size: 15px;
}

.faq-style-area-with-full-width-wrap {
  background-color: #0d1820;
  overflow: hidden;
}
.faq-style-area-with-full-width-wrap .container-fluid {
  padding-left: 0;
  padding-right: 0;
}
.faq-style-area-with-full-width-wrap .faq-style-accordion {
  max-width: 640px;
  margin: 0 0 0 auto;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-right: 50px;
}
.faq-style-area-with-full-width-wrap .faq-style-accordion .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.faq-style-area-with-full-width-wrap .faq-style-accordion h3 {
  font-size: 40px;
  margin-bottom: 35px;
  font-weight: bold;
  color: #ffffff;
}
.faq-style-area-with-full-width-wrap .faq-style-accordion .accordion-item {
  background-color: transparent;
}
.faq-style-area-with-full-width-wrap .faq-style-accordion .accordion-item .accordion-body {
  padding-right: 15px;
}
.faq-style-area-with-full-width-wrap .faq-style-accordion .accordion-item .accordion-body p {
  color: #ffffff;
  font-size: 15px;
}
.faq-style-area-with-full-width-wrap.bg-transparent {
  background-color: transparent;
}
.faq-style-area-with-full-width-wrap.bg-transparent .faq-style-accordion {
  max-width: 640px;
  margin: 0 auto 0 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-right: 0;
  padding-left: 50px;
}
.faq-style-area-with-full-width-wrap.bg-transparent .faq-style-accordion .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.faq-style-area-with-full-width-wrap.bg-transparent .faq-style-accordion h3 {
  color: #0d1820;
}
.faq-style-area-with-full-width-wrap.bg-transparent .faq-style-accordion .accordion-item .accordion-body p {
  color: #767676;
}
.faq-style-area-with-full-width-wrap.bg-transparent .faq-style-image-wrap {
  border-radius: 0px 0px 100px 0px;
}

.faq-style-image-wrap {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
}
/*----- Better Home Area CSS -----*/
.better-home-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 120px;
  position: relative;
  z-index: 1;
}
.better-home-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}
.better-home-area .social-links {
  left: 30px;
  bottom: 30px;
  padding-left: 0;
  margin-bottom: 0;
  position: absolute;
  list-style-type: none;
}
.better-home-area .social-links li {
  display: inline-block;
  margin-right: 10px;
}
.better-home-area .social-links li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background: #DDDDDD;
  color: #ffffff;
  font-size: 20px;
  text-align: center;
  border-radius: 50px;
  transition: 0.5s all ease;
}
.better-home-area .social-links li a:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: translateY(-5px);
}
.better-home-area .social-links li:last-child {
  margin-right: 0;
}

.better-home-content {
  position: relative;
  top: -50px;
}
.better-home-content .sub-title {
  display: inline-block;
  margin-bottom: 18px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.better-home-content h1 {
  font-size: 85px;
  margin-bottom: 15px;
  font-weight: bold;
}
.better-home-content p {
  margin-bottom: 0;
  font-size: 15px;
  max-width: 610px;
}
.better-home-content .better-home-btn {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.better-home-content .better-home-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 18px;
}
.better-home-content .better-home-btn li:last-child {
  margin-right: 0;
}
.better-home-content .better-home-btn li .main-default-btn {
  color: #ffffff;
}
.better-home-content .better-home-btn li .main-optional-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #0d1820;
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.better-home-content .better-home-btn li .main-optional-btn:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

.better-home-image {
  position: relative;
  z-index: 1;
  text-align: center;
}
.better-home-image .video-btn {
  position: absolute;
  left: 100px;
  right: 0;
  margin: auto;
  bottom: 0;
  display: inline-block;
  height: 130px;
  width: 130px;
  line-height: 130px;
  background-color: #0d1820;
  border-radius: 64% 36% 35% 65%/65% 44% 56% 35%;
}
.better-home-image .video-btn i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background-color: #ffffff;
  border-radius: 50px;
  font-size: 45px;
  color: #0d1820;
  position: relative;
  top: 16px;
  transition: 0.5s all ease;
}
.better-home-image .video-btn i:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

/*----- Information Area CSS -----*/
.information-area {
  position: relative;
  z-index: 1;
}
.information-area.with-bg-black-color {
  background-color: #172632;
}
.information-area.with-bg-black-color .information-card .icon i {
  background: rgba(0, 193, 149, 0.2);
}
.information-area.with-bg-black-color .information-card p span {
  color: #ffffff;
}
.information-area.with-bg-black-color .information-card p span a {
  color: #ffffff;
}
.information-area.with-bg-black-color .information-card p span a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.information-area.with-bg-black-color .information-card:hover .icon i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.information-area.with-bg-main-color {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  position: relative;
  z-index: 1;
}
.information-area.with-bg-main-color::before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100px;
  background-color: #ffffff;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.information-area.with-bg-main-color .information-card .icon i {
  background: #00A47E;
}
.information-area.with-bg-main-color .information-card p span {
  color: #ffffff;
}
.information-area.with-bg-main-color .information-card p span a {
  color: #ffffff;
}
.information-area.with-bg-main-color .information-card p span a:hover {
  color: #0d1820;
}
.information-area.with-bg-main-color .information-card:hover .icon i {
  background: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

.information-card {
  position: relative;
  margin-bottom: 30px;
  padding-left: 82px;
}
.information-card .icon {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.information-card .icon i {
  display: inline-block;
  height: 65px;
  width: 65px;
  line-height: 65px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  text-align: center;
  border-radius: 50px;
  font-size: 35px;
  transition: 0.5s all ease;
}
.information-card p {
  margin-bottom: 0;
  font-size: 15px;
  font-weight: 500;
}
.information-card p span {
  display: block;
  color: #0d1820;
  margin-bottom: 5px;
}
.information-card p span:last-child {
  margin-bottom: 0;
}
.information-card p span a {
  color: #0d1820;
}
.information-card p span a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.information-card:hover .icon i {
  background-color: #0d1820;
  color: #ffffff;
}

.information-shape-1 {
  position: absolute;
  left: 50px;
  bottom: 250px;
  z-index: -1;
}

.information-shape-2 {
  position: absolute;
  right: 50px;
  top: 100px;
  z-index: -1;
}

/*----- Beyond Style Area CSS -----*/
.beyond-style-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.beyond-style-list-tab {
  position: relative;
}
.beyond-style-list-tab #thumbs {
  margin-bottom: 50px;
  position: relative;
  z-index: 1;
}
.beyond-style-list-tab #thumbs::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 100%;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
}
.beyond-style-list-tab #thumbs .tabs-item {
  text-align: center;
}
.beyond-style-list-tab #thumbs .tabs-item span {
  display: inline-block;
  height: 75px;
  width: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  font-size: 32px;
  font-weight: bold;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  background-color: #ffffff;
  color: #0d1820;
}
.beyond-style-list-tab #thumbs .current .tabs-item span {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.beyond-style-list-tab #beyond-style-item .beyond-style-content {
  padding-right: 20px;
}
.beyond-style-list-tab #beyond-style-item .beyond-style-content h3 {
  font-size: 40px;
  font-weight: bold;
  margin-bottom: 20px;
}
.beyond-style-list-tab #beyond-style-item .beyond-style-content .bold {
  color: #0d1820;
  font-weight: 500;
  font-size: 15px;
}
.beyond-style-list-tab .owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  transition: 0.5s all ease;
  font-size: 35px;
  display: inline-block;
  height: 75px;
  width: 75px;
  line-height: 0px;
  background-color: #ffffff;
  color: #0d1820;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border-radius: 50px;
  z-index: 1;
}
.beyond-style-list-tab .owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: 0;
}
.beyond-style-list-tab .owl-theme .owl-nav [class*=owl-].disabled {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #0d1820;
}
.beyond-style-list-tab .owl-theme .owl-nav [class*=owl-]:hover {
  color: #ffffff;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.beyond-style-list-tab .owl-theme .owl-nav {
  margin-top: 0;
}

.beyond-style-shape {
  position: absolute;
  left: 0;
  bottom: 100px;
  z-index: -1;
}

.beyond-style-area-with-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.beyond-style-area-with-bg::before {
  position: absolute;
  content: "";
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #0d1820;
  z-index: -1;
  opacity: 0.88;
}
.beyond-style-area-with-bg .main-section-title h2 {
  color: #ffffff;
}
.beyond-style-area-with-bg .beyond-style-list-tab #beyond-style-item .beyond-style-content {
  padding-left: 20px;
}
.beyond-style-area-with-bg .beyond-style-list-tab #beyond-style-item .beyond-style-content h3 {
  color: #ffffff;
}
.beyond-style-area-with-bg .beyond-style-list-tab #beyond-style-item .beyond-style-content .bold {
  color: #ffffff;
}
.beyond-style-area-with-bg .beyond-style-list-tab #beyond-style-item .beyond-style-content p {
  color: #C4C4C4;
}
.beyond-style-area-with-bg .beyond-style-list-tab #beyond-style-item .beyond-style-image {
  text-align: center;
}
.beyond-style-area-with-bg .beyond-style-list-tab #beyond-style-item .beyond-style-image img {
  width: auto;
  display: inline-block;
  border-radius: 5px;
}
.beyond-style-area-with-bg .beyond-style-list-tab .owl-theme .owl-nav [class*=owl-] {
  background-color: #172632;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  border: 1px solid #00C195;
}

/*----- Fun Fact Style Area CSS -----*/
.fun-fact-style-area {
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.fun-fact-style-card {
  position: relative;
  background-color: #F5F7FB;
  padding: 35px 35px 35px 123px;
  border-radius: 5px;
  margin-top: 12px;
  margin-bottom: 12px;
  transition: 0.5s all ease;
}
.fun-fact-style-card h3 {
  font-weight: 700;
  font-size: 55px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  margin-bottom: 0;
  position: absolute;
  top: 28px;
  left: 35px;
}
.fun-fact-style-card p {
  font-size: 18px;
  color: #0d1820;
  font-weight: 500;
  margin-bottom: 0;
}
.fun-fact-style-card.bg-E5F9F4 {
  background-color: #E5F9F4;
}
.fun-fact-style-card:hover {
  transform: translateY(-5px);
}

.fun-fact-style-content {
  padding-left: 40px;
}
.fun-fact-style-content .sub-title {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  display: inline-block;
  margin-bottom: 18px;
}
.fun-fact-style-content h3 {
  font-size: 40px;
  margin-bottom: 18px;
  font-weight: bold;
}
.fun-fact-style-content .bold {
  color: #0d1820;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 0;
}
.fun-fact-style-content .fun-fact-btn {
  margin-top: 20px;
}
.fun-fact-style-content .fun-fact-btn .main-default-btn {
  color: #ffffff;
}

.fun-fact-style-shape {
  position: absolute;
  right: -30px;
  bottom: -350px;
  z-index: -1;
}

.fun-fact-style-area-with-main-color {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.fun-fact-style-item {
  position: relative;
  background-color: #F5F7FB;
  padding: 35px 35px 35px 123px;
  border-radius: 5px;
  margin-bottom: 30px;
  transition: 0.5s all ease;
}
.fun-fact-style-item h3 {
  font-weight: 700;
  font-size: 55px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  margin-bottom: 0;
  position: absolute;
  top: 28px;
  left: 35px;
}
.fun-fact-style-item p {
  font-size: 18px;
  color: #0d1820;
  font-weight: 500;
  margin-bottom: 0;
}
.fun-fact-style-item:hover {
  transform: translateY(-5px);
}

.fun-fact-style-shape-2 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 50%;
}

/*----- Find Loan Area CSS -----*/
.find-own-loan-area {
  background: linear-gradient(180deg, #F5F7FB 0%, rgba(245, 247, 251, 0) 100%);
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.find-own-loan-list-tab .nav {
  padding: 0;
  margin-bottom: 45px;
  border: none;
  border-bottom: 1px solid #DDDDDD;
  text-align: center;
  display: block;
}
.find-own-loan-list-tab .nav .nav-item {
  margin-right: 30px;
  display: inline-block;
}
.find-own-loan-list-tab .nav .nav-item:last-child {
  margin-right: 0;
}
.find-own-loan-list-tab .nav .nav-item .nav-link {
  transition: 0.5s all ease;
  color: #0d1820;
  padding: 0;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 15px;
  position: relative;
  padding-bottom: 20px;
}
.find-own-loan-list-tab .nav .nav-item .nav-link::before {
  position: absolute;
  content: "";
  height: 1px;
  width: 0%;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  left: 0;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.5s all ease;
}
.find-own-loan-list-tab .nav .nav-item .nav-link:hover, .find-own-loan-list-tab .nav .nav-item .nav-link.active {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.find-own-loan-list-tab .nav .nav-item .nav-link:hover::before, .find-own-loan-list-tab .nav .nav-item .nav-link.active::before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}

.find-own-loan-card {
  margin-bottom: 30px;
}
.find-own-loan-card .loan-content {
  position: relative;
  margin-top: -30px;
}
.find-own-loan-card .loan-content .tag {
  display: inline-block;
  padding: 8px 22px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 22px;
}
.find-own-loan-card .loan-content h3 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 15px;
}
.find-own-loan-card .loan-content p {
  margin-bottom: 0;
}

.find-own-loan-slider.owl-theme .owl-nav {
  margin-top: 0;
  transition: 0.5s all ease;
}
.find-own-loan-slider.owl-theme .owl-nav [class*=owl-] {
  color: #0d1820;
  margin: 0;
  position: absolute;
  left: -70px;
  top: 30%;
  transform: translateY(-30%);
  padding: 0;
  transition: 0.5s all ease;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 50px;
  font-size: 25px;
}
.find-own-loan-slider.owl-theme .owl-nav [class*=owl-].owl-next {
  left: auto;
  right: -70px;
}
.find-own-loan-slider.owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #0d1820;
  color: #ffffff;
}

.find-own-loan-box {
  margin-bottom: 30px;
  transition: 0.5s all ease;
}
.find-own-loan-box .loan-image {
  position: relative;
}
.find-own-loan-box .loan-image .tag {
  display: inline-block;
  padding: 8px 22px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  font-size: 14px;
  font-weight: 400;
  position: absolute;
  left: 20px;
  bottom: 20px;
  border-radius: 5px;
}
.find-own-loan-box .loan-content {
  margin-top: 25px;
}
.find-own-loan-box .loan-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
}
.find-own-loan-box .loan-content p {
  margin-bottom: 0;
  font-size: 15.8px;
}
.find-own-loan-box:hover {
  transform: translateY(-5px);
}

.find-own-loan-item {
  background-color: #F5F7FB;
  padding: 30px;
  position: relative;
  transition: 0.5s all ease;
  margin-bottom: 30px;
}
.find-own-loan-item .icon {
  margin-bottom: 20px;
}
.find-own-loan-item .icon i {
  display: inline-block;
  height: 80px;
  width: 80px;
  line-height: 80px;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  text-align: center;
  font-size: 45px;
  border-radius: 50px;
  transition: 0.5s all ease;
}
.find-own-loan-item .loan-content h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 15px;
  transition: 0.5s all ease;
}
.find-own-loan-item .loan-content p {
  color: #767676;
  transition: 0.5s all ease;
  font-size: 15px;
}
.find-own-loan-item .tag {
  position: absolute;
  right: 15px;
  top: 10px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  font-size: 15px;
  font-weight: 100;
}
.find-own-loan-item:hover {
  background-color: #0d1820;
}
.find-own-loan-item:hover .icon i {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.find-own-loan-item:hover .loan-content h3 {
  color: #ffffff;
}
.find-own-loan-item:hover .loan-content p {
  color: #ffffff;
}

/*----- Overview Style Area CSS -----*/
.overview-style-area {
  position: relative;
  z-index: 1;
}

.overview-style-inner-box {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px;
  padding-top: 100px;
  padding-bottom: 100px;
  padding-left: 50px;
  padding-right: 50px;
}
.overview-style-inner-box .content {
  text-align: center;
  max-width: 650px;
  margin: auto;
}
.overview-style-inner-box .content h3 {
  font-size: 40px;
  margin-bottom: 0;
  line-height: 1.5;
  font-weight: bold;
}
.overview-style-inner-box .content h3 span {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.overview-style-inner-box .content .overview-btn {
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.overview-style-inner-box .content .overview-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 18px;
}
.overview-style-inner-box .content .overview-btn li:last-child {
  margin-right: 0;
}
.overview-style-inner-box .content .overview-btn li .main-default-btn {
  color: #ffffff;
}
.overview-style-inner-box .content .overview-btn li .main-optional-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #0d1820;
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.overview-style-inner-box .content .overview-btn li .main-optional-btn:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}

.overview-style-shape-1 {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}

/*-----  Choice Home Area CSS -----*/
.choice-home-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 100px;
  position: relative;
  z-index: 1;
}
.choice-home-area .container-fluid {
  padding-left: 160px;
  padding-right: 30px;
}
.choice-home-area::before {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  height: 100%;
  width: 350px;
  background-color: #0d1820;
  z-index: -1;
}
.choice-home-area::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 88px;
  background-color: #0d1820;
  z-index: -1;
}

.choice-home-content {
  position: relative;
  top: -20px;
}
.choice-home-content .sub-title {
  display: inline-block;
  margin-bottom: 18px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.choice-home-content h1 {
  font-size: 75px;
  margin-bottom: 0;
  font-weight: bold;
  color: #ffffff;
}
.choice-home-content .choice-home-btn {
  padding: 0;
  display: inline-flex;
  margin-top: 20px;
  margin-bottom: 0;
}
.choice-home-content .choice-home-btn li {
  display: inline-block;
  margin-right: 30px;
}
.choice-home-content .choice-home-btn li:last-child {
  margin-right: 0;
}
.choice-home-content .choice-home-btn li .main-default-btn {
  color: #ffffff;
}
.choice-home-content .choice-home-btn li .video-btn i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #ffffff;
  color: #0d1820;
  font-size: 30px;
  margin-right: 10px;
  transition: 0.5s all ease;
}
.choice-home-content .choice-home-btn li .video-btn:hover i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.choice-home-content .choice-home-btn li .video-btn span {
  color: #ffffff;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -5px;
}

.mortgage-calculator-form {
  background-color: #ffffff;
  padding: 35px;
  max-width: 535px;
  margin-left: auto;
  border-radius: 5px;
}
.mortgage-calculator-form h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
}
.mortgage-calculator-form form .form-group {
  margin-bottom: 20px;
  position: relative;
}
.mortgage-calculator-form form .form-group label {
  margin-bottom: 0;
  font-size: 18px;
  position: absolute;
  top: 12px;
  left: 15px;
  color: #555555;
}
.mortgage-calculator-form form .form-group .form-control {
  height: 50px;
  border-radius: 4px;
  border: 1px solid #E3E9ED;
  font-weight: 400;
  font-size: 14px;
  padding-left: 40px;
  width: 100%;
  color: #555555;
  background-color: #F8FAFB;
}
.mortgage-calculator-form form .form-group .form-control:focus {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.mortgage-calculator-form form .form-group::placeholder {
  color: #7f7f7f;
}
.mortgage-calculator-form form .form-group .dropdown {
  position: relative;
}
.mortgage-calculator-form form .form-group .dropdown a {
  display: block;
  height: 50px;
  border-radius: 4px;
  color: #555555;
  background-color: #F8FAFB;
  font-weight: 400;
  font-size: 14px;
  border: 1px solid #E3E9ED;
  text-align: left;
  padding-left: 40px;
  line-height: 35px;
}
.mortgage-calculator-form form .form-group .dropdown a::after {
  border-top: 7px solid;
  position: absolute;
  top: 22px;
  right: 18px;
}
.mortgage-calculator-form form .form-group .dropdown a:focus {
  box-shadow: none;
  border: 1px solid #f1f1f1;
}
.mortgage-calculator-form form .form-group .dropdown .dropdown-menu.show {
  display: block;
  width: 100%;
}
.mortgage-calculator-form form .main-default-btn {
  border: 0;
  width: 100%;
}

.choice-home-social-links {
  text-align: right;
  padding: 0;
  margin-top: 30px;
  margin-bottom: 0;
}
.choice-home-social-links li {
  display: inline-block;
  margin-right: 10px;
}
.choice-home-social-links li a {
  display: inline-block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  background: #C4C4C4;
  color: #0d1820;
  font-size: 20px;
  text-align: center;
  border-radius: 50px;
  transition: 0.5s all ease;
}
.choice-home-social-links li a:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: translateY(-5px);
  color: #ffffff;
}
.choice-home-social-links li:last-child {
  margin-right: 0;
}

.choice-home-information {
  padding: 0;
  margin-bottom: 0;
  position: absolute;
  top: 48%;
  transform: translateY(-48%);
  left: -10%;
  transform: rotate(-90deg);
}
.choice-home-information li {
  list-style-type: none;
  display: inline-block;
  margin-right: 15px;
  font-size: 14px;
}
.choice-home-information li:last-child {
  margin-right: 0;
}
.choice-home-information li span {
  color: #ffffff;
  font-weight: 600;
  font-size: 14px;
}
.choice-home-information li a {
  color: #DDDDDD;
}
.choice-home-information li a:hover {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*-----  Expect Style Area CSS -----*/
.expect-style-area {
  background-color: #0d1820;
}
.expect-style-area .main-section-title {
  max-width: 630px;
  margin: 0 auto 45px;
}
.expect-style-area .main-section-title h2 {
  color: #ffffff;
}

.expect-style-card {
  background-color: #172632;
  padding: 35px 50px;
  margin-bottom: 30px;
  border: 1px solid #172632;
  transition: 0.5s all ease;
}
.expect-style-card h3 {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 18px;
  color: #ffffff;
}
.expect-style-card p {
  font-size: 15px;
  color: #DDDDDD;
  margin-bottom: 18px;
}
.expect-style-card a {
  font-size: 15px;
  font-weight: 500;
  color: #ffffff;
}
.expect-style-card:hover {
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  transform: translateY(-5px);
}
.expect-style-card:hover a {
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*-----  Confidence Home Area CSS -----*/
.confidence-home-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  padding-top: 100px;
  padding-bottom: 100px;
}
.confidence-home-area .container-fluid {
  padding-left: 30px;
  padding-right: 30px;
}

.confidence-home-content {
  position: relative;
  top: -20px;
}
.confidence-home-content .sub-title {
  display: inline-block;
  margin-bottom: 15px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.confidence-home-content h1 {
  font-size: 85px;
  margin-bottom: 12px;
  font-weight: bold;
}
.confidence-home-content p {
  margin-bottom: 0;
  font-size: 15px;
  max-width: 610px;
}
.confidence-home-content .confidence-home-btn {
  padding: 0;
  display: inline-flex;
  margin-top: 30px;
  margin-bottom: 0;
}
.confidence-home-content .confidence-home-btn li {
  display: inline-block;
  margin-right: 30px;
}
.confidence-home-content .confidence-home-btn li:last-child {
  margin-right: 0;
}
.confidence-home-content .confidence-home-btn li .main-default-btn {
  color: #ffffff;
}
.confidence-home-content .confidence-home-btn li .video-btn i {
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  background: #0d1820;
  color: #ffffff;
  font-size: 30px;
  margin-right: 10px;
  transition: 0.5s all ease;
}
.confidence-home-content .confidence-home-btn li .video-btn:hover i {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
}
.confidence-home-content .confidence-home-btn li .video-btn span {
  color: #0d1820;
  font-weight: 500;
  font-size: 14px;
  position: relative;
  top: -5px;
}

.confidence-home-area-wrap {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 0px 0px 200px 0px;
  position: relative;
  z-index: 1;
  padding-top: 350px;
  padding-bottom: 220px;
}
.confidence-home-area-wrap::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  background: linear-gradient(90deg, #000000 0%, rgba(0, 0, 0, 0) 72.4%);
  opacity: 0.75;
  border-radius: 0px 0px 200px 0px;
  z-index: -1;
}

.confidence-home-wrap-content {
  max-width: 650px;
}
.confidence-home-wrap-content .sub-title {
  display: inline-block;
  margin-bottom: 12px;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.confidence-home-wrap-content h1 {
  font-size: 85px;
  margin-bottom: 15px;
  font-weight: 400;
  color: #ffffff;
}
.confidence-home-wrap-content h1 b {
  font-weight: 900;
}
.confidence-home-wrap-content p {
  margin-bottom: 0;
  font-size: 15px;
  color: #ffffff;
}
.confidence-home-wrap-content .confidence-home-btn {
  padding: 0;
  display: inline-flex;
  margin-top: 35px;
  margin-bottom: 0;
}
.confidence-home-wrap-content .confidence-home-btn li {
  display: inline-block;
  margin-right: 30px;
}
.confidence-home-wrap-content .confidence-home-btn li:last-child {
  margin-right: 0;
}
.confidence-home-wrap-content .confidence-home-btn li .main-default-btn {
  color: #ffffff;
}
.confidence-home-wrap-content .confidence-home-btn li .main-optional-btn {
  display: inline-block;
  padding: 14px 30px;
  background-color: transparent;
  color: #ffffff;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
  border: 1px solid #ffffff;
}
.confidence-home-wrap-content .confidence-home-btn li .main-optional-btn:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*-----  Get Quote Style Area CSS -----*/
.get-quote-style-area {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  padding-top: 20px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.get-quote-style-content {
  position: relative;
  top: -15px;
}
.get-quote-style-content .sub-title {
  color: #ffffff;
  display: inline-block;
  margin-bottom: 18px;
}
.get-quote-style-content h3 {
  font-size: 40px;
  margin-bottom: 0;
  font-weight: bold;
  color: #ffffff;
}
.get-quote-style-content .quote-btn {
  padding: 0;
  margin-top: 25px;
  margin-bottom: 0;
}
.get-quote-style-content .quote-btn li {
  list-style-type: none;
  display: inline-block;
  margin-right: 25px;
}
.get-quote-style-content .quote-btn li:last-child {
  margin-right: 0;
}
.get-quote-style-content .quote-btn li .main-default-btn {
  background-color: #0d1820;
  color: #ffffff;
}
.get-quote-style-content .quote-btn li .main-default-btn:hover {
  background-color: #ffffff;
  color: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.get-quote-style-content .quote-btn li .main-optional-btn {
  display: inline-block;
  padding: 15px 30px;
  background-color: #ffffff;
  color: #0d1820;
  border: none;
  font-size: 15px;
  font-weight: 400;
  transition: 0.5s all ease;
  border-radius: 10px;
}
.get-quote-style-content .quote-btn li .main-optional-btn:hover {
  background-color: #0d1820;
  color: #ffffff;
}

.get-quote-style-shape-1 {
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
  opacity: 50%;
}

.get-quote-style-shape-2 {
  position: absolute;
  right: 30px;
  top: 30px;
  z-index: -1;
}

/*----- Process Style Area CSS -----*/
.process-style-area {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

.process-style-accordion .accordion-item {
  border: none;
  transition: 0.5s all ease;
  border-radius: 0;
  position: relative;
  z-index: 1;
  background-color: transparent;
}
.process-style-accordion .accordion-item::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 100%;
  z-index: -1;
  left: 25px;
  top: 0;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}
.process-style-accordion .accordion-item:last-child .accordion-button {
  padding-bottom: 0;
}
.process-style-accordion .accordion-item:last-child .accordion-body {
  padding-top: 10px;
  padding-bottom: 0;
}
.process-style-accordion .accordion-item .accordion-button {
  color: #0d1820;
  box-shadow: unset;
  margin-bottom: 0;
  display: block;
  border: none;
  width: 100%;
  font-size: 18.8px;
  font-weight: 600;
  background-color: transparent;
  padding: 0 0 15px 0;
}
.process-style-accordion .accordion-item .accordion-button span {
  display: inline-block;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff;
  border-radius: 50px;
  margin-right: 10px;
  font-size: 16px;
}
.process-style-accordion .accordion-item .accordion-body {
  padding: 0px 0 20px 60px;
}
.process-style-accordion .accordion-item .accordion-body .bold {
  color: #0d1820;
  font-weight: 500;
  font-size: 15px;
  margin-bottom: 10px;
}
.process-style-accordion .accordion-item .accordion-body p {
  color: #767676;
  font-size: 15px;
  margin-bottom: 0;
  font-size: 15px;
}

.process-style-wrap-image {
  position: relative;
  z-index: 1;
  padding-top: 0;
  padding-bottom: 10px;
  padding-right: 10px;
  border-radius: 0px 0px 100px 0px;
}
.process-style-wrap-image img {
  border-radius: 0px 0px 100px 0px;
}
.process-style-wrap-image::before {
  position: absolute;
  content: "";
  height: 495px;
  width: 550px;
  background-color: transparent;
  right: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 0px 0px 100px 0px;
  border: 1px solid linear-gradient(135deg, cyan 30%, #eb9d37 85%);
}

/*=================================
Buy Now Btn
====================================*/
.buy-now-btn {
  right: 30px;
  z-index: 99;
  top: 50%;
  position: fixed;
  transform: translateY(-50%);
  border-radius: 30px;
  display: inline-block;
  color: #ffffff !important;
  background-color: #82b440;
  padding: 10px 20px 10px 42px;
  font-size: 15px;
  font-weight: 600;
  animation-name: tada;
  animation-duration: 5s;
  animation-fill-mode: both;
  animation-iteration-count: infinite;
}
.buy-now-btn img {
  top: 50%;
  left: 20px;
  width: 15px;
  position: absolute;
  transform: translateY(-50%);
}
.buy-now-btn:hover {
  background: linear-gradient(135deg, cyan 30%, #eb9d37 85%);
  color: #ffffff !important;
}

@keyframes tada {
  0% {
    transform: scale3d(1, 1, 1);
  }
  10%, 20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }
  30%, 50%, 70%, 90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }
  40%, 60%, 80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }
  100% {
    transform: scale3d(1, 1, 1);
  }
}
.overlay_spinner {
  position: absolute !important;
  left: 43%;
  top: 37%;
}

.overlay_spinner-parent {
  position: relative !important;
}